/* global EM */
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Routes from '../app/Routes';
import PageTitle from  '../components/PageTitle';

class NotFound extends Component {
    render() {
        return (
            <div key="contents" className="page container">                              
                <PageTitle title={EM.t('notFound.title')} text={EM.t('notFound.instructions')} />
                <Link to={Routes.client.home}>{EM.t('notFound.homeLink')}</Link>
            </div> 
        );
    }
}

export default NotFound;
