import _ from 'underscore';
import * as errorActions from './actions/error';
import * as accountActions from './actions/account';
import * as translationActions from './actions/translation';
import * as domainActions from './actions/domain';
import * as fileActions from './actions/files';
import * as termsActions from './actions/terms';
import * as releaseActions from './actions/release';
import LocalStorageManager from '../util/LocalStorageManager';
import entityTypes from './entityTypes';
import Entity from './Entity';
import * as IdbKv from 'idb-keyval';

export default class EntityManager {
    constructor(store, authenticator, api) {

        this._isInitialized = false;

        this.storage = new LocalStorageManager();

        this.store = store;
        this.dispatch = store.dispatch;
        this.authenticator = authenticator;
        this.api = api;

        this.fallbackLanguage = 'en';
        this.fallbackTheme = 'Light';
        this.currentLanguage = this.storage.get('lang') || this.fallbackLanguage;

        this.translation = null; //set after load
        this.helpCache = {};
        this.languageList = [{ value: 'en', label: 'English' }];
        if (process.env.REACT_APP_ENV !== 'prod') {
            this.languageList.push({ value: 'dev', label: 'Dev' });
        };

        this.themeList = [{ label: 'Light', value: 'Light' }, { label: 'Dark', value: 'Dark' }];
        this.currentTheme = this.storage.get('theme') || this.fallbackTheme;

        this._debugMode = this.storage.get('debug');
        this.helpMode = false;
        this.history = null;

        this.domainPermission = null;
        this.tenantPermission = null;
        this.domainColor = null;

        this.statusMessageRef = null;
        this.featureCache = null;

        this.dpCache = {};

        if (window.location.hostname === 'localhost') {
            this.registerLogging();
        } else {
            this.unregisterLogging();
        }
    }

    get debugMode() {
        return this._debugMode;
    }

    set debugMode(value) {
        this._debugMode = value;
        if (value) {
            this.storage.set('debug', true);
            this.registerLogging();
        } else {
            this.storage.remove('debug');
            this.unregisterLogging();
        }
    }

    async initialize() {
        try {
            await this.dispatch(translationActions.loadTranslation(this.currentLanguage, this.fallbackLanguage)).then((action) => {
                let state = this.store.getState();
                if (state.translation) {
                    this.translation = state.translation;
                    this.storage.set('lang', state.translation.name);
                }
            });

            await this.dispatch(termsActions.loadTermsData());            

            let accountDispatchedResponse = await this.dispatch(accountActions.loadAccount(this.authenticator.mode)).catch((e) => {
                let msg = e.message || e;
                if (msg.toLowerCase().indexOf('multiple') > -1) {
                    this.dispatch(errorActions.globalError({ message: 'Multiple accounts detected.', errorCode: 'account:99' }));
                }
                throw e;
            });
            this.me = accountDispatchedResponse.data;

            entityTypes.forEach(et => {
                if (et.entityType) {
                    this[et.name] = new et.entityType(this.store, et);
                } else {
                    this[et.name] = new Entity(this.store, et);
                }
            });
            this.warn('EM Initialized.');

            this.dispatch(releaseActions.loadReleaseData()).then(() => {
                this.getUserNotifications();
            });
            
            return true;
        } catch (e) {
            this.warn('EM Initialization Error', e);
            return false;
        }
    }

    /*-----------------Entity & Data Methods-----------*/
    loadAccount() {
        return this.dispatch(accountActions.loadAccount());
    }

    async setActiveDomain(domain) {
        let state = this.store.getState();
        if (state.domain !== domain) {
            this.log('Setting active domain:', domain.Name);
            this.dispatch(domainActions.setDomain(domain));
            document.documentElement.style.setProperty('--domain-color', domain.color);
            await this.settings.load();
            if (this.debugMode && this.isDomainAdmin()) {
                await this.users.load();
            }
        }
    }

    getActiveDomain() {
        let state = this.store.getState();
        return state.domain;
    }

    async clearActiveDomain() {
        let state = this.store.getState();
        this.api.abortPendingFetches();
        if (state.domain) {
            this.log('Clearing active domain.');
            await this.dispatch(domainActions.clearDomain());
            await this.dispatch(fileActions.clearFiles());
            this.domainPermission = null;
            entityTypes.forEach(async et => {
                await this[et.name].clear();
            });
        }
        this.domainColor = null;
        this.clearDpCache();
    }

    allLoaded(...args) {
        return _.every(args, (entity) => {
            return entity.isLoaded();
        })
    }

    loadEntities(allEntities, force) {
        let promiseSet = [];
        allEntities.forEach((entity) => {
            promiseSet.push(entity.load(force));
        });
        return Promise.all(promiseSet);
    }

    setError(errorIn) {
        const eid = (new Date()).getTime();

        let errorOut = { errorCode: null, message: '' };

        if (typeof errorIn === 'string') {
            errorOut.message = errorIn;
        } else {
            if (errorIn instanceof Error) {
                errorOut.message = errorIn.toString();
            } else {
                errorOut = Object.assign(errorOut, errorIn);
            }
        }

        //Don't show abort errors, since the user initiated the abort by changing domains.
        if (errorOut.message.indexOf('AbortError') === 0) {
            console.log(errorOut.message);
            return;
        }

        this.dispatch(errorActions.localError(eid, errorOut));
        window.setTimeout(() => {
            this.dispatch(errorActions.clearLocalError(eid));
        }, 5000);
    }

    /*-------------------Security & Permissions-----------*/
    setDomainPermission() {
        let state = this.store.getState();
        let domain = state.domain;
        let match = this.me.Domains.find(d => {
            return d.DomainId === (domain ? domain.DomainId : -1) || d.Permission.PermissionLookupId >= 500
        });
        if (match) {
            return this.domainPermission = match.Permission;
        }
    }

    setTenantPermission() {
        let match = this.me.User.Permissions.find(d => {
            return d.PermissionLookupId >= 500
        });
        if (match) {
            return this.tenantPermission = match;
        }
    }

    isSuperAdmin() {
        if (!this.tenantPermission) this.setTenantPermission();
        if (!this.tenantPermission) return false;
        return this.tenantPermission.PermissionLookupId >= 1000;
    }

    isStrictlySuperAdmin() {
        if (!this.tenantPermission) this.setTenantPermission();
        if (!this.tenantPermission) return false;
        return this.tenantPermission.PermissionLookupId === 1000;
    }

    isTenantAdmin() {
        if (!this.tenantPermission) this.setTenantPermission();
        if (!this.tenantPermission) return false;
        return this.tenantPermission.PermissionLookupId >= 500;
    }

    isStrictlyTenantAdmin() {
        if (!this.tenantPermission) this.setTenantPermission();
        if (!this.tenantPermission) return false;
        return this.tenantPermission.PermissionLookupId === 500;
    }

    isDomainAdmin() {
        if (!this.domainPermission) this.setDomainPermission();
        if (!this.domainPermission) return false;
        return this.domainPermission.PermissionLookupId >= 100;
    }

    isStrictlyDomainAdmin() {
        if (!this.domainPermission) this.setDomainPermission();
        if (!this.domainPermission) return false;
        return this.domainPermission.PermissionLookupId === 100;
    }

    isDomainEditor() {
        if (!this.domainPermission) this.setDomainPermission();
        if (!this.domainPermission) return false;
        return this.domainPermission.PermissionLookupId >= 50;
    }

    isStrictlyDomainEditor() {
        if (!this.domainPermission) this.setDomainPermission();
        if (!this.domainPermission) return false;
        return this.domainPermission.PermissionLookupId === 50;
    }

    isDomainSubscriber() {
        if (!this.domainPermission) this.setDomainPermission();
        if (!this.domainPermission) return false;
        return this.domainPermission.PermissionLookupId >= 1;
    }

    isStrictlyDomainSubscriber() {
        if (!this.domainPermission) this.setDomainPermission();
        if (!this.domainPermission) return false;
        return this.domainPermission.PermissionLookupId === 1;
    }

    hasAnyDomainAdmin() {
        let domainsWithAdmin = this.me.Domains.filter(domain => {
            return domain.Permission.PermissionLookupId >= 100;
        });

        return domainsWithAdmin.length > 1;
    }

    isMyEntity(entityObject) {
        return entityObject.CreatedBy === this.me.User.UserId;
    }

    hasAcceptedTerms() {
        let state = this.store.getState();
        let termsUpdatedDate = state.terms.dateUpdated;
        let userTermsAcceptedDate = state.account.User.TermsAccepted;
        let termsUpdatedTicks = new Date(termsUpdatedDate).getTime();
        let userTermsAcceptedTicks = new Date(userTermsAcceptedDate).getTime();
        if (userTermsAcceptedTicks < termsUpdatedTicks) {
            return false;
        }
        return true;
    }

    async setAcceptedTerms() {
        let newUserObj = {
            TermsAccepted: new Date().toISOString()
        };

        await this.api.updateUser(newUserObj);
    }

    /*-------------------Translation-----------*/
    t(key, exact, opts) {
        if (this.settings) {
            let tOverride = this.getSetting('t.' + key);
            if (tOverride) {
                return tOverride;
            }
        }

        let selectorFunc = _.property(key.split('.'));
        let result = selectorFunc(this.translation);
        let strOutput = exact ? result : (result || key);
        return opts ? this.supplant(strOutput, opts) : strOutput;
    }

    supplant(str, opts) {
        return str.replace(/{([^{}]*)}/g,
            function (a, b) {
                let r = opts[b];
                return typeof r === 'string' || typeof r === 'number' ? r : a;
            }
        );
    };

    /*-------------------Logging-----------*/
    registerLogging() {
        this.log = console.log.bind(window.console);
        this.warn = console.warn.bind(window.console);
        this.time = console.time.bind(window.console);
        this.timeEnd = console.timeEnd.bind(window.console);
    }

    unregisterLogging() {
        this.log = () => { };
        this.warn = () => { };
        this.time = () => { };
        this.timeEnd = () => { };
    }

    logState() {
        this.log(this.store.getState());
    }

    logProcess() {
        this.log(process.env);
    }

    /*-------------------Utility methods-----------*/
    triggerWindowResize(delay) {
        window.setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, delay || 500);
    }

    isOverridden(entity) {
        let eName = typeof entity === 'object' ? entity.modelName : entity;
        let overrideSetting = this.getSetting('OverrideParent:' + eName);
        if (overrideSetting) {
            if (overrideSetting.toLowerCase() === 'true') return true;
        }
        return false;
    }

    getSetting(name) {
        let settingRecord = this.settings.findByKey(name);
        let settingValue = null;
        if (settingRecord && settingRecord.Value) {
            settingValue = settingRecord.Value;
        }

        return settingValue;
    }    

    getTenantOption(name) {
        if (!this.tenantOptions) {
            try {
                this.tenantOptions = JSON.parse(this.me.Tenant.Options || '{}');
            } catch (e) {
                this.tenantOptions = {}
            }
        }
        return this.tenantOptions[name] || null;
    }

    async getConfig(name, configType) {
        await this.configurations.load();
        return this.configurations.get().find(config => {
            if (configType) {
                return config.ConfigurationTypeLookupId === configType && config.Name === name;
            } else {
                return config.Name === name;
            }
        });
    }

    loadScript(uri) {
        return new Promise((resolve, reject) => {
            var tag = document.createElement('script');
            tag.src = uri;
            tag.async = true;
            tag.onload = () => {
                resolve();
            };
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        });
    }

    /*-------------------Notifications-----------*/
    async getUserNotifications() {
        let notificationsArr = [];

        let reduxStore = this.store.getState();
        let userNotificationAcknowledgedDate = reduxStore.account.User.NotificationsAcknowledged;
        let releaseNotesUpdatedDate = reduxStore.release.dateUpdated;
        let userNotificationAcknowledgedTicks = new Date(userNotificationAcknowledgedDate).getTime();
        let releaseNotesUpdatedTicks = new Date(releaseNotesUpdatedDate).getTime();

        if (userNotificationAcknowledgedTicks < releaseNotesUpdatedTicks) {
            notificationsArr.push({
                id: 1, type: "new_release", Message: this.t('util.releaseNotification', null, [process.env.REACT_APP_VERSION]),
            });
        }

        let payload = { type: 'LOAD_NOTIFICATIONS', notifications: notificationsArr };
        this.dispatch(payload);

        return notificationsArr;
    }

    async removeNotification(notification) {
        let payload = { type: 'REMOVE_NOTIFICATION', notification: notification };
        await this.dispatch(payload);

        if (this.store.getState().notifications.length === 0) {
            this.updateUserNotificationsAcknowledged();
        }
    }

    async updateUserNotificationsAcknowledged() {
        let newUserObj = {
            NotificationsAcknowledged: new Date().toISOString()
        }
        await this.api.updateUser(newUserObj).then(
            () => { this.dispatch(accountActions.loadAccount(this.authenticator.mode)); }
        )
    }

    /*-------------------Cross-page/Nav-----------*/
    setHistory(history) {
        var self = this;
        this.history = history;
        this.navCount = 0;
        this.history.listen((location, action) => {
            self.navCount++;
            if (self.navCount > 2) self.clearCrossPage();
        });
    }

    set crossPage(value) {
        this.navCount = 0;
        this.storage.set('crossPage', value);
    }

    get crossPage() {
        return JSON.parse(this.storage.get('crossPage'));
    }

    clearCrossPage() {
        this.storage.remove('crossPage');
    }

    /*-------------------Themes-----------*/
    setTheme(theme, e) {
        this.storage.set('theme', theme);
        this.currentTheme = theme;

        var pc = document.getElementById("page-container");
        var toggles = document.getElementsByClassName('theme-toggle');
        [].forEach.call(toggles, function (elem) {
            elem.classList.remove('active');
        });

        var target = e.target;
        target.classList.add('active');

        if (theme === 'Dark') {
            pc.classList.add("dark-mode");
            document.body.classList.add('dark');
        } else {
            pc.classList.remove("dark-mode");
            document.body.classList.remove('dark');
        }
    }

    //Get variables for use in custom algo expression
    async getVariables() {
        var self = this;

        //default variables
        let variables = [
            'Projection', 'Complexity', 'ComplexityNumeric', 'Role', 'Department', 'Organization', 'WorkItemName', 'ActivityName', 'ActivityId', 'Begin', 'End'
        ];

        await self.attributeTypeLookup.load();
        await self.attributes.load().then(() => {
            self.attributes.get().forEach(attribute => {
                if (attribute.AttributeTypeLookupId === 3) return;
                let aType = self.attributeTypeLookup.lookupValue(attribute.AttributeTypeLookupId);
                variables.push(aType + '["' + attribute.Name + '"]')
            });
        });

        await self.schedules.loadDefaultItem().then(async (defaultSchedule) => {
            if (!defaultSchedule) return;
            await self.schedules.loadFile(defaultSchedule.ScheduleId).then(() => {
                let fileContents = self.schedules.getFile(defaultSchedule.ScheduleId);
                if (fileContents) {
                    let filters = fileContents.getFilterValues();
                    filters.forEach(filter => {
                        if (filter.label === 'Work Item' || filter.label === 'Activity') return;
                        if (filter.name === 'workitem' || filter.name === 'activity') return;
                        variables.push('Schedule["' + filter.label + '"]');
                    });
                }
            });
        });

        return _.uniq(variables);
    }

    /*-------------------Dirty/clean State-----------*/
    isDomainDirty() {
        let state = this.store.getState();
        let domain = state.domain;
        if (state.domain && state.domainState) {
            if (state.domainState[domain.DomainId]) {
                return true;
            }
        }
        return false;
    }

    markDomainDirty(domainId, entity) {
        if (entity) {
            if (entity.name === 'assignments' ||
                entity.name === 'activities' ||
                entity.name === 'employees' ||
                entity.name === 'roles' ||
                entity.name === 'departments' ||
                entity.name === 'organizations' ||
                entity.name === 'headcounts' ||
                entity.name === 'schedules' ||
                entity.name === 'actuals' ||
                entity.name === 'configurations' ||
                entity.name === 'permissions' ||
                entity.name === 'scenarios' ||
                entity.name === 'notes' ||
                entity.name === 'referenceTables' ||
                entity.name === 'resourceRequests' ||
                entity.name === 'settings'
            ) return;
        }
        let payload = { type: 'MARK_DOMAIN_DIRTY', domainId: domainId };
        return this.dispatch(payload);
    }

    markDomainClean(domainId) {
        let payload = { type: 'MARK_DOMAIN_CLEAN', domainId: domainId };
        return this.dispatch(payload);
    }

    /*-------------------Global Status Messages-----------*/
    getStatusMessageRef(React) {
        return this.statusMessageRef = React.createRef();
    }

    setStatusMessage(message, className, timeout) {
        if (!this.statusMessageRef) return;
        if (!this.statusMessageRef.current) return;
        this.statusMessageRef.current.setMessage(message, className, timeout);
    }

    clearStatusMessage() {
        if (!this.statusMessageRef) return;
        if (!this.statusMessageRef.current) return;
        this.statusMessageRef.current.clearMessage();
    }

    /*-------------------Env Stuff-----------*/
    isProd() {
        return process.env.REACT_APP_ENV === 'prod';
    }

    isDev() {
        return process.env.REACT_APP_ENV === 'dev';
    }

    /*-------------------Feature Stuff-----------*/
    hasFeature(feature) {
        if (!this.featureCache) {
            try {
                this.featureCache = JSON.parse(this.me.Tenant.Features || '{"*":{}}');
            } catch (e) {
                this.featureCache = { "*": {} }
            }
        }

        let domain = this.getActiveDomain();
        let dn = domain ? domain.Name : '*';
        let querySet = this.featureCache[dn] || this.featureCache['*'];
        if (querySet[feature]) {
            return true;
        } else {
            return false;
        }
    }

    denyFeature() {
        if (this.history) this.history.push('/');
        let errorCode = 'account:98';
        this.dispatch(errorActions.localError(errorCode, { errorCode, message: this.t('util.noFeatureAccess') }));
        window.setTimeout(() => {
            this.dispatch(errorActions.clearLocalError(errorCode));
        }, 5000);
    }

    /*-------------------Cache Helpers-----------*/
    clearProjectionsCache() {
        let projStore = new IdbKv.Store('ProjectionsStoreDB', 'ProjectionsStore');
        IdbKv.clear(projStore);
    }

    clearDataExplorerCache() {
        let projStore = new IdbKv.Store('DataExplorerDb', 'DataExplorerStore');
        IdbKv.clear(projStore);
    }

    fromDpCache(key, func) {
        if (!this.dpCache[key]) {
            this.dpCache[key] = func();
        }
        return this.dpCache[key];
    }

    clearDpCacheItem(key) {
        this.dpCache[key] = null;
    }

    clearDpCache() {
        this.dpCache = {};
    }
}