/* global EM */
/* eslint eqeqeq: "off" */
import React, { Component } from 'react';
import StaffingUtil from '../../util/StaffingUtil';

export default class StaffingGroupTotalRow extends Component{     
    render(){
        let {assignments, range, allocation} = this.props;
        
        let assignmentDateGroups = {};
        if (assignments){
            assignments.forEach((assignment) => {
                assignment.dateKeys.forEach(dateKey => {
                    if (!assignmentDateGroups[dateKey])assignmentDateGroups[dateKey] = 0;
                    assignmentDateGroups[dateKey] += assignment.Value;
                })
            });
        }

        let marginBuffer = 0;
        let cellWidth = this.props.cellWidth;

        return (
            <div className={"item total"}>
                <div className="item-header">{EM.t('staffing.total')}:</div>
                {range.dates.map((month, monIndex) => {   
                    let dateKey = range.beginIndex + monIndex;
                    let value = (assignmentDateGroups[dateKey] * 100);
                    let allocationValue = (allocation * 100);
                    let percentage = value/allocationValue;
                    let cls = StaffingUtil.getColorClass(percentage, 1);
                    if (percentage > 0){
                        let output = (
                            <div className={"item-element " + cls} key={monIndex} style={{ marginLeft: marginBuffer * cellWidth }} title={value.toFixed(2) + ' / ' + allocationValue.toFixed(2)}>
                                {(percentage*100).toFixed()}%
                            </div>
                        );
                        marginBuffer = 0;
                        return output;
                    }else{
                        marginBuffer++;
                        return null;
                    }
                })}                
            </div>
        );
    }
}