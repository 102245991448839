/* global EM */
import * as types from '../actionTypes';

export function clearDomainEntity(entity) {
    return { type: 'CLEAR_' + entity.name.toUpperCase() };
}

export function loadDomainEntity(domainId, entity, isSilent) {
    let entityName = entity.name;
    let entityIdField = entity.idField;
    return (dispatch, getState, api) => {
        return api.loadDomainEntity(domainId, entityName, isSilent).then(data => {
            if (entityName !== 'settings'){
                let limitSetting = EM.getSetting('Limit:' + entityName.capitalize());
                if (limitSetting){
                    let limitSet = limitSetting.split(',');
                    if (limitSet.length > 0){
                        data = data.filter(item => {
                            return limitSet.indexOf(item[entityIdField].toString()) > -1;
                        });
                    }
                }
            }
            return dispatch({ type: 'LOAD_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function createDomainEntity(domainId, entity, newItem) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.createDomainEntity(domainId, entityName, newItem).then(data => {
            return dispatch({ type: 'CREATE_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function createBulkDomainEntity(domainId, entity, newItems) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.createDomainEntity(domainId, entityName, newItems).then(data => {
            return dispatch({ type: 'CREATE_BULK_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function updateDomainEntity(domainId, entity, newItem, oldItem) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        //Optimisitically update the value for immediate re-render
        dispatch({ type: 'UPDATE_' + entityName.toUpperCase(), data: newItem });
        return api.updateDomainEntity(domainId, entityName, newItem).catch(error => {
            EM.setError(error);
            return dispatch({ type: 'UPDATE_' + entityName.toUpperCase(), data: oldItem });
        });
    };
}

export function deleteDomainEntity(domainId, entity, ids) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.deleteDomainEntity(domainId, entityName, ids).then(data => {
            return dispatch({ type: 'DELETE_' + entityName.toUpperCase(), data: ids });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function clearAllDomainEntity(domainId, entity) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.clearDomainEntity(domainId, entityName).then(data => {
            return dispatch({ type: 'CLEARALL_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function importDomainEntity(domainId, entity, items, isSilent, modsOnly) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.importDomainEntity(domainId, entityName, items, isSilent, modsOnly).then(data => {
            return dispatch({ type: 'LOAD_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function loadAdminEntity(tenantId, entity, entityId) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.loadAdminEntity(tenantId, entityName, entityId ? entityId : "").then(data => {
            return dispatch({ type: 'LOAD_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function createAdminEntity(tenantId, entity, newItem, entityId) {
    let entityName = entity.name;

    return (dispatch, getState, api) => {
        return api.createAdminEntity(tenantId, entityName, newItem, entityId).then(data => {
            return dispatch({ type: 'CREATE_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function deleteAdminEntity(tenantId, entity, ids) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.deleteAdminEntity(tenantId, entityName, ids).then(data => {
            return dispatch({ type: 'DELETE_' + entityName.toUpperCase(), data: ids });
        }).catch(error => {
            EM.setError(error);
        });
    };
}

export function updateAdminEntity(tenantId, entity, newItem, oldItem) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        dispatch({ type: 'UPDATE_' + entityName.toUpperCase(), data: newItem });
        return api.updateAdminEntity(tenantId, entityName, newItem).catch(error => {
            EM.setError(error);
            return dispatch({ type: 'UPDATE_' + entityName.toUpperCase(), data: oldItem });
        });
    };
}

export function importAdminEntity(tenantId, entity, items, isSilent) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.importAdminEntity(tenantId, entityName, items, isSilent).then(data => {
            return dispatch({ type: 'LOAD_' + entityName.toUpperCase(), data: data });
        }).catch(error => {
            EM.setError(error);
        });;
    };
}

export function auditDomainEntity(domainId, entity, entityId) {
    let entityName = entity.name;
    return (dispatch, getState, api) => {
        return api.auditDomainEntity(domainId, entityName, entityId).then(data => {
            return dispatch({ type: types.AUDIT_ENTITY_ITEM, key: entityName + entityId, data: data });
        }).catch(error => {
            EM.setError(error);
        });
    };
}