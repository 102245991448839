/* global EM */
import React, { Component } from 'react';
import { Button, ButtonGroup, Nav, NavItem, NavLink, TabContent, TabPane, FormGroup, Label, Input } from 'reactstrap';
import FilterSelection from '../FilterSelection';
import classnames from 'classnames';
import MonthPicker from '../MonthPicker';
import Dates from '../../util/Dates';
import DemandPreferences from '../../entities/preferences/DemandPreferences';
import DemandToolsDatasetOptions from './DemandToolsDatasetOptions';
import CannedDateRangeSelector from '../CannedDateRangeSelector';
import OptionSwitcher from '../OptionSwitcher';

export default class DemandToolsPanel extends Component {
    constructor(props) {
        super(props);

        this.defaultPrefs = new DemandPreferences('default');
        this.state = {
            currentPreferences: this.defaultPrefs,
            activeTab: 'Filters'
        }

        this.prefs = this.defaultPrefs.group;
        this.onChange = this.onChange.bind(this);
        this.onTabChange = this.onTabChange.bind(this);
        this.addResetPreferences = this.addResetPreferences.bind(this);
        this.addResetFilters = this.addResetFilters.bind(this);
        this.setPreferences = this.setPreferences.bind(this);
        this.deletePreferences = this.deletePreferences.bind(this);
    }

    addResetPreferences(name, startFromCurrent) {
        let self = this;
        let newSet = startFromCurrent ? self.state.currentPreferences.clone().inst : {};
        self.prefs(name, newSet);
        self.setPreferences(name);
    }

    addResetFilters(keyName, filterValues) {
        let self = this;

        let clonedOldSet = self.state.currentPreferences.clone().inst;

        //remove main filters
        for (let index = 0; index < filterValues.length; index++) {
            delete clonedOldSet[filterValues[index].name];
        }

        //remove three filters that left
        delete clonedOldSet.organization;
        delete clonedOldSet.department;
        delete clonedOldSet.role;

        self.prefs(keyName, clonedOldSet);
        self.setPreferences(keyName);
    }

    deletePreferences(name) {
        let self = this;
        self.prefs(name, null, true);
        self.setPreferences('default');
    }

    setPreferences(name, suppressNotification) {
        let self = this;
        let newPrefs = new DemandPreferences(name);
        self.setState({ currentPreferences: newPrefs });
        if (!suppressNotification) self.props.onChange(newPrefs);
    }

    onChange(preferences) {
        let self = this;
        let newPrefs = preferences ? preferences.clone() : self.state.currentPreferences.clone();
        self.setState({ currentPreferences: newPrefs }, () => {
            window.setTimeout(() => {
                self.props.onChange(newPrefs);
            }, 100);
        });
    }

    onTabChange(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({ activeTab: tab });
        }
    }

    render() {
        let preferences = this.state.currentPreferences;

        let optSets = [{ label: 'Default', value: 'default' }, ...this.prefs().map(key => {
            if (key === 'default') return null;
            return { label: key, value: key }
        }).filter((el) => el != null)];
        let selectedOptSet = optSets.find(os => os.value === preferences.name);

        let beginDate = preferences.begin ? Dates.fromStr(preferences.begin, Dates.DATE_PREF) : null;
        let endDate = preferences.end ? Dates.fromStr(preferences.end, Dates.DATE_PREF) : null;

        let dataSets = this.props.getAvailableDataSets;
        let primaryDatasets = dataSets;
        let noneText = EM.t('demand.tools.none');
        let secondaryDatasets = [{ label: noneText, value: null }, ...dataSets];
        let tertiaryDatasets = [{ label: noneText, value: null }, ...dataSets];

        let primarySelected = preferences.primary.dataset || primaryDatasets[0].options[0];
        let secondarySelected = preferences.secondary.dataset || secondaryDatasets[0];
        let tertiarySelected = preferences.tertiary.dataset || tertiaryDatasets[0];

        let isSecondaryDisabled = false; //!(preferences.primary.dataset&&preferences.primary.dataset.value);
        let isTertiaryDisabled = isSecondaryDisabled || !(preferences.secondary.dataset && preferences.secondary.dataset.value);

        return (
            <div className="tools-panel">
                <div className="tools-panel-header">
                    <h5>{EM.t('demand.tools.title')}</h5>
                    <button type="button" className="close" aria-label="Close" onClick={this.props.onFilterClose}>
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div className="tools-panel-content">
                    <OptionSwitcher
                        options={optSets}
                        value={selectedOptSet}
                        addResetPreferences={this.addResetPreferences}
                        deletePreferences={this.deletePreferences}
                        onChange={this.setPreferences}
                    />
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === 'Filters' })}
                                onClick={() => { this.onTabChange('Filters'); }}
                            >{EM.t('demand.tools.filters')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === 'Data' })}
                                onClick={() => { this.onTabChange('Data'); }}
                            >{EM.t('demand.tools.data')}</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab}>
                        {this.props.filterValues ?
                            <TabPane tabId="Filters">
                                <div className="form-group">
                                    <label>{EM.t('demand.tools.dateRange')}:</label><br />
                                    <div className="month-range">
                                        <MonthPicker
                                            placeholder={EM.t('demand.tools.from')}
                                            date={beginDate}
                                            onChange={(date) => {
                                                preferences.begin = date.toFormat(Dates.DATE_PREF);
                                                this.onChange();
                                            }}
                                        />
                                        <MonthPicker
                                            placeholder={EM.t('demand.tools.to')}
                                            date={endDate}
                                            onChange={(date) => {
                                                preferences.end = date.toFormat(Dates.DATE_PREF);
                                                this.onChange();
                                            }}
                                        />
                                        <CannedDateRangeSelector
                                            onSelect={(begin, end) => {
                                                preferences.begin = begin ? begin.toFormat(Dates.DATE_PREF) : null;
                                                preferences.end = end ? end.toFormat(Dates.DATE_PREF) : null;
                                                this.onChange();
                                            }}
                                            custom={[
                                                {
                                                    label: EM.t('util.tools.fullRange'),
                                                    setter: event => {
                                                        if (this.props.getPrimaryDatasetDateRange) {
                                                            let range = this.props.getPrimaryDatasetDateRange();
                                                            if (range) {
                                                                preferences.begin = range[0].toFormat(Dates.DATE_PREF);
                                                                preferences.end = range[1].toFormat(Dates.DATE_PREF);
                                                                this.onChange(preferences);
                                                            }
                                                        }
                                                    }
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                                <ButtonGroup>
                                    <Button active={preferences.dateGrouping === 'Month'} onClick={() => {
                                        preferences.dateGrouping = 'Month';
                                        this.onChange();
                                    }}>{EM.t('demand.tools.month')}</Button>
                                    <Button active={preferences.dateGrouping === 'Quarter'} onClick={() => {
                                        preferences.dateGrouping = 'Quarter';
                                        this.onChange();
                                    }}>{EM.t('demand.tools.quarter')}</Button>
                                    <Button active={preferences.dateGrouping === 'Year'} onClick={() => {
                                        preferences.dateGrouping = 'Year';
                                        this.onChange();
                                    }}>{EM.t('demand.tools.year')}</Button>
                                </ButtonGroup>
                                <hr />
                                <ButtonGroup>
                                    <Button active={preferences.grouping === 'Role'} onClick={() => {
                                        preferences.grouping = 'Role';
                                        this.onChange();
                                    }}>{EM.t('demand.tools.role')}</Button>
                                    <Button active={preferences.grouping === 'Department'} onClick={() => {
                                        preferences.grouping = 'Department';
                                        preferences.set('role', null);
                                        this.onChange();
                                    }}>{EM.t('demand.tools.department')}</Button>
                                    <Button active={preferences.grouping === 'Organization'} onClick={() => {
                                        preferences.grouping = 'Organization';
                                        preferences.set('role', null);
                                        preferences.set('department', null);
                                        this.onChange();
                                    }}>{EM.t('demand.tools.organization')}</Button>
                                </ButtonGroup>
                                <Button className="reset-filters" size="sm" color="secondary" title="Clear Filters" outline onClick={() => {
                                        let confirmation = window.confirm(EM.t('demand.tools.resetFiltersWarning'));
                                        if (!confirmation) return;
                                        this.addResetFilters(selectedOptSet.value, this.props.filterValues);
                                    }}><i className="fas fa-filter"></i>
                                </Button>
                                <FilterSelection
                                    selected={preferences.get('role')}
                                    key={'role'}
                                    options={Object.keys(EM.roles.asValueList()).sort()}
                                    label={'Role'}
                                    disabled={preferences.grouping === 'Department' || preferences.grouping === 'Organization'}
                                    onChange={(values) => {
                                        preferences.set('role', values);
                                        this.onChange();
                                    }} />
                                <FilterSelection
                                    selected={preferences.get('department')}
                                    key={'department'}
                                    options={Object.keys(EM.departments.asValueList()).sort()}
                                    label={'Department'}
                                    disabled={preferences.grouping === 'Organization'}
                                    onChange={(values) => {
                                        preferences.set('department', values);
                                        this.onChange();
                                    }} />
                                <FilterSelection
                                    selected={preferences.get('organization')}
                                    key={'organization'}
                                    options={Object.keys(EM.organizations.asValueList()).sort()}
                                    label={'Organization'}
                                    disabled={false}
                                    onChange={(values) => {
                                        preferences.set('organization', values);
                                        this.onChange();
                                    }} />
                                <hr />
                                <div>
                                    {this.props.filterValues.map((filter, index) => {
                                        return <FilterSelection
                                            selected={preferences.get(filter.name)}
                                            key={filter.name}
                                            options={filter.values}
                                            label={filter.label}
                                            onChange={(values) => {
                                                preferences.set(filter.name, values);
                                                this.onChange();
                                            }} />
                                    })}
                                </div>
                            </TabPane>
                            : null}
                        <TabPane tabId="Data">
                            <DemandToolsDatasetOptions
                                title={EM.t('demand.tools.primaryDataset')}
                                datasetPrefs={preferences.primary}
                                datasets={primaryDatasets}
                                selected={primarySelected}
                                onChange={this.onChange}
                            />
                            <hr />
                            <DemandToolsDatasetOptions
                                title={EM.t('demand.tools.secondaryDataset')}
                                datasetPrefs={preferences.secondary}
                                datasets={secondaryDatasets}
                                selected={secondarySelected}
                                onChange={this.onChange}
                                isDisabled={isSecondaryDisabled}
                            />
                            <hr />
                            <DemandToolsDatasetOptions
                                title={EM.t('demand.tools.tertiaryDataset')}
                                datasetPrefs={preferences.tertiary}
                                datasets={tertiaryDatasets}
                                selected={tertiarySelected}
                                onChange={this.onChange}
                                isDisabled={isTertiaryDisabled}
                            />
                            <div className="mt-2">
                                <hr />
                                <FormGroup check>
                                    <Label check>
                                        <Input type="checkbox" checked={preferences.plotLines} onChange={(event) => {
                                            preferences.plotLines = event.target.checked;
                                            this.onChange();
                                        }} />
                                        {EM.t('demand.tools.showPlotlines')}
                                    </Label>
                                </FormGroup>
                            </div>
                        </TabPane>
                    </TabContent>

                </div>
            </div>
        );
    }
}
