/* global EM */
import React, { Component } from 'react';
import PageTitle from  '../components/PageTitle';
import DomainCard from  '../components/DomainCard';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import _ from 'underscore';

class Home extends Component {
    constructor(props){
        super(props);
        
        EM.clearActiveDomain();    
    }

    alpha(set){
        return _.sortBy(set, item => {
            return (item.order || 'z') + item.DisplayName;
        });
    }

    render() { 
        let topLevelDomains = _.groupBy(this.props.account.Domains, 'ParentDomainId');        
        let topLevelKeys = Object.keys(topLevelDomains);

        if (!topLevelDomains['null'])topLevelDomains['null'] = [];

        topLevelKeys.forEach(key => {
            if (key === 'null')return;
            let hasParent = topLevelDomains['null'].find(d => d.DomainId.toString() === key);
            if (!hasParent){
                topLevelDomains['null'].push({ DomainId: key, DisplayName: null });
            }
        });

        return (
            <div key="contents" className="page page-home page-bg container-fluid">  
                <div className="page-header">
                    <div className="container" style={{ zIndex: 3 }}>
                        <PageTitle icon="far fa-cube" title={this.props.account.Tenant.DisplayName} text={EM.t('home.instructions')}>
                            {!this.props.account.Tenant.Active ? 
                                <div className="alert alert-danger">This Tenant has been disabled. Only Super Admins may log in.</div>
                            : null }
                        </PageTitle>
                        { !EM.getTenantOption('redirectLink') ? 
                            <>
                                {EM.getTenantOption('pbixTemplate') ? 
                                    <div>
                                        <UncontrolledButtonDropdown size="sm" className="utility-menu">
                                            <DropdownToggle color="success" aria-label="Analytics Menu" title="Analytics Menu">
                                                <i className="far fa-cubes font-lg"></i>&nbsp;<i className="fas fa-caret-down"></i>
                                            </DropdownToggle>
                                            <DropdownMenu right positionFixed={true}>
                                                <DropdownItem onClick={event => {
                                                    window.location = EM.getTenantOption('pbixTemplate');
                                                }}>
                                                    {EM.getTenantOption('pbixLinkText') || EM.t('home.analytics')}
                                                </DropdownItem>                                        
                                                { EM.getTenantOption('pbixAuthType') === 'Connector' ?
                                                    <DropdownItem onClick={event => {
                                                        window.location = 'RM.Analytics.mez';
                                                    }}>
                                                        {EM.t('home.analyticsConnector')}
                                                    </DropdownItem>
                                                : null }
                                                { EM.getTenantOption('pbixAuthType') === 'Request Token' || EM.getTenantOption('pbixAuthType') === 'Generate Token' ?
                                                    <DropdownItem disabled>
                                                        {EM.t('home.analyticsRequestToken')}
                                                    </DropdownItem>
                                                : null }
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown> 
                                    </div>
                                : null }
                            </>
                        : null }
                    </div>                
                </div>
                <div className="container">  
                    { EM.getTenantOption('redirectLink') ?                   
                        <div>
                            <h5>{EM.t('home.redirectMessage')}</h5>
                            <h5 className="m-4"><b><a href={EM.getTenantOption('redirectLink')}>{EM.getTenantOption('redirectLink')}</a></b></h5>
                        </div>
                    :
                        <div className="domain-top" tabIndex="  " >
                            { this.alpha(topLevelDomains['null']).map(domain =>  {        
                                return (
                                    <DomainCard domain={domain} key={domain.DomainId} history={this.props.history}>
                                        { topLevelDomains[domain.DomainId] ? this.alpha(topLevelDomains[domain.DomainId]).map(subdomain => 
                                            <DomainCard domain={subdomain} key={subdomain.DomainId} history={this.props.history}/>
                                        ) : null }
                                    </DomainCard>
                                )
                            })}                    
                            { topLevelKeys.length === 0 ? 
                                <div className="alert alert-secondary">
                                    <h4 className="alert-heading">{EM.t('home.noDomainsHeader')}</h4>
                                    <p className="mb-0">{EM.t('home.noDomainsText')}</p>
                                </div>
                            : null }
                        </div>
                    }
                </div>                            
            </div> 
        );
    }
}

export default Home;
