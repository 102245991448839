/* global EM */
import React, { Component } from 'react';
import Dates from '../util/Dates';
import TextareaAutosize from 'react-textarea-autosize';
import UserIconAndName from './UserIconAndName';
import NotesFile from '../entities/files/NotesFile';

export default class DomainNotes extends Component {
    constructor(props){
        super(props);

        this.state = {           
            currentText: '',
            currentFile: null
        }

        EM.notes.load();
        if (EM.isDomainEditor())EM.users.load();

        this.onFileSelected = this.onFileSelected.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDownload = this.onDownload.bind(this);
    }

    onFileSelected(event){
        var file = event.target.files[0]; 
        if (!file)return;
        if (file.size > 5000000){
            alert(EM.t('notes.sizeWarning'));
            return;
        }
        if (!file.type || !NotesFile.checkFileType(file.type)){
            alert(EM.t('notes.invalidType'));
            return;
        }

        var fr = new FileReader();
        fr.onload = (event) => {
            let fileContents = event.target.result;
            if (!fileContents)return;   
            this.setState({ currentFile: { name: file.name, contents: fileContents, type: file.type }});
        };  
        fr.readAsDataURL(file);   
    }

    onCreate(){
        let text = this.state.currentText;
        if (!text && !this.state.currentFile)return;
        let newRecord = {
            Grouping: this.props.contentKey,
            Value: text,
            CreatedByName: EM.me.Name
        };

        if (this.state.currentFile){
            newRecord.FileName = this.state.currentFile.name;
            newRecord.File = this.state.currentFile;
        }

        EM.notes.create(newRecord);   
        
        this.setState({ currentText: '', currentFile: null });
    }

    onDelete(noteId){
        let $elem = document.getElementById('Note' + noteId);
        $elem.classList.add('hide');
        EM.notes.delete([noteId]);
        
    }

    onDownload(note){
        EM.notes.loadFile(note.NoteId).then(dispatchedResponse => {
            if (dispatchedResponse.data)dispatchedResponse.data.download();
            window.setTimeout(() => {
                EM.notes.invalidateFile(note.NoteId);
            }, 60000);
        });
    }

    render() {
        if (!this.props.contentKey)return null;
        let notesProcessed = this.props.notes ? this.props.notes.filter(note => note.Grouping === this.props.contentKey).reverse() : [];
        return (
            <div className="notes">
                {EM.isDomainEditor() ?  
                    <div className="controls">
                        <TextareaAutosize tabIndex="1" maxRows={5} className="form-control" placeholder={EM.t('notes.placeholder')} value={this.state.currentText} 
                            onChange={(event) => {
                                let value = event.target.value;
                                value = value.replace('\n', '');
                                this.setState({ currentText: value });
                            }} 
                        />

                        <div className="mt-1 clearfix">
                            <div className="file-browser float-left">
                                <input type="file" className="file-browser-input" id="customFile" onChange={this.onFileSelected}/>
                                <label className="file-browser-label" htmlFor="customFile">
                                    <i className="fas fa-paperclip"></i>
                                    {this.state.currentFile ? this.state.currentFile.name : ''}
                                </label>
                                {this.state.currentFile ?
                                    <button className="btn btn-sm btn-remove" onClick={() => {
                                        this.setState({ currentFile: null });
                                    }}>
                                        <i className="fas fa-times"></i>
                                    </button>    
                                : null}
                            </div>

                            <button tabIndex="2" className="btn btn-sm btn-primary float-right" onClick={this.onCreate}>{EM.t('notes.add')}</button>
                        </div>
                    </div>
                : null }

                <div className="history">
                    {notesProcessed.map((note) => {
                        let user = EM.users.byId(note.CreatedBy);
                        return <div key={note.NoteId} id={'Note' + note.NoteId}>                            
                            <div className="meta">
                                <UserIconAndName user={user} altUser={user ? null : note.CreatedByName} className="faded font-xs"/>
                                <div>{Dates.isoToDateTime(note.CreatedOn)}</div>
                            </div>                                                        
                            {note.Value ? 
                                <p>{note.Value}</p>  
                            : null }
                            {note.FileName ? 
                                <button className={"btn btn-link " + (note.Value?'mt-2':'')} onClick={() => {
                                    this.onDownload(note);
                                }}>{note.FileName}</button>
                            : null }
                            {EM.isDomainAdmin() || (EM.isDomainEditor() && EM.me.User.UserId === note.CreatedBy)? 
                                <div className="editing">
                                    <button className="btn" onClick={() => this.onDelete(note.NoteId)}>
                                        <i className="fas fa-trash-alt"></i>
                                    </button>
                                </div>
                            : null }
                        </div>
                    })}

                    {notesProcessed.length === 0 ?
                        <div className="text-muted font-xs p-3">{EM.t('notes.nodata')}</div>
                    : null}
                </div>  
            </div>
        );
    };
}
