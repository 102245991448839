/* global EM */
import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import Routes from '../../app/Routes';
import Spinner from '../Spinner';
import ProjectionsManager from '../../entities/ProjectionsManager';

const WorkItemsNoAssingmentsWidget = (props) => {

    let domain = EM.getActiveDomain();
    let staffingWiUrl = Routes.compose(Routes.client.staffingWorkItem, { DomainName: domain.Name }) + '#';
    let maxNumOfItemsToShow = 15;

    let [workItems, setWorkItems] = useState(null);
    let stringifiedWorkItems = JSON.stringify(workItems);

    useEffect(() => {
        let projectionsManager = new ProjectionsManager();

        EM.schedules.loadDefaultItem().then((defaultSchedule) => {
            if (!defaultSchedule) return;

            let defaultProjections = defaultSchedule.Projections;
            if (defaultProjections.length === 0) return;

            projectionsManager.loadDefaultProjections().then(projectionsData => {

                let projectionsFile = projectionsData.data;

                if (!projectionsFile) return;

                EM.loadEntities([EM.assignments]).then(() => {
                    let rawAssignments = EM.assignments.get();
                    const uniqueAssignmentNames = [...new Set(rawAssignments.map(assignment => assignment.WorkItemName))];

                    let workItems = new Set();
                    for (let index = 1; index < projectionsFile.length; index++) {
                        const projection = projectionsFile[index];
                        let workItemName = projection[0];
                        if (!uniqueAssignmentNames.includes(workItemName)) {
                            workItems.add(workItemName);
                        }
                    }

                    setWorkItems([...workItems]);
                });
            });

        });

    }, [stringifiedWorkItems]);

    return (
        <div>
            <h4>{EM.t('widgets.workItemsNoAssingments.title')}</h4>
            <div className="card mt-2 widget">
                <div className="card-body">
                    <div className="card-text">
                        {workItems ?
                            <div className="pills">
                                {workItems.slice(0, maxNumOfItemsToShow).map((item, itemIndex) => {

                                    return (
                                        <NavLink to={staffingWiUrl + item.toIdToken()}
                                            key={item + itemIndex}
                                            title={item}
                                        >
                                            <b>{item}</b>
                                        </NavLink>
                                    )
                                })}
                                {workItems.length > maxNumOfItemsToShow ?
                                    <NavLink key={'over'} className="overflow-message" to={staffingWiUrl}>
                                        Plus {workItems.length - maxNumOfItemsToShow} more...
                                    </NavLink>
                                    : null}
                                {workItems.length === 0 ?
                                    <NavLink key={'over'} className="overflow-message" to={staffingWiUrl}>
                                        No Work Items found.
                                    </NavLink>
                                    : null}
                            </div>
                            : <Spinner text={EM.t('util.loading')} classes="small" />}
                    </div>
                </div>
            </div>
        </div>
    );

}

export default WorkItemsNoAssingmentsWidget;