import Highcharts from 'highcharts/es-modules/Core/Globals.js';
import 'highcharts/es-modules/Core/Renderer/SVG/SVGRenderer.js';
import 'highcharts/es-modules/Core/Renderer/HTML/HTMLElement.js';
import 'highcharts/es-modules/Core/Renderer/HTML/HTMLRenderer.js';
import 'highcharts/es-modules/Core/Axis/Axis.js';
import 'highcharts/es-modules/Core/Axis/DateTimeAxis.js';
import 'highcharts/es-modules/Core/Axis/PlotLineOrBand.js';
import 'highcharts/es-modules/Core/Tooltip.js';
import 'highcharts/es-modules/Core/Pointer.js';
import 'highcharts/es-modules/Core/MSPointer.js';
import 'highcharts/es-modules/Core/Legend.js';
import 'highcharts/es-modules/Core/Chart/Chart.js';
import 'highcharts/es-modules/Extensions/Stacking.js';
import 'highcharts/es-modules/Core/Dynamics.js';
import 'highcharts/es-modules/Series/AreaSeries.js';
import 'highcharts/es-modules/Series/ColumnSeries.js';
import 'highcharts/es-modules/Series/PieSeries.js';
import 'highcharts/es-modules/Core/Interaction.js';

import 'highcharts/es-modules/Extensions/Exporting.js';
import 'highcharts/es-modules/Extensions/OfflineExporting.js';
import 'highcharts/es-modules/Extensions/NoDataToDisplay.js';


// Override the legend getAllItems function to filter out empty sets
Highcharts.wrap(Highcharts.Legend.prototype, 'getAllItems', function (proceed) {
    var allItems = [];
    this.chart.series.forEach(function (series) {
        if (!series)return;
        var seriesOptions = series && series.options;
        let foundItem = series.options.data.find(item => item[1]);
        if (!!foundItem && series.visible){
            allItems = allItems.concat(series.legendItems ||
                (seriesOptions.legendType === 'point' ?
                    series.data :
                    series));
        }else{
            return;
        }
    });
    return allItems;
});

export default Highcharts;