/* global EM */
import Entity from './Entity';
import * as entityActions from './actions/entities';

export default class TenantEntity extends Entity{
    async load(force){
        let state = this.store.getState();
        if (!state[this.name] || force){            
            await this.dispatch(entityActions.loadAdminEntity('*', this));
        }        
    }

    async create(newItem, entityId) {
        let created = await this.dispatch(entityActions.createAdminEntity('*', this, newItem, entityId));
        this.resetCachedLists();
        return created;
    }

    async delete(ids) {
        await this.dispatch(entityActions.deleteAdminEntity('*', this, ids));
        this.resetCachedLists();
    }

    async update(oldItem, newItem) {
        let updated = await this.dispatch(entityActions.updateAdminEntity('*', this, newItem, oldItem));
        this.resetCachedLists();
        return updated;
    }   
    
    async switch(newTenantId) {
        await this.dispatch((() => {
            return (dispatch, getState, api) => {
                return api.switchTenant(newTenantId).then(() => {
                    window.location = window.location.origin;
                }).catch(error => {
                    EM.setError(error);
                });
            };
        })());
    }
}