/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import { Validators } from '../util/EntityValidators';

class Complexities extends Component {
    constructor(props){
        super(props);

        EM.complexities.load();
        EM.activities.load();
        EM.roles.load();
        EM.complexityLookup.load();
        EM.resourceRequests.load();

        this.calculatePrecedence = this.calculatePrecedence.bind(this);
        this.sortByPrecedence = this.sortByPrecedence.bind(this);
    }

    calculatePrecedence(cell, row){
        let prec = 0;
        if (row.WorkItemName)prec += 1;
        if (row.ActivityId)prec += 1;
        if (row.RoleId)prec += 1;
        if (row.AttributeKey || row.AttributeValue)prec += 1;
        return prec;
    }

    sortByPrecedence(a, b, order, dataField, rowA, rowB){    
        var self = this;
        let aP = self.calculatePrecedence(null, rowA);
        let bP = self.calculatePrecedence(null, rowB);
        if (order === 'desc') {
            return parseInt(aP) - parseInt(bP);
        } else {
            return parseInt(bP) - parseInt(aP);
        }
    }    

    render() {
        let data = null;
        let columns = null;
        const entity = EM.complexities;

        if (EM.allLoaded(entity, EM.activities, EM.roles, EM.complexityLookup)){    
            data = entity.get();
            columns = [{
                dataField: 'WorkItemName',
                text: entity.columns('workItemTitle'),
                asWorkItem: true,
                width: 150,
                filterOptions: {
                    text: true
                }
            }, {
                dataField: 'ActivityId',
                text: entity.columns('activity'),
                fromEntity: EM.activities,
                allowNulls: true,
                width: 150
            }, {
                dataField: 'RoleId',
                text: entity.columns('role'),
                fromEntity: EM.roles,
                allowNulls: true,
                width: 150
            }, {
                dataField: 'AttributeKey',
                text: entity.columns('otherKey'),
                width: 150,
                filterOptions: {
                    text: true
                }      
            }, {
                dataField: 'AttributeValue',
                text: entity.columns('otherValue'),
                width: 150,
                filterOptions: {
                    text: true
                }
            }, {
                dataField: 'ComplexityLookupId',
                text: entity.columns('complexity'),
                fromEntity: EM.complexityLookup,
                omitEntitySort: true,
                width: 85,        
                validators: [ Validators.required ]
            }, {
                dataField: 'precedence',
                text: entity.columns('precedence'),
                isReference: true,
                formatter: this.calculatePrecedence,
                sortFunc: this.sortByPrecedence,
                width: 100,
                filterValue: this.calculatePrecedence,                
                filterOptions: {
                    number: true
                }
            }, {
                dataField: 'Active',
                text: entity.columns('active'),
                asActive: true,
                width: 75
            }, {
                dataField: 'Description',
                text: entity.columns('description'),
                width: 200,
                asDescription: true,
                filterOptions: {
                    text: true
                }  
            }];
        }

        return (
            <div key="contents" className="page container-fluid">
                <EntityTable entity={entity} data={data} columns={columns} className="table-overflow-container"/>
            </div>
        );
    }
}

export default Complexities;