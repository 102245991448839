/* global EM */
import React from 'react';
import UserIcon from './UserIcon';

const UserIconAndName = ({ user, className, altUser}) => {
    if (!user && !altUser){
        return <div className={'user-icon-name ' + (className?className:"")}>{EM.t('users.userRemoved')}</div>
    }

    return (
        <div className={'user-icon-name ' + (className?className:"")}>
            <UserIcon user={user} altUser={altUser} className={"user-icon small mr-1" } />
            {altUser ? altUser : user.Name}
        </div>
    );
};

export default UserIconAndName;