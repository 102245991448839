import * as types from '../actionTypes';
const initialState = {
    error: null,
    successMessage: null
};

const resourceReducer = (state = initialState, action) => {
    switch(action.type) {
        case types.POST_RESOURCE_REQUEST_SUCCESS:
            return {
                ...state,
                successMessage: 'Resource request submitted successfully',
                error: null
            };
        case types.POST_RESOURCE_REQUEST_FAILURE:
            return {
                ...state,
                error: action.error,
                successMessage: null
            };
        default:
            return state;
    }
};

export default resourceReducer;