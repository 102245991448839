/* global EM */
import React, { useState, useEffect } from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, FormText, CustomInput, Label, FormFeedback } from 'reactstrap';
import PanelModal from '../PanelModal';
import File from '../../util/File';

const CreateNewDomainDialog = (props) => {

    let [templatedDomainFile, setTemplatedDomainFile] = useState(null);
    let [newDomainName, setNewDomainName] = useState(null);
    let [canCreateDomain, setCanCreateDomain] = useState(false);
    let [errors, setErrors] = useState({});

    useEffect(() => {
        if (newDomainName && templatedDomainFile) {
            setCanCreateDomain(true);
        } else {
            setCanCreateDomain(false);
        }
    }, [newDomainName, templatedDomainFile])

    async function parseFile() {
        var input = document.getElementById('importFile');

        try {
            let contents = await File.read(input);
            let parsedJson = simpleDomainFileValidation(contents)

            if (parsedJson) {
                setTemplatedDomainFile(parsedJson);
                delete errors.file;
                let errs = Object.assign({}, errors)
                setErrors(errs);
            } else {
                let errs = Object.assign({}, errors, { file: "Something went wrong. Try again." });
                setErrors(errs);
            }

        } catch (e) {
            let errs = Object.assign({}, errors, { file: e.message });
            console.log(e);
            setErrors(errs);
            return;
        }
    }
    function onClose() {
        props.onClose();
    }
    
    return (
        <PanelModal fade={false} isOpen={props.isOpen} toggle={onClose} className={'panel panel-full ' + props.className} key="import-modal">
            <ModalHeader toggle={onClose}>{EM.t('domains.import.header')}</ModalHeader>
            <ModalBody>
                <div className='form-create'>
                    <FormGroup className={errors['file'] ? 'custom is-invalid' : ''}>
                        <Label for="importFile">{EM.t('util.table.importLabel')}</Label>
                        <CustomInput type="file" name="file" id="importFile" accept=".json"
                            onClick={event => event.target.value = null}
                            onChange={async (event) => {
                                let fileName = event.target.value.replace("C:\\fakepath\\", "");
                                document.getElementsByClassName('custom-file-label')[0].innerText = fileName;
                                await parseFile();
                            }}
                            label={EM.t('domains.import.selectFile.label')}
                            valid={errors.file ? false : true} />
                        <FormFeedback>{errors.file}</FormFeedback>
                        <FormText color="muted">
                            {EM.t('domains.import.selectFile.instructions')}
                        </FormText>
                    </FormGroup>
                    <FormGroup className={errors['domainName'] ? 'custom is-invalid' : ''}>
                        <Label for="domainName">
                            {EM.t('domains.import.inputDomainName.label')}
                        </Label>
                        <Input id="domainName" name="domainName" placeholder={EM.t('domains.import.inputDomainName.placeholder')}
                            onChange={(e) => {
                                let domainDisplayName = e.target.value;
                                let validatedName = validateDomainName(domainDisplayName)
                                setNewDomainName(validatedName);
                            }
                            }
                            valid={errors.domainName ? false : true} />
                        <FormFeedback>{errors.domainName}</FormFeedback>
                    </FormGroup>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onClose}>{EM.t('util.cancel')}</Button>
                <Button color="primary" disabled={!canCreateDomain} onClick={() => { onCreate(); onClose(); }}>{EM.t('domains.import.buttonCreate.label')}</Button>
            </ModalFooter>
        </PanelModal>
    );

    /**
     * Validates input domain name and returns a validated one
     * @param {String} domainName 
     * @returns a new validated string (truncated)
     */
    function validateDomainName(domainName) {
        if (domainName.length >= 100) {
            let errs = Object.assign({}, errors, { domainName: "Domain names longer than 100 characters will be truncated!" })
            setErrors(errs);
            domainName = domainName.substring(0, 100);
        } else {
            delete errors.domainName;
            let errs = Object.assign({}, errors)
            setErrors(errs);
        }
        return domainName;
    }
    
    async function onCreate() {
        if (!canCreateDomain) return;
        let requestBody = {};
        requestBody.domainData = templatedDomainFile;
        requestBody.tenantId = EM.me.Tenant.TenantId;
        requestBody.newDomainName = newDomainName;

        let response
        try {
            response = await EM.api.createDomainFromTemplate(requestBody);
            EM.setStatusMessage(`Nicely done! A new domain with id:${response.Id} and name : ${response.DisplayName} has been created. Please refresh the page.`);
        } catch (e) {
            EM.setStatusMessage(`Oops! Something went wrong with the new domain. Error: ${e.message}`, 'error', 10000);
        }
    }
    /**
     * 
     * @param {object} contents JSON object
     */
    function simpleDomainFileValidation(contents) {
        let parsedBody;
        try { parsedBody = JSON.parse(contents); }
        catch (e) {
            throw Error("JSON is invalid")
        }
        if (!parsedBody.domain) {
            throw Error("Domain data is missing!", "Domain data must be present to create a domain.")
        } else if (parsedBody.domain.parentDomainId) {
            if (!doesParentExistInTenant(parsedBody.domain.parentDomainId)) {
                throw Error("Parent domain is not found in the current tenant!", "Parent Domain must be present in the tenant when importing a child domain.");
            }
        }
        return parsedBody;
    }

    function doesParentExistInTenant(parentDomainId) {
        let matchingDomainId = EM.store.getState().domains.map(x => x.DomainId).find(y => y === parentDomainId);
        return matchingDomainId;
    }
}

export default CreateNewDomainDialog;