/* eslint no-return-assign: 0 */
import React, { Component } from 'react';
import MonthPicker from './MonthPicker';
import Dates from '../util/Dates';

export default class DateEditField extends Component {
    getValue(){
        return this.props.value;
    }
    
    render() {
        return (
            <MonthPicker
                placeholder={''}
                date={Dates.fromISO(this.props.value)}
                onChange={(date) => {
                    this.props.onUpdate(date.toISO());
                }}
            />
        );
    }
}