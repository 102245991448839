/* global EM */
import React, { Component } from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter, FormGroup, FormText, CustomInput, Label, Alert } from 'reactstrap';
import PanelModal from '../PanelModal';
import CsvFileViewer from '../CsvFileViewer';
import File from '../../util/File';
import { Validate } from '../../util/EntityValidators';
import _ from 'underscore';
import PipelineRunner from '../../entities/pipelines/PipelineRunner';
import Spinner from '../Spinner';

class EntityImporter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentDataSet: null,
            errors: [],
            canImport: false,
            importItems: null,
            updatedItemRows: null,
            parsingFile: false
        };

        this.onClose = this.onClose.bind(this);
        this.onImport = this.onImport.bind(this);
        this.parseFile = this.parseFile.bind(this);
        this.entityTitle = this.props.entity.t('title');
    }

    onClose() {
        this.props.onClose();
        this.clearState();
    }

    onImport() {
        let formattedItems = null;

        this.props.entity.import(formattedItems ? formattedItems : this.state.importItems);
        this.props.onClose();
        this.clearState();
    }

    clearState(withErrors) {
        this.setState({ currentDataSet: null, errors: withErrors || [], canImport: false, importItems: null, updatedItemRows: null, parsingFile: false  });
    }

    async parseFile(event) {
        var input = document.getElementById('importFile');
        let csvContents = null;
        try {
            csvContents = await File.readCSV(input);
        } catch (e) {
            this.clearState([['', e.message]]);
            return;
        }

        if (!csvContents){
            this.setState({ parsingFile: false });
            return;
        }        

        try{
            csvContents = await PipelineRunner.input(this.props.entity, csvContents);
        }catch(e){
            console.warn(e);
            this.clearState([[ '', 'Pipeline Error: ' + e.message ]]);
            return;
        }  
        
        let importLimitSetting = parseInt(EM.getTenantOption('importLimit'));
        let importLimit = isNaN(importLimitSetting) ? 10000 : importLimitSetting;
        if (csvContents.length > (importLimit + 1)){
            this.clearState([['', 'File exceeds import limit of ' + importLimit + ' rows.']]);
            return;
        }

        try {
            let results = await this.generateDataSetFromCsv(csvContents);
            this.setState({ currentDataSet: csvContents, errors: [], canImport: true, importItems: results.importItems, updatedItemRows: results.updatedItemRows, parsingFile: false  });
        } catch (e) {
            this.setState({ currentDataSet: csvContents, errors: e, canImport: false, importItems: null, updatedItemRows: null, parsingFile: false  });        
        }
    }

    generateDataSetFromCsv(csvContents) {
        let self = this;
        return new Promise(function (resolve, reject) {
            let keys = [];
            let validators = [];
            let columnDefs = [];
            let items = [];
            let updatedItemRows = {};
            let columnDefinitionsTextIndex = _.indexBy(self.props.columns, 'text');

            //Check that the CSV contains the correct columns
            let columnMessages = [];
            csvContents[0].forEach((displayName, colIndex) => {
                var col = columnDefinitionsTextIndex[displayName];
                if (!col) {
                    columnMessages.push(['0:' + colIndex, EM.t('error.csv-wrong-column', false, [displayName, self.entityTitle])]);
                } else {
                    columnDefs.push(col);
                    keys.push(col.dataField);
                    validators.push(col.validators || []);
                }
            });
            if (columnMessages.length > 0) {
                reject(columnMessages);
                return;
            }

            //Validate each row
            let validationMessages = [];
            csvContents.slice(1).forEach((row, rowIndex) => {
                //Perform validation on the row                 
                row.forEach((value, colIndex) => {
                    let validationResult = Validate(value, validators[colIndex], (columnDefs[colIndex].getValidationContext ? columnDefs[colIndex].getValidationContext() : null), 'import');
                    if (validationResult) {
                        let dn = csvContents[0][colIndex];
                        validationMessages.push([(rowIndex + 1) + ':' + colIndex, dn + ': ' + validationResult]);
                    }
                });
            });

            //Reverse lookup fields based on column config so that server is sent IDs, not text strings
            let newCsvContents = csvContents.slice(1).map((row, rowIndex) => {
                return row.map((valueIn, colIndex) => {
                    let value = (valueIn && valueIn.trim) ? valueIn.trim() : valueIn;
                    let columnDef = columnDefs[colIndex];
                    if (columnDef.fromEntity) {
                        let id = columnDef.fromEntity.lookupId(value);
                        if ((typeof id === 'undefined' || id === null) && !columnDef.allowNulls) {
                            validationMessages.push([(rowIndex + 1) + ':' + colIndex, columnDef.text + ': Not found']);
                            return null;
                        } else {
                            return id;
                        }
                    }else if (columnDef.fromList){
                        let item = columnDef.fromList.find(item => item.label === value);
                        if (item){
                            return item.value;
                        }else{
                            return null;
                        }
                    } else if (columnDef.asActive) {
                        return value === 'Yes' ? 'true' : 'false';
                    } else if (columnDef.asPercentage) {
                        return (value ? (value+"").replace('%','') : 0) / 100;
                    } else if (columnDef.asPermission) {
                        let permId = EM.permissionLookup.lookupId(value);
                        if (!permId || permId > 100){
                            validationMessages.push([(rowIndex + 1) + ':' + colIndex, columnDef.text + ': Not found']);
                            return null;
                        }
                        return permId;
                    } else if (columnDef.asUser || columnDef.asUserName) {
                        let userId = EM.users.lookupId(value);
                        if (!userId){
                            validationMessages.push([(rowIndex + 1) + ':' + colIndex, columnDef.text + ': Not found']);
                            return null;
                        }
                        return userId;
                    } else {
                        return value;
                    }
                });
            });

            if (validationMessages.length > 0) {
                reject(validationMessages);
                return;
            }

            newCsvContents.forEach((row, rowIndex) => {                
                const item = _.object(keys, row.map(value => {
                    if (typeof value !== "undefined" && value !== null){
                        if (value.trim){
                            return value.trim();
                        }else{
                            return value;
                        }
                    }else{
                        return value;
                    }
                }));
                
                const existingItem = self.props.entity.findItem(item);             
                if (existingItem) {
                    updatedItemRows[rowIndex + 1] = 'Update';
                    item[self.props.entity.idField] = existingItem[self.props.entity.idField];
                } else {
                    updatedItemRows[rowIndex + 1] = 'New';
                }

                items.push(item);
            });

            resolve({ importItems: items, updatedItemRows: updatedItemRows });
        });
    }

    render() {
        return (
            <PanelModal fade={false} isOpen={this.props.isOpen} toggle={this.onClose} className={'panel panel-full ' + this.props.className} key="import-modal">
                <ModalHeader toggle={this.onClose}>{EM.t('util.table.importTitle', false, [this.entityTitle])}</ModalHeader>
                <ModalBody>
                    <div>
                        <FormGroup>
                            <Label for="importFile">{EM.t('util.table.importLabel')}</Label>
                            <CustomInput type="file" name="file" id="importFile" accept=".csv" onClick={event => event.target.value = null} onChange={async (event) => {
                                let fileName = event.target.value.replace("C:\\fakepath\\", "");
                                document.getElementsByClassName('custom-file-label')[0].innerText = fileName;
                                this.setState({ parsingFile: true });
                                await this.parseFile();
                            }} label="Select a file" />
                            <FormText color="muted">
                                {EM.t('util.table.importInstructions')}
                            </FormText>
                        </FormGroup>
                        {this.state.parsingFile ? 
                            <Spinner text="Loading. This may take some time..." classes="small" />
                        : null }                        
                        {this.state.errors.length <= 0 && this.state.currentDataSet !== null ?
                            <Alert color="success">{EM.t('util.table.importAllowed')}</Alert>
                        : null}
                        {this.state.errors.length > 0 ?
                            <Alert color="danger">{EM.t('util.table.importDisallowed')}</Alert>
                        : null}
                        {this.state.errors.length > 0 ?
                            <ul className="validation-list">
                                {this.state.errors.slice(0, 20).map((error, index) => {
                                    let lr = error[0] ? error[0].split(':') : null;
                                    return <li className="text-danger" key={index}>
                                        {lr ?
                                            <span>Line {parseInt(lr[0]) + 1}, Column {parseInt(lr[1]) + 1}:&nbsp;</span>
                                            : null}
                                        {error[1]}
                                    </li>
                                })}
                                {this.state.errors && this.state.errors.length > 20 ? 
                                    <li className="text-danger">{EM.t('util.table.moreErrors', null , [this.state.errors.length - 20])}.</li>
                                : null}                                
                            </ul>
                        : null}
                        <CsvFileViewer data={this.state.currentDataSet} errors={this.state.errors} statuses={this.state.updatedItemRows} />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.onClose}>{EM.t('util.cancel')}</Button>
                    <Button color="primary" disabled={!this.state.canImport} onClick={this.onImport}>{EM.t('util.table.importButton')}</Button>
                </ModalFooter>
            </PanelModal>
        );
    }
}

export default EntityImporter;
