/* global EM */
export default class PreferenceManager{
    constructor(pageName){
        this.page = pageName;
    }

    setDomain(){
        if (!this.domain){
            let domainObject = EM.getActiveDomain() || { Name: 'global' };
            this.domain = domainObject.Name;
        }
    }

    getKey(group, name) {
        this.setDomain();
        return [this.domain, this.page, group, name].join('.');
    }

    get(group, name){
        let key = this.getKey(group, name);
        return JSON.parse(EM.storage.get(key));
    }

    set(group, name, obj){
        let key = this.getKey(group, name);
        EM.storage.set(key, obj);
    }

    remove(group, name){
        let key = this.getKey(group, name);
        EM.storage.remove(key);
    }    

    keys(){
        this.setDomain();
        let keyBase = [this.domain, this.page].join('.') + '.';
        return EM.storage.keys().filter(key => { 
            return key.indexOf(keyBase) === 0;
        }).map(key => {
            return key.replace(keyBase, '');
        });
    }

    asGroup(group){
        let self = this;     
        return (name, obj, remove) => {
            if (remove){
                self.remove(group, name);
            }else if (obj){
                self.set(group, name, obj);
            }else if (name){
                return self.get(group, name);
            }else{
                return this.keys().filter(key => {
                    return key.indexOf(group) === 0;
                }).map(key => {
                    return key.replace(group + '.', '');
                });
            }
        }
    }

    asFlag(group, name){
        let self = this;     
        return (obj) => {
            if (typeof obj !== 'undefined'){
                self.set(group, name, obj?true:false);
            }else{
                return self.get(group, name)?true:false;
            }
        }
    }
}