/* global EM */
import React, { Component } from 'react';
import Routes from '../app/Routes';
import Spinner from './Spinner';
import { NavLink } from "react-router-dom";

export default class ReportListingConfigurations extends Component {
    render() {
        if (!EM.configurations.isLoaded())return <Spinner text={EM.t('reports.loading')} classes="mb-4"/>;

        let domain = this.props.domain ? this.props.domain.Name : '';
        let allConfigs = EM.configurations.get();        
        let displayConfigs = allConfigs.filter(config => {
            return config.ConfigurationTypeLookupId === this.props.typeId && (config.IsPublic || config.CreatedBy === EM.me.User.UserId);
        });
        let reportUrl ='';
        switch(this.props.typeId){
            case 1:
                reportUrl = Routes.client.demandReport;
            break;
            case 2:
                reportUrl = Routes.client.staffingReport;
            break;
            default:
                break;
        }

        return (
            <div className="report-listing">
                <h5>{this.props.title}</h5>
                {displayConfigs.length > 0 ? 
                    <ul>
                        { displayConfigs.map((config) => {
                            let styling = JSON.parse(config.Styling);
                            let icon = styling.icon || 'far fa-file-alt';
                            let href = Routes.compose(reportUrl, { DomainName: domain }, { configuration: config.ConfigurationId });
                            return (
                                <li key={config.ConfigurationId} className={'custom ' + (config.IsPublic?'public':'private')}>                                
                                    <NavLink className="" to={href}>
                                        <i className={icon} />
                                        {config.Name}                                                                
                                        {config.Description ? 
                                            <span>{config.Description}</span>
                                        : null}
                                    </NavLink>                                
                                </li>
                            );
                        })}
                    </ul>
                : 
                    <div className="alert alert-secondary">{EM.t('reports.no-reports')}</div>
                }
            </div>
        )
    }
};