import React from 'react';
import { Label, FormGroup, Input, FormText } from 'reactstrap';

export default function ArrayField(props){        
    return (
        <FormGroup>      
            <Label title={props.description}>{props.display || props.name}:</Label>
            <Input 
                value={props.value} 
                onChange={event => {
                    let value = event.target.value;
                    let bits = value.split(',');
                    props.onChange(props.name, bits);
                }}
                onBlur={event => {
                    let value = event.target.value;
                    let bits = value.split(',').map(str => str.trim());
                    props.onChange(props.name, bits);
                }}
            />
            {props.description ? 
                <FormText>{props.description}</FormText>
            : null }             
        </FormGroup>
    );
}