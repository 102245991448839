/* global EM */
import React, { Component } from 'react';
import _ from 'underscore';
import Dates from '../util/Dates';
import DateInfo from 'luxon/src/info.js'

export default class MonthPicker extends Component {
    constructor(props) {
        super(props);

        this.now = Dates.now();

        this.state = {
            isOpen: false,
            isYearShowing: false
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.months = DateInfo.months('short');
        this.beginYear = this.now.year - 5;
        this.years = _.range(this.beginYear, this.beginYear + 15);

        this.currentYear = null;
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({ isOpen: false });
        }
    }

    setDate(month, year) {
        this.currentYear = year;
        let newState = {};
        if (year)newState.isYearShowing = false;
        if (month)newState.isOpen = false;
        this.setState(newState);
        if (month && this.props.onChange){
            let date = this.props.date || Dates.now();
            let newDate = Dates.fromObject({ year: year || date.year, month: month || date.month, day: 1 });
            this.currentYear = null;
            this.props.onChange(newDate);
        }
    }

    render() {
        let date = this.props.date || Dates.now();
        let displayedValue = this.props.date ? Dates.toMonthYearStrShort(date) : '';
        let selMonth = date.month;
        let selYear = date.year;
        return (
            <div className={"month-picker " + (this.state.isOpen ? 'active ' : '')} ref={this.setWrapperRef}>
                <input className="form-control" type="text" readOnly={!!this.props.readonly} placeholder={this.props.placeholder || 'Month Year'} value={displayedValue} onChange={() => { }} onFocus={() => {
                    if (!this.props.readonly)this.setState({ isOpen: true });
                }} />
                {this.state.isOpen ?
                    <div className={"month-picker-dropdown "  + (this.state.isYearShowing ? 'year-mode' : '')}>
                        <div className="header" onClick={() => {
                            this.setState({ isYearShowing: true })
                        }}>{this.state.isYearShowing ? <span>{EM.t('util.select')}</span> : <span className="selected">{this.currentYear || selYear}</span>}</div>
                        <div className="years">
                            {this.years.map((year, yearIndex) => {
                                return <div key={"y" + yearIndex} className={selYear === yearIndex + 1 ? 'selected' : ''} onClick={(event) => {
                                    this.setDate(null, year);
                                }}>{year}</div>
                            })}
                        </div>
                        <div className="months">
                            {this.months.map((month, monthIndex) => {
                                return <div key={"m" + monthIndex} className={selMonth === monthIndex + 1 ? 'selected' : ''} onClick={(event) => {
                                    this.setDate(monthIndex + 1, this.currentYear);
                                }}>{month}</div>
                            })}
                        </div>
                    </div>
                    : null}
            </div>
        )
    }
}