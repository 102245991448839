import ColorManager from '../../util/ColorManager';
export default {
    colors: ColorManager.COLORS,

    lang: {
        resetZoom: 'Zoom Out'
    },

    chart: {        
        selectionMarkerFill: 'rgba(0, 0, 0, .08)',
        resetZoomButton: {
            position: {
                x: 0,
                y: -15
            },
            theme: {
                fill: '#6c757d',
                stroke: '#6c757d',
                color: 'white',
                r: 0,
                style: {
                    color: 'white',
                    font: '12px Lato, sans-serif'
                },           
                states: {
                    hover: {
                        fill: '#5a6268',
                        style: {
                            color: 'white'
                        }
                    }
                }
            }
        }
    },    

    title: {
        style: {
            color: '#000',
            font: '12px Lato, sans-serif'
        }
    },

    subtitle: {
        style: {
            color: '#666666',
            font: '12px Lato, sans-serif'
        }
    },

    tooltip: {
        borderRadius: 0,
        backgroundColor: '#FFFFFF',
        borderWidth: 2        
    },

    legend: {
        itemStyle: {
            font: '12px Lato, sans-serif',
            color: 'black'
        },
        itemHoverStyle:{
            color: 'gray'
        }   
    },

    yAxis: {
        stackLabels: {
            rotation: -90,
            textAlign: 'left',
            verticalAlign: 'top',
            style: {
                font: '12px Lato, sans-serif',
                color: 'gray'
            },
        }
    },
};