
/* global EM */
import React, { Component } from 'react';
import { Nav, NavItem } from 'reactstrap';
import { NavLink } from "react-router-dom";
import Routes from "../app/Routes";

export default class SettingsTabs extends Component {
    render() {
        let domain = this.props.domain ? this.props.domain.Name : '';
        return (
            <Nav tabs>
                { EM.isDomainAdmin() ? 
                    <NavItem>
                        <NavLink className="nav-link" to={Routes.compose(Routes.client.generalSettings, { DomainName: domain })}>
                            {EM.t('settings.general')}
                        </NavLink>
                    </NavItem>
                : null }
                { EM.isDomainAdmin() ? 
                    <NavItem>
                        <NavLink className="nav-link" to={Routes.compose(Routes.client.security, { DomainName: domain })}>
                            {EM.t('settings.security')}
                        </NavLink>
                    </NavItem>
                : null }
                { EM.hasFeature('pipelines') ? 
                    <>       
                        { EM.isDomainAdmin() ? 
                            <NavItem>
                                <NavLink className="nav-link" to={Routes.compose(Routes.client.pipelines, { DomainName: domain })}>
                                    {EM.t('pipelines.tabs.pipelines')}
                                </NavLink>
                            </NavItem>          
                        : null }
                        { EM.isDomainEditor() ?              
                            <NavItem>
                                <NavLink className="nav-link" to={Routes.compose(Routes.client.referenceTables, { DomainName: domain })}>
                                    {EM.t('pipelines.tabs.referenceTables')}
                                </NavLink>
                            </NavItem>
                        : null }                        
                    </> 
                : null }       
                { EM.isDomainAdmin() ?                            
                    <NavItem>
                        <NavLink className="nav-link" to={Routes.compose(Routes.client.advancedSettings, { DomainName: domain })}>
                            {EM.t('settings.advanced')}
                        </NavLink>
                    </NavItem>   
                : null }                       
            </Nav>
        );
    }
}
