import React from 'react';

export default function EmployeeAvailabilityOption(props){
    const {
        className,
        cx,
        getStyles,
        isDisabled,
        isFocused,
        isSelected,
        innerRef,
        innerProps,
    } = props;

    let availability = props.data.availability;
    let plusClassName = availability ? availability.cls : '';
    let average = (availability ? availability.average : 0) * 100;

    return (
        <div
            css={getStyles('option', props)}
            className={cx(
                {
                    option: true,
                    'option--is-disabled': isDisabled,
                    'option--is-focused': isFocused,
                    'option--is-selected': isSelected,
                },
                className
            ) + ' with-availability ' + plusClassName}
            ref={innerRef}
            {...innerProps}
        >
            <label>{props.data.label} {availability ? '(' + average.toFixed(0) + ')%' : ''}</label>
            {availability ? 
                <span>
                    {availability.months.map((availabilityMonth, amI) => {
                        let value = ((availabilityMonth.value || 0)*100).toFixed(0);
                        return <span key={"amI" + amI} className={availabilityMonth.cls} title={availabilityMonth.dt + ': ' + value + '%'} />
                    })}
                </span>
            : null }
        </div>
    );
};