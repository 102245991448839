import React, { Component } from 'react';
import Dates from '../util/Dates';
import MonthPicker from './MonthPicker';

export default class MonthPickerControlled extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: Dates.now()
        }
    }

    getValue(){
        return this.state.date.toISO();
    }

    render() { 
        return (       
            <MonthPicker
                date={this.state.date || Dates.now()}
                innerRef={this.props.innerRef}
                onChange={date => {
                    this.setState({ date })
                }}
            />
        )
    }
}