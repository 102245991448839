/* global Msal */
/* eslint no-throw-literal: 0 */
import _ from 'underscore';
import Common from "../util/Common";

export default class Authenticator {
    static async load(){
        await Common.loadScript('https://insightscdn.azureedge.net/web-assets/msal.min.js');
    }

    constructor(){
        this.mode = 'Legacy';
        this.loginStyle = 'redirect';
        
        this.clientId = '9b61ec66-c773-4e3b-83da-ea327543cb59';
        this.config = {
            auth: {
                clientId: this.clientId ,
                authority: 'https://login.microsoftonline.com/intrinsicinsights.onmicrosoft.com',
                redirect_uri: window.location.origin,
                redirectUri: window.location.origin,
                postLogoutRedirectUri: 'http://intrinsiccs.com'
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true
            }
        };

        this.tokenRequest = {
            scopes: [this.clientId  + '/user_impersonation']
        };

        this.isCallback = false;

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);
    
        this.authContext = new Msal.UserAgentApplication(this.config);    
        this.authContext.handleRedirectCallback((error, response) => {
            this.isCallback = true;
            console.log('Login request callback handled.');
        });

        var self = this;
        this.isTestingPop = false;
        this.eCall = _.once(() => {
            if (self.isTestingPop){
                throw new Error('test');
            }
        });
    }

    async login() {  
        this.authContext.loginRedirect({
            scopes: this.tokenRequest.scopes
        });
    }

    logout () {
        window.sessionStorage.removeItem('rm.login_mode');
        this.authContext.logout();
    }

    switchAccount(){
        window.sessionStorage.removeItem('rm.login_mode');
        window.location = window.location.origin;
    }    

    async isAuthenticated(){
        return this.authContext.getAccount() ? true : false;
    }

    async acquireToken() {
        let self = this;
        let account = self.authContext.getAccount();
        let req = {
            scopes: this.tokenRequest.scopes     
        };
        if (account){
            req.loginHint = account.idTokenClaims ? account.idTokenClaims.preferred_username : null;
        }

        return new Promise((resolve, reject) => {
            self.authContext.acquireTokenSilent(req).then(tokenResponse => {
                self.eCall();
                resolve('Bearer ' + tokenResponse.accessToken);                
            }).catch((error) => {
                console.log(error);
                if (self.requiresInteraction(error.errorCode)) {
                    req.prompt = 'select_account';
                    self.authContext.acquireTokenPopup(req).then(tokenResponse2 => {
                        resolve('Bearer ' + tokenResponse2.accessToken);
                    }).catch((error2) => {
                        console.log(error2);  
                        reject(self.tokenFailure(error2));
                    });
                }else{
                    reject(self.tokenFailure(error));
                }
            });        
        });
    }

    tokenFailure(error){    
        return { message: 'Token acquisition failed. ' + error.toString(), name: 'acquisitionFailure'};
    }

    requiresInteraction(errorCode) {
        if (this.isTestingPop)return true;
        if (!errorCode || !errorCode.length)return false;
        return errorCode === "consent_required" || errorCode === "interaction_required" || errorCode === "login_required";
    }

    getUsername(){
        let account = this.authContext.getAccount();
        return account.userName;
    }
};
