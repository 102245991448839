import { UserManager } from 'oidc-client';
//import { Log } from 'oidc-client';
import Location from '../util/Location';

export default class Authenticator {
    static async load(){
        return;
    }

    constructor(instance) {
        this.mode = 'OIDC';

        if (!instance || !instance.customLoginProps || !instance.customLoginProps.OIDC){
            throw new Error('OIDC configuration not found.');
        }

        let env = process.env.REACT_APP_ENV;
        let oidcProps = instance.customLoginProps.OIDC;    
        if (env !== 'prod'){
            if (oidcProps[env])oidcProps = oidcProps[env];
        }
        console.log('OIDC Config:', oidcProps);
        this.loginStyle = 'popup'; //oidcProps.loginStyle || 'popup';

        let settings = {
            redirect_uri: window.location.origin,
            silent_redirect_uri: window.location.origin,
            post_logout_redirect_uri: window.location.origin,
            response_type: 'code',
            scope: 'openid profile email'
        };
        settings = Object.assign(settings, oidcProps);

        this.userManager = new UserManager(settings);

        //Log.logger = console;
        //Log.level = Log.INFO;

        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.isAuthenticated = this.isAuthenticated.bind(this);

        this.isCallback = false;
    }

    async initialize(){
        return new Promise((resolve, reject) => {
            try{
                let handler = null;
                if (this.loginStyle === 'popup'){
                    handler = this.userManager.signinPopupCallback(); 
                }else{
                    handler = this.userManager.signinRedirectCallback(); 
                }

                handler.then((user) => {
                    console.log('Login redirect callback handled.');
                    resolve(null);
                });
                handler.catch((e) => {
                    resolve(null);
                });
            }catch(e){
                resolve(null);
            }
        });
    }

    async login() {  
        if (this.loginStyle === 'popup'){
            return await this.userManager.signinPopup();
        }else{
            this.userManager.signinRedirect();    
        }
    }

    logout() {
        window.sessionStorage.removeItem('rm.login_mode');
        //This may not work for other OIDC implementations, since we're not explicitly setting "post_logout_redirect_uri"
        this.userManager.signoutRedirect().then(() => {
            window.location = window.location.origin;
        });    
    }

    switchAccount(){
        return null;
    } 

    async isAuthenticated(){
        let qs = Location.qsToObj();
        let user = await this.userManager.getUser();

        if (!user && qs.code){
            await new Promise((resolve, reject) => {
                console.log('waiting');
                window.setTimeout(resolve, 3000);
            });
            user = await this.userManager.getUser();
        }  

        return !!user;
    }

    async acquireToken() {
        let user = await this.userManager.getUser();
        return 'Bearer ' + user.access_token;
    }

    getUsername(){
        let account = this.user;        
        return account.profile.email;
    }
}
