/* global EM */
import DemandReportTable from './DemandReportTable';
import Dates from '../../util/Dates';
import ColumnMapper from '../ColumnMapper';
import DateGroupValueSet from '../../entities/files/DateGroupValueSet';

export default class DemandListingTable extends DemandReportTable {
    constructor(props){
        super(props);

        this.state = {
            seriesName: null,
            tableData: null
        }       
    }

    createColumns(props){
        let pName = props.primary ? props.primary.getFullName(props.preferences.primary) : null;
        let columns = [{
            dataField: 'date',
            text: EM.t('reports.differenceTable.date'),   
            width: 125,     
            formatter: (cell) => {
                return Dates.toGroupedStr(cell, props.preferences.dateGrouping)
            },     
            csvFormatter: (cell) => {
                return Dates.toGroupedStr(cell, props.preferences.dateGrouping)
            }
        },{
            dataField: 'primary',
            text: pName,       
            width: 100,     
            formatter: (cell) => {
                return cell ? cell.toFixed(2) : '';
            }
        }];

        if (props.preferences.secondary.dataset && props.preferences.secondary.dataset.value !== null){
            let sName = props.secondary ? props.secondary.getFullName(props.preferences.secondary) : null;
            columns.push({
                dataField: 'secondary',
                text: sName,
                width: 100,         
                formatter: (cell) => {
                    return cell ? cell.toFixed(2) : '';
                }
            });          
        }

        if (props.preferences.tertiary.dataset && props.preferences.tertiary.dataset.value !== null){
            let tName = props.tertiary ? props.tertiary.getFullName(props.preferences.tertiary) : null;
            columns.push({
                dataField: 'tertiary',
                text: tName,
                width: 100,         
                formatter: (cell) => {
                    return cell ? cell.toFixed(2) : '';
                }
            });
        }        

        this.columns = ColumnMapper(columns);
    }

    async componentDidUpdate(prevProps){
        if (!this.props.selectedSeries)return;

        let seriesName = this.props.selectedSeries.name;

        if (this.state.seriesName === seriesName)return;

        let preferences = this.props.preferences;

        if (!this.props.primary)return;
        let primaryData = await this.props.primary.getSummary(preferences, 'primary');  
        let secondaryData = null;  
        let tertiaryData = null;            

        if (this.props.secondary){
            secondaryData = await this.props.secondary.getSummary(preferences, 'secondary');        
        }         
        if (this.props.tertiary){
            tertiaryData = await this.props.tertiary.getSummary(preferences, 'tertiary');        
        }                

        let tableData = this.transformDatasetsForTable(seriesName, primaryData, secondaryData, tertiaryData, preferences);
        if (!this.columns)this.createColumns(this.props);
        this.setState({ seriesName: seriesName, tableData: tableData });
    }

    transformDatasetsForTable(seriesName, primaryData, secondaryData, tertiaryData, preferences){
        let output = [];    

        let seriesOfInterest = preferences.grouping + ':' + seriesName;
        if (seriesName.toLowerCase() === 'total'){
            seriesOfInterest = 'total';
        }

        Object.keys(primaryData).sort((a,b) => a - b).forEach((monthKey, mkIndex) => {
            let monthGroup = primaryData[monthKey];

            let rowObj = {
                id: mkIndex,
                date: monthGroup.date.toMillis ? monthGroup.date : Dates.fromMs(monthGroup.date.ts),
                primary: 0,
                secondary: 0,
                tertiary: 0
            }

            let pData = monthGroup[seriesOfInterest];
            if (pData){
                rowObj.primary = DateGroupValueSet.from(pData).get();
            }

            if (secondaryData){
                let secMonthGroup = secondaryData[monthKey]; 
                if (secMonthGroup){  
                    let sData = secMonthGroup[seriesOfInterest];    
                    if (sData){
                        rowObj.secondary = DateGroupValueSet.from(sData).get();
                    }
                }        
            }  
            
            if (tertiaryData){
                let terMonthGroup = tertiaryData[monthKey]; 
                if (terMonthGroup){  
                    let tData = terMonthGroup[seriesOfInterest];    
                    if (tData){
                        rowObj.tertiary = DateGroupValueSet.from(tData).get();
                    }
                }        
            }              
            
            output.push(rowObj);
        });

        return output;
    }     
};