/* global EM */
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import HelpIndex from '../../entities/help/HelpIndex';
import Routes from '../../app/Routes';
import ContactSupportButton from '../ContactSupportButton';

export default class HelpListing extends Component {
    constructor(props){
        super(props);

        this.state = {
            content: null,
            pathname: null            
        }
    }

    render() {      
        let listing = HelpIndex.getListing();

        let path = Routes.parseQuery().path.slice(1);
        let curPath = HelpIndex.parsePath(path);

        return (
            <div className="help-listing">
                <h3>{EM.t('help.listing')}</h3>
                <ol>
                    {listing.map((helpItem, index) => {
                        let tmpPath = '/x/' + helpItem.path;  
                        if (helpItem.isBase)return null;          
                        if (helpItem.excludeFromListing)return null;          
                        return (
                            <li className={curPath === helpItem.path ? 'active clearfix' : 'clearfix'} key={helpItem.path}>
                                <NavLink exact={true} to={Routes.compose(Routes.client.help, {}, {path: tmpPath})}>
                                    {helpItem.title}
                                </NavLink>
                            </li>
                        );
                    })} 
                </ol>
                <ContactSupportButton />  
            </div>
        );
    }
};