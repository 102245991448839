/* global EM */
import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Spinner from './Spinner';

export default class ProjectionOverlay extends Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.onClose = this.onClose.bind(this);
        this.onGenerate = this.onGenerate.bind(this);
    }

    onClose(){
        this.props.onClose();
    }     
    
    onGenerate(){
        this.props.onGenerate();
    }

    render() {
        let data = this.props.schedule;
        if (!data)return null;
        return (
            <Modal fade={false} isOpen={data?true:false} toggle={this.onClose} className={'projections-overlay ' + (EM.currentTheme === 'Dark'?'dark-mode ': ' ') + this.props.className} backdrop="static">
                <ModalHeader>
                    {data.Name}
                </ModalHeader>
                <ModalBody>
                    {this.props.isGenerating ?
                        <div className="text-center p-4 mb-3 bg-light">
                            <Spinner text={EM.t('schedules.overlay.loading')} classes="small"/>
                        </div>
                    :
                        <div>
                            <p>
                                {EM.t('schedules.overlay.prompt')}
                            </p>
                            <p className="text-center">
                                {(data.Projections && data.Projections.length > 0) ?
                                    <Button color="secondary" onClick={this.onGenerate}>{EM.t('schedules.overlay.regenerate')}</Button>
                                : 
                                    <Button color="success" onClick={this.onGenerate}>{EM.t('schedules.overlay.generate')}</Button>
                                }
                            </p>
                        </div>
                    }
                    <div className="alert alert-warning">
                        {EM.t('schedules.overlay.warning')}
                    </div>
                    
                </ModalBody>
                <ModalFooter>               
                    <Button color="secondary" onClick={this.onClose}>{EM.t('util.closeButton')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}