/* global EM */
import React, { Component } from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'reactstrap';
import PanelModal from './PanelModal';
import CsvFileViewer from './CsvFileViewer';
import Csv from '../util/CSV';
import Spinner from './Spinner';

class FileViewerDialog extends Component {
    constructor(props) {
        super(props);

        this.onClose = this.onClose.bind(this);
        this.downloadCsv = this.downloadCsv.bind(this);
    }

    onClose(){
        this.props.onClose();
    }

    downloadCsv(){
        let data = this.props.files[this.props.id].asArray();
        Csv.save(this.props.title||'file', this.props.entity.fileType.preExportTransform(data));
    }

    render() {
        let id = this.props.id;
        if (!id)return null;

        let file = null;
        let fileData = null;
        let isLoading = true;
        let hasData = false;
        let cellFormatter = null;
        if (this.props.files.hasOwnProperty(id)){
            isLoading = false;
            file = this.props.files[id];            
            if (file){
                hasData = true;
                cellFormatter = file.cellFormatter;
                fileData = this.props.files[id].asArray();
            }else{
                fileData = [['File not found.']];
            }
        }      
        
        return (
            <PanelModal fade={false} isOpen={id?true:false} toggle={this.onClose} className={'panel panel-full ' + this.props.className} key="file-dialog-modal">
                <ModalHeader toggle={this.onClose}>
                    {this.props.title||'File Contents'}
                    {this.props.subtitle ? <small>{this.props.subtitle}</small> : null }
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        {isLoading ?                            
                            <Spinner text={EM.t('util.loading')} classes="small"/>
                        : 
                            <CsvFileViewer data={fileData} cellFormatter={cellFormatter} />
                        }
                    </FormGroup>                    
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.onClose}>{EM.t('util.closeButton')}</Button>
                    {hasData ? <Button color="success" onClick={this.downloadCsv}>{EM.t('util.downloadCsv')}</Button> : null }
                </ModalFooter>
            </PanelModal>
        );
    }
}

export default FileViewerDialog;
