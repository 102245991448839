/* eslint eqeqeq: "off" */
/* global EM */
import React, { Component } from 'react';
import ResizableTimelineRow from './ResizableTimelineRow';
import Dates from '../../util/Dates';
import _ from 'underscore';

export default class StaffingEmployeeDetail extends Component{
    constructor(props){
        super(props);
        this.state = {
            selectedAssignment: null,
            tasks:[]
        };

        this.monitorKeyPress = this.monitorKeyPress.bind(this);

        let textEndSetting = EM.getSetting('FavorTextEndInLabels') || 'false';
        this.showRtlLabels = (textEndSetting === 'true' ? true : false);
    }

    componentDidMount(){
        window.addEventListener("keydown", this.monitorKeyPress);
        EM.schedules.loadDefaultItem().then((defaultSchedule) => {
            if (!defaultSchedule)return;
            EM.schedules.loadFile(defaultSchedule.ScheduleId).then(() => {
                let fileContents = EM.schedules.getFile(defaultSchedule.ScheduleId);
                if (fileContents){  
                    let fileContentsObjects = fileContents.toObjectArray();
                    let wiGroups = fileContentsObjects.groupBy('Name');
                    let wiKeys = Object.keys(wiGroups).sort();
                    let addMeta = EM.getSetting('AdditionalMetadataFields');
                    if (addMeta){
                        addMeta = addMeta.split(',');
                    }
                    let tasks = wiKeys.map(key => {                        
                        let group = wiGroups[key];
                        let lbl = key;
                        
                        if (addMeta && Array.isArray(addMeta)){
                            let meta = {};
                            group.forEach(item => {
                                addMeta.forEach(attr => {
                                    let value = item['_' + attr];
                                    if (value){
                                        if (!meta[attr])meta[attr] = {};
                                        meta[attr][value] = true;
                                    }
                                });
                            });
                            let metaStr = [];
                            Object.keys(meta).forEach(key => {
                                metaStr.push(key + ': ' + Object.keys(meta[key]).join(', '));
                            });
                            if (metaStr.length > 0){
                                lbl = lbl + " " + metaStr.join(' | ') ;
                            }
                        }
                        return { label: lbl, value: key };
                    });

                    let addTaskList = EM.getSetting('AdditionalTasks');  
                    if (addTaskList){
                        addTaskList.split(',').map((task) => {
                            return tasks.push({ value: task.trim(), label: task.trim() });
                        });
                    }

                    this.setState({ tasks });
                }
            });
        });
    }

    componentWillUnmount(){
        window.removeEventListener("keydown", this.monitorKeyPress)
    }

    monitorKeyPress(event){
        let self = this;
        if (event.isComposing || event.keyCode === 229) {
            return;
        }
        if (event.keyCode === 27){
            self.setState({ selectedAssignment: null });
        }
    }

    render(){
        let { employee, range, assignments, maxWidth, onUpdate, onAdd, onDelete, onToggleLock, onDuplicate, onEdit } = this.props;        
        let filteredAssignments = assignments ? assignments.filter(assignment => {
            return Dates.doMonthRangesOverlap(assignment.range, range);
        }) : [];

        let assignmentGroups = _.groupBy(filteredAssignments, (assignment) => {           
            let activity = EM.activities.findByKey(assignment.ActivityName);             
            return assignment.WorkItemName + '!^$&' + (activity ? (activity.Order ? parseInt(activity.Order) : 1000) : 1000) + '!^$&'; // + assignment.ActivityName;
        });
    
        let assignmentGroupKeys = Object.keys(assignmentGroups).sort((a, b) => {
            var aBits = a.split('!^$&');
            var bBits = b.split('!^$&');          

            var o1 = aBits[0].toLowerCase();
            var o2 = bBits[0].toLowerCase();

            var p1 = parseInt(aBits[1]);
            var p2 = parseInt(bBits[1]);

            if (o1 < o2) return -1;
            if (o1 > o2) return 1;
            if (p1 < p2) return -1;
            if (p1 > p2) return 1;
            return 0;
        });

        let pHeight = this.props.rowHeight;
        let detailHeight = (filteredAssignments ? filteredAssignments.length * pHeight : -3) + (pHeight + 2);

        return (
            <div className="item-detail" style={{ height: this.props.isActive ? detailHeight : 0 }} onClick={(event) => {
                event.stopPropagation();
                this.setState({ selectedAssignment: null })
            }}>   
                {this.props.isActive ? assignmentGroupKeys.map((assignmentGroupKey) => {
                    let assignmentsInGroup = _.sortBy(assignmentGroups[assignmentGroupKey], (item) => {
                        return item.range.beginIndex;
                    });  
                    const task = this.state.tasks.find(task => task.value === assignmentsInGroup[0].WorkItemName);                  
                    return (
                        <div key={assignmentGroupKey} className={"detail-row"} style={{ height: assignmentsInGroup.length * pHeight }}>
                            <div className={"detail-header"} style={{ height: (assignmentsInGroup.length * pHeight) - 1 }}>
                                <b title={task ? task.label : assignmentsInGroup[0].WorkItemName} className={this.showRtlLabels?'rtl':''}>{assignmentsInGroup[0].WorkItemName}</b><br />
                                <span title={assignmentsInGroup[0].ActivityName} className={this.showRtlLabels?'rtl':''}>{assignmentsInGroup[0].ActivityName || '<blank>'}</span>
                            </div>                            
                            {assignmentsInGroup.map((assignment, assignmentIndex) => {
                                let pw = this.props.cellWidth;
                                let l = (assignment.range.beginIndex - range.beginIndex);
                                let w = assignment.range.dates.length;
                                let lo = l < 0 ? Math.abs(l) : 0;
                                let wlo = w - lo;
                                let il = (lo * pw) + (wlo * pw > maxWidth ? maxWidth / 2 : (wlo / 2) * pw);
                                let cls = [ 
                                    (this.state.selectedAssignment && this.state.selectedAssignment.AssignmentId === assignment.AssignmentId ? 'active' : null ), 
                                    (assignment.Status === 'Locked'?'locked': ''),
                                    (assignment.Label ? 'labeled': '')
                                ].join(' ');
                                return (
                                    <div className="detail-grid" style={{ top: assignmentIndex * pHeight }} key={assignmentIndex}>
                                        <ResizableTimelineRow 
                                            key={assignment.AssignmentId}
                                            className={"detail-bar " + cls}
                                            pixelWidth={this.props.cellWidth}
                                            maxWidth={maxWidth}
                                            width={w}
                                            left={l}
                                            readOnly={assignment.Status === 'Locked' || !EM.isDomainEditor()}
                                            color={EM.activities.getColor(assignment.ActivityName)}
                                            tooltip={((assignment.Value * 100).toFixed(0)) + "%: " + assignment.BeginDateStr + ' - ' + assignment.EndDateStr + (assignment.Label ? ': ' + assignment.Label : '')}  
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                this.setState({ selectedAssignment: assignment })
                                            }}
                                            onMoved={(event, size) => {
                                                let newBeginDate = range.dates[0].plus({ months: size.left });
                                                if (+newBeginDate === +assignment.BeginDateObj)return;                                            
                                                assignment.BeginDate = newBeginDate.toISO();

                                                let months = Math.floor(size.width - 1);
                                                let newEndDate = newBeginDate.plus({ months: months });
                                                assignment.EndDate = newEndDate.toISO();

                                                window.setTimeout(() => {
                                                    onUpdate(assignment);
                                                }, 0) 
                                            }}
                                            onResized={(event, size) => {
                                                let months = Math.floor(size.width - 1);
                                                let newEndDate = Dates.fromISO(assignment.BeginDate).plus({ months: months });
                                                if (+newEndDate === +assignment.EndDateObj)return;

                                                assignment.EndDate = newEndDate.toISO();
                                                window.setTimeout(() => {
                                                    onUpdate(assignment);
                                                }, 0)                                                
                                            }}                                                                                      
                                        >
                                            {this.state.selectedAssignment && this.state.selectedAssignment.AssignmentId === assignment.AssignmentId && assignment.Status !== 'Locked' &&  EM.isDomainEditor() ? 
                                                <input defaultValue={(assignment.Value * 100).toFixed(0)}
                                                    style={{ left: il }}
                                                    onFocus={(event) => event.target.select()}
                                                    onBlur={(event) => {
                                                        let newValue = parseFloat(event.target.value);                                                        
                                                        if (isNaN(newValue))return;
                                                        newValue = newValue/100;
                                                        if (assignment.Value == newValue)return;
                                                        assignment.Value = newValue;
                                                        window.setTimeout(() => {
                                                            onUpdate(assignment);
                                                        }, 0)  
                                                    }}
                                                    onKeyDown={(event) => {
                                                        if (event.keyCode === 13){
                                                            event.stopPropagation();
                                                            event.target.blur();
                                                            this.setState({ selectedAssignment: null });
                                                        }
                                                        if (event.keyCode === 27){
                                                            this.setState({ selectedAssignment: null });
                                                        }
                                                    }}
                                                />
                                            : 
                                                <label style={{ left: il }} className={assignment.Status !== 'Locked' ? 'locked' : ''}>
                                                    {(assignment.Value * 100).toFixed(0)}%
                                                </label> 
                                            }
                                        </ResizableTimelineRow>   
                                    </div>
                                )
                            })}                   
                        </div>
                    )
                }) : null }
                { this.props.isActive ?
                    <div className={"detail-row detail-util"}>
                        <div className={"detail-header"} title={employee.FirstName + ' ' + employee.LastName}>
                            {employee.Contractor ? '* ' : ''}{employee.FirstName} {employee.LastName} <br/>
                            <span>{EM.t('staffing.workTimePercentage')}: {((employee.TimeAllocation || 1) * 100).toFixed(0)}%</span>
                        </div>                        
                        <div className={"detail-controls"}>                            
                            {EM.isDomainEditor() ?
                                <button className="btn btn-success btn-sm" onClick={() => {
                                    onAdd(employee);
                                }}>{EM.t('staffing.addAssignment')}</button>
                            : null }
                            {EM.isDomainEditor() ?
                                <button className="btn btn-primary btn-sm" disabled={(!this.state.selectedAssignment)} onClick={() => {
                                    onDuplicate(this.state.selectedAssignment.AssignmentId);
                                }}>{EM.t('staffing.duplicateAssignment')}</button>
                            : null }                            
                            {EM.isDomainEditor() ?
                                <button className="btn btn-danger btn-sm" disabled={(!this.state.selectedAssignment || this.state.selectedAssignment.Status === 'Locked')} onClick={() => {
                                    onDelete(this.state.selectedAssignment.AssignmentId);
                                }}>{EM.t('staffing.deleteAssignment')}</button>
                            : null }                            
                            {EM.isDomainAdmin() ?
                                <span className="divider"></span>
                            : null }
                            {EM.isDomainAdmin() ?
                                <button className="btn btn-warning btn-sm" disabled={(!this.state.selectedAssignment)} onClick={() => {
                                    onToggleLock(this.state.selectedAssignment.AssignmentId);
                                }}>{EM.t('staffing.toggleLocked')}</button>                      
                            : null }
                            {EM.isDomainEditor() ?
                                <button className="btn btn-info btn-sm" disabled={(!this.state.selectedAssignment || this.state.selectedAssignment.Status === 'Locked')} onClick={() => {
                                    onEdit(this.state.selectedAssignment.AssignmentId);
                                }}>{EM.t('staffing.edit')}</button>                      
                            : null }                            
                        </div>
                    </div>   
                : null }                 
            </div>    
        );
    }
}