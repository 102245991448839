import PreferenceManager from './PreferenceManager';

export default class EmployeesTablePreferences{
    constructor(name, inst){
        this.name = name;
        this.preferences = new PreferenceManager('employees');
        this.group = this.preferences.asGroup('preferences'); 
        this.inst = inst ? inst : (this.group(name) || {});             
    }

    set(name, value){
        let newOptions = Object.assign({}, this.inst, { [name]: value });
        this.inst = newOptions;
        this.group(this.name, newOptions);
    }

    get(name, defaultValue){
        let val = this.inst[name] || null;
        return val || defaultValue;
    }

    clone(){
        return new EmployeesTablePreferences(this.name, this.inst);
    }

    //////////Helpers for specific fields
    get hiddenColumns() {
        return this.get('hiddenColumns', ['Department', 'Organization']);
    }
    set hiddenColumns(value) {
        this.set('hiddenColumns', value);
    }     
}