export default class DateGroupValueSet{
    constructor(dateGrouping) {
        this.dateGrouping = dateGrouping;
        this.set = {};
    }

    push(month, value){
        let mkey = month.toMillis();
        if (!this.set[mkey]){
            this.set[mkey] = [];
        }
        this.set[mkey].push(value);
    }

    get(){
        let keys = Object.keys(this.set);
        let sum = keys.map((key) => {
            return this.set[key].reduce((accumulator, currentValue) => accumulator + currentValue);
        }).reduce((accumulator, currentValue) => accumulator + currentValue);
        if (this.dateGrouping.toLowerCase() === 'month'){
            return sum;
        }else{
            return sum / keys.length;
        }
    } 
    
    static from(obj){
        if (obj.get){
            return obj;
        }else{
            let dgvs = new DateGroupValueSet(obj.dateGrouping);
            dgvs.set = obj.set;
            return dgvs;
        }
    }
}