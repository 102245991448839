/* global EM */
import React, { useMemo } from 'react';
import { NavItem, NavLink } from 'reactstrap';

const TrainingHeaderAdditions = ({ settings }) => {
    const trainingSettings = useMemo(() => {       
        let output = {};
        if (!settings)return output;
        settings.forEach(setting => {
            if (setting.Key.indexOf('Training:') === 0){
                output[setting.Key] = setting.Value;
            }
        })
        return output;
    }, [settings]);

    return (
        <>
            {trainingSettings['Training:ExerciseArticleLink'] ?
                <NavItem className="training-addition" title={EM.t('header.help')}>
                    <NavLink href={trainingSettings['Training:ExerciseArticleLink']} target="exercise">
                        <i className="fa-light fa-book-sparkles"></i>
                        <span>{trainingSettings['Training:ExerciseArticleText'] || 'Exercise Article'}</span>
                    </NavLink>
                </NavItem>
            : null }
        </>
    );
};

export default TrainingHeaderAdditions;