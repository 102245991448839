/* eslint eqeqeq: "off" */
/* global EM */
import React, { Component } from 'react';
import _ from 'underscore';
import Select from 'react-select';
import { FormGroup } from 'reactstrap';
import EntityTable from '../EntityTables/EntityTable';
import { Validators } from '../../util/EntityValidators';
import EmployeeAvailabilityOption from './EmployeeAvailabilityOption';
import StaffingUtil from '../../util/StaffingUtil';

export default class StaffingWorkItemEmployeeListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            projection: this.props.projection,
            assignment: {},
            selectedEmployee: null,
            availability: null
        };

        this.tableRef = React.createRef();

        this.addAssignment = this.addAssignment.bind(this);
    }

    addAssignment(currentTotal) {
        let { projection, filterIndexes, employees, onAddAssignment } = this.props;
        if (!projection)return;

        let activities = EM.activities.get();
        let activity = _.findWhere(activities, { ActivityId: projection[filterIndexes.activity] + "" });
        let value = projection[filterIndexes.value];
        if (value > 1) {
            let remainder = value - currentTotal;
            if (remainder > 1 || remainder <= 0) {
                value = 1;
            } else {
                value = remainder;
            }
        }
        let assignment = Object.assign({
            WorkItemName: projection[filterIndexes.workitem].trim(),
            ActivityName: activity.Name.trim(),
            BeginDate: projection[2],
            EndDate: projection[3].startOf('month'),
            Value: value,
            ProjectedValue: projection[filterIndexes.value],       
        }, this.state.assignment);
        let employee = _.findWhere(employees, { EmployeeId: assignment.EmployeeId });

        assignment.DomainId = employee.DomainId;
        onAddAssignment(assignment);
        this.setState({ assignment: {}, selectedEmployee: null });
    }

    getDomainEmployees(employees) {
        let availability = this.state.availability || {};
        return _.sortBy(_.map(employees, function (employee) {
            let availObj = availability[employee.EmployeeId];
            return {
                label: (employee.FirstName + ' ' + employee.LastName),
                lastName:employee.LastName,
                value: employee.EmployeeId,
                availability: availObj                
            };
        }), "lastName");
    }

    getAssignments(employeeIds) {
        let { assignments, projection, filterIndexes } = this.props;
        if (!projection)return [];
        let activities = EM.activities.get();
        return _.filter(assignments ? [...assignments] : [], function (assignment) {
            let activity = _.findWhere(activities, { ActivityId: projection[filterIndexes.activity] + "" });
            return _.contains(employeeIds, assignment.EmployeeId) && (assignment.ActivityName || '').trim() === activity.Name.trim() && (assignment.WorkItemName || '').trim() === projection[filterIndexes.workitem].trim();
        });
    }

    onBeforeDelete(ids) {
        let lockedItemFound = ids.find((id) => {
            let assignment = EM.assignments.byId(id);
            return assignment.Status === 'Locked';
        });
        if (lockedItemFound) {
            window.alert(EM.t('staffing.modifyPrevented'));
            return { cancel: true };
        }
        return null;
    }

    getAssignmentColumns() {
        const entity = EM.assignments;
        return [
            {
                dataField: 'EmployeeId',
                text: entity.columns('employee'),
                fromEntity: EM.employees,
                validators: [Validators.required],
                editable: false
            },
            {
                dataField: 'BeginDate',
                text: entity.columns('begin'),
                asDate: true,
                width: 100,
                validators: [Validators.required],
                editable: (cell, row) => {
                    return EM.isDomainEditor() && row.Status !== 'Locked';
                }
            },
            {
                dataField: 'EndDate',
                text: entity.columns('end'),
                asDate: true,
                width: 100,
                validators: [Validators.required],
                editable: (cell, row) => {
                    return EM.isDomainEditor() && row.Status !== 'Locked';
                }
            },
            {
                dataField: 'Value',
                text: entity.columns('value'),
                asPercentage: true,
                csvType: Number,
                width: 60,
                validators: [Validators.positiveIntegerAsPercentage],
                editable: (cell, row) => {
                    return EM.isDomainEditor() && row.Status !== 'Locked';
                }
            },
            /*{
                dataField: 'ProjectedValue',
                text: entity.columns('originalValue'),
                asPercentage: true,
                csvType: Number,
                width: 60,
                editable: false,
                headerClasses: 'column-reference',
                classes: 'column-reference'
            },*/           
            {
                dataField: 'Label',
                width: 300,
                text: entity.columns('label'),
                asAssignmentLabel: true,
                editable: (cell, row) => {
                    return EM.isDomainEditor() && row.Status !== 'Locked';
                }
            },
            {
                dataField: 'Status',
                width: 100,
                text: entity.columns('status'),
                asAssignmentStatus: true,
                editable: EM.isDomainAdmin(),
            }
        ];
    }

    componentDidMount(){
        let disableAvailability = EM.getSetting('DisableEmployeeAvailability');
        if (disableAvailability)return null;

        let range = this.props.projection[this.props.filterIndexes.range];
        let rangeCacheKey = 'SWIDialog-' + range.beginIndex + '-' + range.endIndex + '-' + this.props.role.roleId;

        if (EM.dpCache[rangeCacheKey]){
            this.setState({ availability: EM.dpCache[rangeCacheKey] });
        }else{      
            window.setImmediate(() => {
                StaffingUtil.getEmployeeAvailability(range, this.props.role.roleId).then(availability => {
                    EM.dpCache[rangeCacheKey] = availability;
                    this.setState({ availability });
                });
            });
        }
    }

    render() {
        let { employees, show, projection } = this.props;
        if (!projection)return <div className="assignment-listing">Projection not found.</div>;

        let employeeSelectOptions = this.getDomainEmployees(employees);
        const entity = EM.assignments;
        if (show) {
            let columns = this.getAssignmentColumns();
            let roleAssignments = this.getAssignments(_.pluck(employeeSelectOptions, "value"));
            let relevantAssignments = _.indexBy(this.props.relevantAssignments, 'AssignmentId');
            return (
                <div className="assignment-listing">
                    {!this.props.allMatchingDateRange ?
                        <div className="alert alert-secondary font-sm">
                            <i className="fas fa-exclamation-circle">&nbsp;</i>{EM.t('staffing.notAllDatesMatch')}
                        </div>
                        : null}
                    <EntityTable
                        beforeUpdate={(oldItem, newItem) => this.props.onAfterUpdateAssignment(newItem)}
                        //onUpdate={this.props.onAfterUpdateAssignment}
                        onAfterDelete={this.props.onAfterDeleteAssignment}
                        search={false}
                        hideSearch={true}
                        hideClearAll={true}
                        hideImportExport={true}
                        hideAddRecord={true}
                        hideTotals={true}
                        entity={entity}
                        data={roleAssignments}
                        columns={columns}
                        hideToolbar={true}
                        ref={this.tableRef}
                        onBeforeDelete={this.onBeforeDelete}
                        disableAudit={true}
                        suppressMeta={true}
                        setRowClasses={(row, rowIndex) => {
                            if (relevantAssignments[row.AssignmentId]){
                                return [];
                            }else{
                                return ['dimmed'];
                            }
                        }}
                    />
                    {EM.isDomainEditor() ?
                        <div className="form-area">
                            <FormGroup className="form-inline">
                                <Select
                                    className="single-select"
                                    classNamePrefix="single-select"
                                    isSearchable={true}
                                    isClearable={true}
                                    isMulti={false}
                                    options={employeeSelectOptions}
                                    placeholder={EM.t('util.filtering.placeholderDefault')}
                                    value={this.state.selectedEmployee}
                                    components={{ Option: EmployeeAvailabilityOption }}
                                    onChange={(selected) => {
                                        if (!selected) {
                                            this.setState({ assignment: {}, selectedEmployee: null });
                                            return;
                                        } else {
                                            let assignment = Object.assign({}, this.state.assignment);
                                            assignment.EmployeeId = selected.value;
                                            this.setState({ assignment: assignment, selectedEmployee: selected })
                                        }
                                    }}
                                />
                                <button className="btn btn-success btn-sm" onClick={() => {
                                    if (!this.state.selectedEmployee) return;
                                    let currentTotal = roleAssignments.reduce((memo, item) => memo + item.Value, 0);
                                    this.addAssignment(currentTotal);
                                    this.setState({ assignment: {}, selectedEmployee: null })
                                }}>{EM.t('staffing.addAssignment')}</button>
                                <button className="btn btn-danger btn-sm" onClick={() => {
                                    this.tableRef.current.onDelete();
                                }}>{EM.t('staffing.deleteAssignments')}</button>
                            </FormGroup>
                        </div>
                        : null}
                </div>
            );
        } else {
            return null
        }
    }
}