/* global EM */
/* eslint no-throw-literal: 0 */
import Routes from './Routes';
import * as fetchingActions from '../entities/actions/fetching';

export default class API {
    constructor(authenticator, instance, loginMode) {
        this.baseApiAddress = API.getBaseAddress();
        this.Authenticator = authenticator;
        this.abortController = null;
        this.instance = instance;
        this.loginMode = loginMode;

        this.resetSignal();
    }

    static getBaseAddress(){
        if (process.env.REACT_APP_ENV === 'dev'){
            return 'http://localhost:5003/api/';
        }else{
            return '/api/';
        }
        
    }

    abortPendingFetches() {
        this.abortController.abort();
        this.resetSignal();
    }

    resetSignal() {
        if (AbortController) {
            this.abortController = new AbortController();
        } else {
            this.abortController = { signal: null, abort: function () { } };
        }
    }

    async loadTranslation(Lang) {
        const url = Routes.compose(Routes.clientApi.translation, { Lang: Lang }, { build: process.env.REACT_APP_BUILD });
        return this.fetchStaticJson(url, {});
    }

    async loadHelp(CID, Name, Lang, IsMetaData) {
        const url = Routes.compose(Routes.clientApi.help, { CID: CID, Name: Name, Lang: Lang, IsMeta: IsMetaData });
        return await this.fetch(this.baseApiAddress + url, {}, Name);
    }

    async updateHelp(Name, Lang, Content) {
        const url = Routes.compose(Routes.clientApi.help, { Name: Name, Lang: Lang });
        return await this.fetch(this.baseApiAddress + url, { method: 'PUT', body: Content }, Name);
    }

    async loadAccount(loginMode) {
        let qs = (loginMode ? 'loginMode=' + loginMode : null);
        const url = Routes.compose(Routes.clientApi.account, null, qs);
        return this.fetch(this.baseApiAddress + url, {}, 'Account');
    }

    async loadDomainEntity(DomainId, Entity, isSilent) {
        const url = Routes.compose(Routes.clientApi.domainEntity, { DomainId: DomainId, Entity: Entity });
        return this.fetch(this.baseApiAddress + url, {}, isSilent ? null : Entity);
    }

    async auditDomainEntity(DomainId, Entity, EntityId) {
        const url = Routes.compose(Routes.clientApi.auditEntity, { DomainId: DomainId, Entity: Entity, EntityId: EntityId });
        return this.fetch(this.baseApiAddress + url, {}, Entity);
    }

    async createDomainEntity(DomainId, Entity, Item) {
        const url = Routes.compose(Routes.clientApi.domainEntity, { DomainId: DomainId, Entity: Entity });
        return this.fetch(this.baseApiAddress + url, { method: 'POST', body: JSON.stringify(Item) }, Entity);
    }

    async updateDomainEntity(DomainId, Entity, Item) {
        const url = Routes.compose(Routes.clientApi.domainEntity, { DomainId: DomainId, Entity: Entity });
        return this.fetch(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify(Item) }, Entity);
    }

    async deleteDomainEntity(DomainId, Entity, ids) {
        const url = Routes.compose(Routes.clientApi.domainEntity, { DomainId: DomainId, Entity: Entity });
        return this.fetch(this.baseApiAddress + url, { method: 'DELETE', body: JSON.stringify(ids) }, Entity);
    }

    async importDomainEntity(DomainId, Entity, Items, isSilent, modsOnly) {
        const qs = modsOnly ? { MODSONLY: true } : null;
        const url = Routes.compose(Routes.clientApi.domainEntityImport, { DomainId: DomainId, Entity: Entity }, qs);
        return this.fetch(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify(Items) }, isSilent ? null : Entity);
    }

    async clearDomainEntity(DomainId, Entity) {
        const url = Routes.compose(Routes.clientApi.domainEntityClear, { DomainId: DomainId, Entity: Entity });
        return this.fetch(this.baseApiAddress + url, { method: 'DELETE' }, Entity);
    }

    async loadDomainEntityFile(DomainId, Entity, Id) {
        const url = Routes.compose(Routes.clientApi.domainEntityFile, { DomainId: DomainId, Entity: Entity, Id: Id });
        return this.fetchFile(this.baseApiAddress + url, {}, Entity + ' File');
    }

    async updateDomainEntityFile(DomainId, Entity, Id, Data) {
        const url = Routes.compose(Routes.clientApi.domainEntityFile, { DomainId: DomainId, Entity: Entity, Id: Id });
        return this.fetchFile(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify(Data) }, null);
    }

    async regenerateProjections(DomainId, ScheduleId, isSilent) {
        const url = Routes.compose(Routes.clientApi.projections, { DomainId, ScheduleId }, 'output=1');
        return this.fetch(this.baseApiAddress + url, {}, 'Projections', isSilent);
    }

    async loadAdminEntity(tenantId, entity, entityId) {
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: entity, EntityId: entityId });
        return this.fetch(this.baseApiAddress + url, {}, entity);
    }

    async createAdminEntity(tenantId, entity, newItem, entityId) {
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: entity, EntityId: entityId ? entityId : "" });
        return this.fetch(this.baseApiAddress + url, { method: 'POST', body: JSON.stringify(newItem) }, entity);
    }

    async deleteAdminEntity(tenantId, entity, ids) {
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: entity, EntityId: "" });
        return this.fetch(this.baseApiAddress + url, { method: 'DELETE', body: JSON.stringify(ids) }, entity);
    }

    async hardDeleteDomain(tenantId, ids) {
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: 'domains', EntityId: "" }, { hardDelete: true });
        return this.fetch(this.baseApiAddress + url, { method: 'DELETE', body: JSON.stringify(ids) }, 'domains', true);
    }

    async updateAdminEntity(tenantId, entity, item) {
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: entity, EntityId: "" });
        return this.fetch(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify(item) }, entity);
    }

    async importAdminEntity(tenantId, entity, items, isSilent) {
        const url = Routes.compose(Routes.adminApi.adminEntityImport, { TenantId: tenantId, Entity: entity, EntityId: "" });
        return this.fetch(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify(items) }, isSilent ? null : entity);
    }

    async switchTenant(newTenantId) {
        const url = Routes.compose(Routes.adminApi.switchTenant, {});
        return this.fetch(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify({ TenantId: newTenantId }) }, 'Tenants');
    }

    async auditUsers(tenantId) {
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: 'users' });
        return this.fetch(this.baseApiAddress + url + '?operation=audit', {}, 'Users');
    }

    async auditLogins(tenantId) {
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: 'users' });
        return this.fetch(this.baseApiAddress + url + '?operation=logins', {}, 'Users');
    }

    async updateUser(newUserObj) {
        const url = Routes.compose(Routes.clientApi.users, {});
        return this.fetch(this.baseApiAddress + url, { method: 'PUT', body: JSON.stringify(newUserObj) });
    }

    async generateUserToken(tenantId, userId) {
        const url = Routes.compose(Routes.adminApi.adminEntity, { TenantId: tenantId, Entity: 'users' });
        return this.fetch(this.baseApiAddress + url + '?operation=generateToken', { method: 'POST', body: JSON.stringify({ UserId: userId }) }, 'Users');
    }

    async uptimeReport(dateStr) {
        const url = Routes.compose(Routes.adminApi.uptimeReport, {});
        return this.fetch(this.baseApiAddress + url + '?since=' + dateStr, {}, 'Uptime');
    }

    async notify(messageDetails) {
        const url = Routes.compose(Routes.clientApi.notify, {});
        return this.fetch(this.baseApiAddress + url, { method: 'POST', body: JSON.stringify(messageDetails) });
    }

    async loadViaProxy(urlToProxy) {
        const url = Routes.compose(Routes.clientApi.proxy, {}, { url: urlToProxy });
        return this.fetch(this.baseApiAddress + url, {});
    }

    async postResourceRequestForm(domainId,formDetails){
        const url = `http://localhost:5003/api/data/${domainId}/resourceRequests`;
        console.log(url)
        console.log(domainId)
        console.log(formDetails)
        // this.baseApiAddress + 
        return this.fetch(url,{ method: 'POST', body: JSON.stringify(formDetails) });
    }

    /**
     * 
     * @param {*} domainId 
     * @param {*} fileName 
     * @param {object} files {.schedule} - includes the default schedule
     * @returns 
     */
    async getDomainTemplate(domainId, fileName, files) {
        let queryParams = Object.assign({ fileName: fileName }, files)
        const url = Routes.compose(Routes.clientApi.domainTemplateCreate, { DomainId: domainId }, queryParams);
        let fileResult = await this.fetchFile(this.baseApiAddress + url)
        return fileResult;
    }

    async createDomainFromTemplate(templatedJson) {
        const completeUrl = this.baseApiAddress + Routes.compose(Routes.clientApi.domainCreateFromTemplate);
        return this.fetch(completeUrl, { method: 'POST', body: JSON.stringify(templatedJson) });
    }

    async fetch(url, options, itemName, isSilent) {
        if (itemName && !isSilent) {
            EM.dispatch(fetchingActions.startFetch(itemName))
        };

        var token = await this.Authenticator.acquireToken();
        localStorage.setItem('temporaryToken',JSON.stringify(token));
        if (!token) {
            throw { message: 'Token acquisition failed.', name: 'acquisitionFailure' };
        }

        let defaultOptions = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            signal: this.abortController.signal,
            headers: {
                'Authorization': token,
                'RM-Instance': this.instance,
                'RM-LoginMode': this.loginMode,
                'RM-Referer': (window.location.origin + window.location.pathname)
            }
        }
        let newOptions = Object.assign(defaultOptions, options);

        return await fetch(url, newOptions)
            .then(responseText => {
                return responseText.json()
            })
            .then(responseObj => {
                if (responseObj.status === 'success') {
                    return responseObj.data
                } else {
                    throw responseObj;
                }
            })
            .catch((ex) => {
                console.log('API Error:', ex);
                throw ex;
            })
            .finally(() => {
                if (itemName && !isSilent) EM.dispatch(fetchingActions.endFetch(itemName));
            });
    }

    async fetchFile(url, options, itemName, isSilent) {
        if (itemName && !isSilent) EM.dispatch(fetchingActions.startFetch(itemName));

        var token = await this.Authenticator.acquireToken();
        if (!token) {
            throw { message: 'Token acquisition failed.', name: 'acquisitionFailure' };
        }

        let defaultOptions = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Authorization': token,
                'RM-Instance': this.instance,
                'RM-LoginMode': this.loginMode
            }
        }
       
        let newOptions = Object.assign(defaultOptions, options);

        return await fetch(url, newOptions)
            .then(responseText => {
                return responseText.json()
            })
            .then(responseObj => {
                if (responseObj.status === 'error') {
                    throw responseObj;
                } else {
                    return responseObj;
                }
            })
            .catch((ex) => {
                console.log('API Error:', ex);
                throw ex;
            })
            .finally(() => {
                if (itemName && !isSilent) EM.dispatch(fetchingActions.endFetch(itemName));
            });
    }

    async fetchStaticJson(url, options, itemName) {
        if (itemName) EM.dispatch(fetchingActions.startFetch(itemName));
        return await fetch(url, options)
            .then(responseText => {
                return responseText.json()
            })
            .catch(function (ex, b) {
                console.log('Fetch Static Error:', ex);
                throw ex;
            })
            .finally(() => {
                if (itemName) EM.dispatch(fetchingActions.endFetch(itemName));
            });
    }

    async fetchStaticText(url, options) {
        return await fetch(url, options)
            .then(responseText => {
                return responseText.text()
            })
            .catch(function (ex, b) {
                console.log('Fetch Static Error:', ex);
                throw ex;
            });
    }
}