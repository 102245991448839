import React, { Component } from 'react';
import HelpIndex from '../../entities/help/HelpIndex';
import Spinner from '../Spinner';

export default class HelpContent extends Component {
    constructor(props){
        super(props);

        this.state = {
            key: null,
            title: null,
            body: null,
            pathname: null,
        }

        this.loading = false;
    }

    componentDidMount(){
        if (!this.state.body)this.loadContent();       
    }

    componentDidUpdate(nextProps){
        if (!this.state.body || this.props.path !== nextProps.path)this.loadContent();
    }

    async loadContent(){
        if (this.loading)return;
        this.loading = true;
        let raw = this.props.path;
        if (raw === null)return;
        if (raw === '*'){
            HelpIndex.loadAll().then((content) => {
                this.setState({ key: 'all', title: null, body: content.body, pathname: window.location.pathname });
                this.loading = false;
            });
        }else{
            let path = raw.slice(1);
            let bits = path.split('/');
            let newPath = bits.slice(1).join('/');
            HelpIndex.getContent(newPath || 'home').then((content) => {
                this.setState({ key: newPath || 'home', title: content.title, body: content.body, pathname: window.location.pathname });
                this.loading = false;
            });
        }                
    }

    render() {   
        let showTitle = true;
        if (this.props.hideTitle)showTitle = false;
        return (
            <div>
                {this.state.body ?
                    <div className="help-content" id="help-content">
                        <div className="help-title clearfix">
                            {this.state.title && showTitle ? <h6 className="float-left">{this.state.title}</h6> : null }
                        </div>
                        <div className="help-body" >
                            <div dangerouslySetInnerHTML={{ __html: this.state.body }} />
                        </div>                    
                    </div>                              
                : <Spinner text="Loading..." /> }
            </div>
        );
    }
};