import React, { Component } from 'react';
import PageTitle from  '../components/PageTitle';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div key="contents" className="page container">                              
                    <PageTitle title="Error" className="text-danger" text="Something went wrong. Please consult your browser's console for more detail." />
                    <button className="btn btn-sm btn-primary" onClick={() => {
                        window.location.reload();
                    }}>Reload</button>
                </div>
            )
        }

        return this.props.children;
    }
}