/* global EM */
/* eslint no-useless-constructor: 0 */
import ProjectionsFileBase from './ProjectionsFileBase';
import Dates from '../../util/Dates';
import DateGroupValueSet from './DateGroupValueSet';
import _ from 'underscore';

export default class HeadcountsFile extends ProjectionsFileBase {
    constructor(data, entityName, id) {
        super(data, entityName, id);

        this.dataCache = {};
        this.attritionFactorCache = {};
        this.series = null;
        this.seriesTemplate = null;
        this.defaultGrouping = 'Role';
        this.defaultDateGrouping = 'Month';

        this.filterIndexes = null;
        this.processedData = null;
    }

    static async validate(csvContents) {    
        await EM.roles.load();
    
        let errors = [];

        if (csvContents.length > 1000){
            errors.push([(0 + ':' + 0), 'File contains too many rows. Maximum rows is 1,000.']);
            return errors;
        }

        let header = csvContents[0];
        if (header.length > 256){
            errors.push([(0 + ':' + 0), 'File contains too many columns. Maximum columns is 256.']);
            return errors;
        }

        csvContents.forEach((row, rowIndex) => {
            row.forEach((cell, cellIndex) => {
                if (cellIndex === 0 && rowIndex > 0){
                    let roleFound = EM.roles.lookupId(row[0], 'Name');
                    if (!roleFound){
                        errors.push([(rowIndex + ':' + cellIndex), 'Role not found.']);
                    }
                }  
                
                if (cellIndex > 0 && rowIndex === 0){
                    if (!cell || !Dates.isValidDateStr(cell)){
                        errors.push([(rowIndex + ':' + cellIndex), 'Invalid date format. Must be in format m/d/yyyy.']);
                    }
                }

                if (cellIndex > 0 && rowIndex > 0){
                    let value = parseFloat(cell);
                    if (isNaN(value)){
                        errors.push([(rowIndex + ':' + cellIndex), 'Values must be numeric.']);
                    }
                }
            });                       
        });

        return errors;
    }    

    static preSaveTransform(csvContents) {    
        csvContents[0].forEach((cell, cellIndex) => {
            if (cellIndex > 0){
                csvContents[0][cellIndex] = Dates.fromStr(cell).toISO();
            }                        
        });

        return csvContents;        
    }      

    static preExportTransform(csvContents) {    
        let output = csvContents.map((row, rowIndex) => {
            if (rowIndex === 0){
                return row.map((cell, cellIndex) => {
                    if (cellIndex > 0){
                        return Dates.fromISO(cell).toLocaleString();
                    } else {
                        return cell;
                    }                      
                });
            }else{
                return row;
            }    
        });
        return output;        
    }          
    
    setFilterIndexes() {
        let header = this.data[0];
        let output = {
            role: 0,
            department: header.length,
            organization: header.length + 1
        };
        return output;
    }    

    preprocessData(){
        this.filterIndexes = this.setFilterIndexes();
        this.filterIndexKeys = Object.keys(this.filterIndexes);
        let rolesNotFound = [];

        this.processedData = this.data.mapFiltered((rowIn, rowIndex) => {
            let row = rowIn.map((value) => value);
            if (rowIndex === 0) {
                row.push('Department');
                row.push('Organization');
                return row;
            } else {
                let role = EM.roles.findByKey(row[0]);
                let dept = EM.departments.byId(role ? role.DepartmentId : null);
                let org = EM.organizations.lookupValue(dept ? dept.OrganizationId : null);
                if (role && dept && org) {
                    row.push(dept.Name);
                    row.push(org);
                } else {
                    rolesNotFound.push(row[0]);
                    return null;
                }
                return row;
            }
        });

        if (rolesNotFound.length > 0){
            console.log('Headcount roles not found:', rolesNotFound);
        }
    } 

    getMaxDateRange(){
        if (!this.processedData)this.preprocessData();
        let dts = this.processedData[0].slice(1, -2);
        let begin = Dates.fromISO(dts[0]);
        let end = Dates.fromISO(dts[dts.length - 1]).plus({ years: 1 });   
        
        if (begin < Dates.visualizableBegin)begin = Dates.visualizableBegin;
        if (end > Dates.visualizableEnd)end = Dates.visualizableEnd;

        return [ begin, end ];
    }     

    async getSummaryByDate(preferences, reserve, attrition) {
        let self = this;        
        if (!this.processedData)this.preprocessData();

        let sKey = this.getCacheKey(preferences, reserve, attrition);
        let fromCache = await this.getFromSummaryCache(sKey);
        if (fromCache) {
            return fromCache;
        } else {
            let header = this.processedData[0];
            let dateHeaders = header.slice(1, header.length - 2);

            let dateSets = dateHeaders.map((dtStr, dtIndex) => {
                let begin = Dates.fromISO(dtStr);
                let end = null;
                let endStr = dateHeaders[dtIndex + 1];
                if (endStr){
                    end = Dates.fromISO(endStr).plus({ months: -1 });
                }else{
                    end =  begin.plus({ years: 1 });
                }
                return Dates.getArrayOfMonths(begin, end, true).dates;
            });

            let grouping = preferences.grouping || this.defaultGrouping;
            let groupingColumn = this.filterIndexes[grouping.toLowerCase()];
            let dateGrouping = preferences.dateGrouping|| this.defaultDateGrouping;

            let monthGroups = {};
            this.processedData.forEach((row, rowIndex) => {
                if (rowIndex === 0) return;
                if (!this.isRowIncluded(row, preferences)) return;

                dateSets.forEach((monthSets, dateSetIndex) => {
                    monthSets.forEach(month => {
                        let dgInfo = self.getDateGroup(dateGrouping, month);
                        let monthKey = dgInfo.key;
                        let groupKey = self.getGroupKey(grouping, row[groupingColumn]);
                        let value = parseFloat(row[dateSetIndex + 1]);

                        //if (reserve) value = value * (1 + reserve);
                        if (attrition) value = value * this.getSimpleAttritionFactor(month, attrition);
                        //if (pos) value = value * this.getPoS(row);

                        if (!monthGroups[monthKey]) {
                            monthGroups[monthKey] = {};
                        }
                        if (!monthGroups[monthKey][groupKey]){
                            monthGroups[monthKey][groupKey] = new DateGroupValueSet(dateGrouping);
                        }  
                        monthGroups[monthKey][groupKey].push(month, value);
                        monthGroups[monthKey]['date'] = dgInfo.begin;

                        if (!monthGroups[monthKey]['total']){
                            monthGroups[monthKey]['total'] = new DateGroupValueSet(dateGrouping);
                        }                    
                        monthGroups[monthKey]['total'].push(month, value);
                    });            
                });
            });        

            this.saveToSummaryCache(sKey, monthGroups);
            return monthGroups;
        }
    }


    getDetailByDate(ms, groupingValue, preferences, reserve, attrition){
        let datatable = [];
    
        let grouping = preferences.grouping || this.defaultGrouping;
        let groupingColumn = this.filterIndexes[grouping.toLowerCase()];
        let dateGrouping = preferences.dateGrouping|| this.defaultDateGrouping;
        let date = Dates.fromMs(ms);        
        let dgInfo = this.getDateGroup(dateGrouping, date); 

        let matchingDateCols = [];
        let dts = this.processedData[0].slice(1, -2);
        dts.forEach((dt, dtI) => {
            let dtObjBegin = Dates.fromISO(dt);
            let dtObjEnd = (dts[dtI + 1] ? Dates.fromISO(dts[dtI + 1]) : dtObjBegin.plus({ years: 1 })).plus({ minutes: -1 });

            let overlapping = Dates.doRangesOverlap(dgInfo.begin, dgInfo.end, dtObjBegin, dtObjEnd);                
            if (overlapping){
                matchingDateCols.push(dtI + 1);
            }
        });

        if (matchingDateCols.length === 0)return;

        this.processedData.forEach((row, rowIndex) => {
            if (rowIndex === 0) return;                                             
            if (row[groupingColumn] !== groupingValue)return;

            let value = matchingDateCols.reduce((memo, item) => {
                let val = parseInt(row[item])
                return memo += val || 0;
            }, 0);

            if (matchingDateCols.length > 1){
                value = value / matchingDateCols.length;
            }

            if (attrition) value = value * this.getSimpleAttritionFactor(date, attrition);

            datatable.push({
                id: rowIndex,
                organization: row[this.filterIndexes.organization],
                department: row[this.filterIndexes.department],
                role: row[this.filterIndexes.role],
                workitem: 'N/A',
                activity: 'N/A',
                begin: 0,
                end: 0,
                complexity: 'N/A',
                value: value,
                cid: 'N/A',
                mid: 'N/A',
                mm: 'N/A'
            });

        });
                                    
        let groups = _.groupBy(datatable, 'role');
        return groups;
    }

    cellFormatter(row, col, value){
        if (row === 0 && col > 0){
            return Dates.fromISO(value).toLocaleString();
        }

        return value;
    }

    isRowIncluded(row, preferences) {
        let keys = this.filterIndexKeys;    
        if (keys.length === 0) return true;

        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            if (key === 'begin' || key === 'end' || key === 'value')continue;
            let acceptedValues = preferences.get(key);
            if (!acceptedValues) continue;
            let rowValue = row[this.filterIndexes[key]];

            if (key === 'activity') {
                rowValue = EM.activities.lookupValue(rowValue);
            }
            
            if (acceptedValues.indexOf(rowValue) === -1) {
                return false;
            }
        }

        return true;
    }

    getSimpleAttritionFactor(month, attrition){
        let key = month.toMillis().toString() + attrition;
        if (!this.attritionFactorCache[key]){
            let months = Math.ceil(month.diff(Dates.now(), 'months').toObject().months);            
            let factor = months > 0 ? 1 - ((months/12) * attrition) : 1;   
            this.attritionFactorCache[key] = factor > 0 ? factor : 0;
        }
        return this.attritionFactorCache[key];
    }    
}
