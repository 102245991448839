/* global EM */
import React, { Component } from 'react';
import ColorManager from '../../util/ColorManager';
import _ from 'underscore';

export default class DemandGraphLegend extends Component {
    constructor(props){
        super(props);

        this.state = {
            ts: null,
            isOpen: false,
            showAll: true
        }

        this.colors = new ColorManager();
        this.series = null;
    }

    getAllSeries(){
        let roles = this.getGroupingSeries(EM.roles, 'Role', ['department', 'organization']);
        let depts = this.getGroupingSeries(EM.departments, 'Department', ['organization']);
        let orgs = this.getGroupingSeries(EM.organizations, 'Organization'); 
        return [...roles, ...depts, ...orgs ];   
    }

    getGroupingSeries(dataset, grouping, addProps){
        let self = this;
        let series = [];
        let seriesItems = _.sortBy(dataset.get().map(row => {
            let output = { name: row.Name };

            if (row.DepartmentId){
                let deptObj = EM.departments.byId(row.DepartmentId);
                if (deptObj)output.department = deptObj.Name;

                let orgObj = EM.organizations.byId(deptObj.OrganizationId);
                if (orgObj)output.organization = orgObj.Name;
            }

            if (row.OrganizationId){
                let orgObj = EM.organizations.byId(row.OrganizationId);
                if (orgObj)output.organization = orgObj.Name;
            }            

            return output;
        }), item => item.name);
        
        seriesItems.forEach((item, index) => {            
            let output = { 
                name: item.name,
                color: self.colors.next(),
                seriesGroup: grouping,
                visible: true
            };            
            if (item.department) output.department = item.department;
            if (item.organization) output.organization = item.organization;
            series.push(output);
        });
        return series;
    }    

    componentDidUpdate(prevProps){
        if (EM.getSetting('DisableStickyDemandLegend') === 'true'){
            if (prevProps.preferences !== this.props.preferences){
                this.series.forEach(item => item.visible = true);
                this.setState({ showAll: true });
                this.props.onLegendItemsToggled(true);
            }
        }
    }

    isIn(series, roles, depts, orgs){
        let inRoles = !roles;
        let inDepts = !depts;
        let inOrgs = !orgs;        

        if (roles && roles.length > 0){
            if (roles.indexOf(series.name) > -1)inRoles = true;
        }

        if (depts && depts.length > 0){
            if (depts.indexOf(series.department) > -1)inDepts = true;
        }

        if (orgs && orgs.length > 0){
            if (orgs.indexOf(series.organization) > -1)inOrgs = true;
        }
        return inRoles && inDepts && inOrgs;
    }

    render() {
        if (!this.series)this.series = this.getAllSeries();
        let grouping = this.props.preferences.grouping || 'Role';

        return (
            <div className={"demand-legend " + ((this.state.isOpen || this.props.isReportMode)?'active':'')} onClick={() => {
                this.setState({ isOpen: !this.state.isOpen });
                EM.triggerWindowResize();    
            }}>   
                { this.series? 
                    <div className="demand-legend-content">                
                        {!this.props.preferences.focusMode ? 
                            <div className={"legend-item all-toggle "} key={"all"} onClick={(event) => {
                                event.stopPropagation();
                                event.nativeEvent.stopImmediatePropagation();
                                let newShowValue = !this.state.showAll;
                                this.series.forEach(item => item.visible = newShowValue);
                                this.props.onLegendItemsToggled(newShowValue);
                                this.setState({ showAll: newShowValue });
                            }}>
                                <div>
                                </div>
                                <span>{EM.t('demand.toggleAll')}</span>
                            </div>   
                        : null }                 
                        {this.series.map((series, si) => {
                            if (series.seriesGroup !== grouping)return null;

                            let isIn = this.isIn(series, this.props.preferences.role, this.props.preferences.department, this.props.preferences.organization);
                            if (!isIn)return null;

                            return (
                                <div className={"legend-item " + (series.visible?'':'hidden') + (this.props.preferences.focusMode?' focus':'')} key={"si" + si} onClick={(event) => {
                                    event.stopPropagation();
                                    event.nativeEvent.stopImmediatePropagation();
                                    if (this.props.preferences.focusMode){
                                        this.series.forEach(item => item.visible = (item === series));
                                        this.props.onLegendItemClicked(series, true);
                                    }else{
                                        series.visible = !series.visible;                                
                                        this.props.onLegendItemClicked(series, false);
                                    } 
                                    this.setState({ ts: new Date().getTime() });  
                                }}>
                                    <div style={{ backgroundColor: series.color }} title={series.name}></div>
                                    <span>{series.name}</span>
                                </div>
                            );
                        })} 
                    </div>    
                : null }
                {!this.props.isReportMode ?
                    <button className="btn btn-default btn-focus" onClick={(event) => {
                        event.stopPropagation();
                        event.nativeEvent.stopImmediatePropagation();
                        this.props.preferences.focusMode = !this.props.preferences.focusMode;
                        this.setState({ ts: new Date().getTime() });
                    }}>
                        <i className="fas fa-bullseye"></i>
                    </button>                
                : null }
                {!this.props.isReportMode ?
                    <button className="btn btn-default btn-toggle" >
                        <i className="fas fa-bars"></i>
                    </button>
                : null } 
            </div>
        );
    }
};