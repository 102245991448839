/* eslint eqeqeq: "off" */
/* global EM */
import React, { Component } from 'react';
import Dates from '../../util/Dates';
import _ from 'underscore';
import StaffingWorkItemRoleManageTasksDialog from './StaffingWorkItemRoleManageTasksDialog';
import StaffingUtil from '../../util/StaffingUtil';

export default class StaffingWorkItemRoleDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailsDialogVisible: false
        };

        let textEndSetting = EM.getSetting('FavorTextEndInLabels') || 'false';
        this.showRtlLabels = (textEndSetting === 'true' ? true : false);
    }

    render() {
        let { projections, range, filterIndexes, assignments } = this.props;
        let pHeight = this.props.rowHeight;

        let filteredProjections = projections.filter(projection => {
            return Dates.doMonthRangesOverlap(projection[filterIndexes.range], range);
        }).sort((a, b) => {
            return a[filterIndexes.range].beginIndex - b[filterIndexes.range].beginIndex;
        });

        let projectionGroups = _.groupBy(filteredProjections, (projection) => {
            return projection[filterIndexes.role];
        });

        let projectionGroupsKeys = Object.keys(projectionGroups).sort();
        assignments = assignments ? assignments : [];

        let cellWidth = this.props.cellWidth;
        let canEdit = !!this.props.onAddAssignment;

        return (
            <div className={"item-detail"} onClick={(event) => {
                event.stopPropagation();
            }}>
                {this.props.milestones && this.props.milestones.length > 0 ?
                    <div className="item-milestones">
                        {this.props.milestones}
                    </div>
                : null}
                {projectionGroupsKeys.map((projectionsGroupKey, pgkI) => {
                    let projectionsInGroup = projectionGroups[projectionsGroupKey];

                    let earliest = _.min(projectionsInGroup, item => {
                        return item[filterIndexes.range].dates[0];
                    });
                    if (!earliest) return null;
                    let beginDt = earliest[filterIndexes.range].dates[0];
                    let beginStr = beginDt ? Dates.toMonthYearStrShort(beginDt) : 'Invalid Date Range';

                    let latest = _.max(projectionsInGroup, item => {
                        let dts = item[filterIndexes.range].dates;
                        return dts[dts.length - 1];
                    });
                    if (!latest) return null;
                    let endDt = latest[filterIndexes.range].dates[latest[filterIndexes.range].dates.length - 1];
                    let endStr = endDt ? ' - ' + Dates.toMonthYearStrShort(endDt) : '';

                    let itemAssignments = _.filter(assignments, function (assignment) {
                        let proj = projectionsInGroup[0][filterIndexes.workitem];
                        let employeeId = assignment.EmployeeId;
                        if (!proj || !employeeId) return;

                        let employee = EM.employees.byId(employeeId);
                        let role = EM.roles.lookupValue(employee.RoleId);
                        if (!employee || !role) return;

                        return role.trim() === projectionsGroupKey.trim() && assignment.WorkItemName.trim() === proj.trim();
                    });

                    let assignmentDateGroups = {};
                    if (itemAssignments) {
                        itemAssignments.forEach((assignment) => {
                            assignment.dateKeys.forEach(dateKey => {
                                if (!assignmentDateGroups[dateKey]) assignmentDateGroups[dateKey] = 0;
                                assignmentDateGroups[dateKey] += assignment.Value;
                            });
                        });
                    }

                    let projectionDateGroups = {};                        
                    projectionsInGroup.forEach((projection) => {
                        if (!projection[filterIndexes.dateKeys])return;
                        projection[filterIndexes.dateKeys].forEach(dateKey => {
                            if (!projectionDateGroups[dateKey])projectionDateGroups[dateKey] = 0;
                            projectionDateGroups[dateKey] += projection[filterIndexes.value];
                        })
                    });                     

                    let focussed = true;
                    if (this.props.focusRoles && this.props.focusRoles.length > 0){
                        focussed = !!(projectionsInGroup.find(proj => {
                            return this.props.focusRoles.indexOf(proj[filterIndexes.roleId].toString()) > -1
                        }));
                    }

                    let localRange = Dates.getArrayOfMonths(beginDt, endDt, true);

                    let left = (localRange.beginIndex - range.beginIndex) * cellWidth;
                    let clip = -left + this.props.maxWidth;

                    return (
                        <div key={projectionsGroupKey} className={"detail-row " + (focussed?'':'hidden')} style={{ height: pHeight }}>
                            <div className={"detail-header"} title={projectionsGroupKey}>
                                <b className={this.showRtlLabels?'rtl':''}>{projectionsGroupKey}</b><br />
                                <span>{beginStr + endStr}</span>
                            </div>
                            <div className="detail-grid" style={{ height: pHeight }}>
                                <div                                    
                                    key={projectionsGroupKey}
                                    className={"detail-bar detail-bar-tall detail-bar-alt"}
                                    title={projectionsGroupKey}
                                    style={{
                                        width: localRange.dates.length * cellWidth,
                                        left: (localRange.beginIndex - range.beginIndex) * cellWidth,
                                        clip: "rect(-5px, " + clip + "px, 40px, -5px)"
                                    }}
                                    onClick={(event) => {
                                        if (!canEdit) return null;
                                        this.setState({
                                            detailsDialogVisible: true,
                                            detailData: {
                                                range,                                                
                                                projectionsGroupKey,
                                                projectionsInGroup,                                               
                                                workItem: projectionsInGroup[0][filterIndexes.workitem],
                                                filterIndexes
                                            }                                            
                                        })
                                    }}
                                >
                                    {localRange.dates.map((month, dateKeyIndex) => {
                                        let dateKey = localRange.beginIndex + dateKeyIndex;
                                        let assignmentValue = Math.fround(assignmentDateGroups[dateKey] ? assignmentDateGroups[dateKey] : 0);
                                        let projectionsValue = Math.fround(projectionDateGroups[dateKey] ? projectionDateGroups[dateKey] : 0);
                                        if (projectionsValue <= 0)return null;
                                        return (
                                            <span
                                                key={dateKey}
                                                className={StaffingUtil.getColorClass(assignmentValue, projectionsValue, true)}
                                                title={StaffingUtil.getTooltip(assignmentValue, projectionsValue)}
                                                style={{
                                                    left: dateKeyIndex * cellWidth
                                                }} 
                                            >
                                                {StaffingUtil.getPercentage(assignmentValue, projectionsValue)}%
                                            </span>
                                        );
                                })}
                                </div>
                            </div>
                        </div>
                    );
                })}

                {this.props.isActive ?
                    <StaffingWorkItemRoleManageTasksDialog
                        visible={this.state.detailsDialogVisible}
                        assignments={assignments}
                        onClose={(event) => { 
                            this.setState({ 
                                detailsDialogVisible: false, 
                                detailData: null
                            }); 
                        }}
                        filterIndexes={this.props.filterIndexes}
                        onAddAssignment={this.props.onAddAssignment}
                        onAfterDeleteAssignment={this.props.onAfterDeleteAssignment}
                        onAfterUpdateAssignment={this.props.onAfterUpdateAssignment}
                        {...this.state.detailData}
                    />
                : null}
            </div>
        );
    }
}