/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import { Validators } from '../util/EntityValidators';
import PanelModal from '../components/PanelModal';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SimpleJsonObjectEditor from '../components/JsonEditor/SimpleJsonObjectEditor';
import CreateTemplateDialog from '../components/DomainImportExport/CreateTemplateDialog'
import CreateNewDomainDialog from '../components/DomainImportExport/CreateNewDomainDialog'
import Common from '../util/Common';

export default class AdminDomains extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editingOptions: null,
            optionEditorProps: null,
            newOptionsStr: '',
            exportDomainModalOpen: false,
            importDomainModalOpen: false,
        };

        this.onCloseOptions = this.onCloseOptions.bind(this);
        this.onSaveOptions = this.onSaveOptions.bind(this);

        EM.clearActiveDomain();
        EM.domains.load();
        EM.users.load();

        this.defaultSort = {
            dataField: 'Active',
            order: 'desc'
        };
    }

    componentDidMount(){
        if (!EM.isTenantAdmin())return EM.denyFeature(); 
    }

    onSaveOptions() {
        if (this.state.optionEditorProps && this.state.editingOptions && this.state.newOptionsStr) {
            let jsonObj;
            try {
                jsonObj = JSON.parse(this.state.newOptionsStr);
            } catch (e) { }

            if (!jsonObj && this.state.newOptionsStr) {
                alert('Invalid JSON.');
                return;
            }

            this.state.optionEditorProps.onUpdate(this.state.newOptionsStr);
        }
        this.onCloseOptions();
    }

    onCloseOptions() {
        if (this.state.optionEditorProps) {
            try {
                this.state.optionEditorProps.onBlur();
            } catch (e) { }
        }
        this.setState({ editingOptions: null, optionEditorProps: null, newOptionsStr: '' });
    }

    onBeforeDelete(ids){
        if (ids.length > 1) {
            EM.setStatusMessage(EM.t('adminDomains.onDelete.multiDeleteError'), 'error', 5000);
            return { cancel: true };
        }

        let selectedDomainId = ids[0];
        let domain = EM.domains.byId(selectedDomainId.toString());

        let userInput = window.prompt(EM.t('adminDomains.onDelete.deleteConfirmation'));
        if (userInput === null)return { cancel: true };

        if (domain.Name !== userInput) {
            EM.setStatusMessage(EM.t('adminDomains.onDelete.failedConfirmationError'), 'error', 5000);
            return { cancel: true };
        }
    }

    async onDelete(ids) {        
        if (ids) {
            let tenantId = EM.me.Tenant.TenantId;
            try {
                let result = await EM.api.hardDeleteDomain(tenantId, ids);
                if (result > 0) {
                    EM.setStatusMessage(EM.t('adminDomains.onDelete.successMessage', false, [result]), 5000);
                    await Common.sleep(2500);
                    window.location.reload();
                } else {
                    EM.setStatusMessage(EM.t('adminDomains.onDelete.errorMessage'), 'error', 5000);
                    return { cancel: true };
                }
            } catch (e) {
                EM.setStatusMessage(e.message, 'error', 5000);
                return { cancel: true };
            }
        }
    }

    render() {
        let data = null;
        let columns = null;
        const entity = EM.domains;

        if (EM.allLoaded(entity, EM.users)) {
            data = entity.get();
            columns = [{
                dataField: 'DisplayName',
                width: 200,
                text: entity.columns('displayName'),
                validators: [Validators.required],
                filterOptions: {
                    text: true
                }
            }, {
                dataField: 'Name',
                width: 200,
                text: entity.columns('name'),
                validators: [Validators.required, Validators.domainName],
                editable: false,
                getValidationContext: () => {
                    return EM.me.Tenant;
                },
                filterOptions: {
                    text: true
                }
            }, {
                dataField: 'Options',
                text: entity.columns('options'),
                width: 85,
                asOptions: true,
                editable: EM.isSuperAdmin(),
                hidden: !EM.isSuperAdmin(),
                onStartEdit: (editorProps, value, row, column, rowIndex, columnIndex) => {
                    window.setTimeout(() => {
                        this.setState({ editingOptions: row, optionEditorProps: editorProps });
                    }, 0);
                },
            }, {
                dataField: 'Active',
                text: entity.columns('active'),
                asActive: true,
                width: 75
            }];
        }

        let title = EM.me.Tenant.DisplayName + ': ' + entity.t('title');

        let currentOptions = this.state.editingOptions;
        let currentOptionsObj = JSON.parse(currentOptions ? (currentOptions.Options || '{}') : '{}');

        return (
            <div key="contents" className="page container-fluid">
                <EntityTable forceMeta={true} pageTitle={title} pageIcon="fas fa-layer-group" entity={entity}
                    data={data}
                    columns={columns}
                    defaultSort={this.defaultSort}
                    className={"table-overflow-container"}
                    hideClearAll={true}
                    hideAudit={true}
                    hideAddRecord={!EM.isSuperAdmin()}
                    hideDelete={!EM.isSuperAdmin()}
                    hideImport={!EM.isSuperAdmin()}
                    onBeforeDelete={this.onBeforeDelete}
                    onDelete={this.onDelete}
                    suppressDeleteConfirmation={true}
                >
                    {EM.isSuperAdmin() ?
                        <div className="btn-group">
                            <button className="btn btn-secondary btn-sm" title='Create a template from the selected domain' onClick={() => {
                                this.setState({ exportDomainModalOpen: true });
                            }}>
                                <i className="fas fa-server"></i><span>Create Template</span>
                            </button>
                            <button className="btn btn-secondary btn-sm" title='Create a new domain from a template' onClick={() => {
                                this.setState({ importDomainModalOpen: true });
                            }}>
                                <i className="fa-regular fa-file-import"></i><span>Import Domain</span>
                            </button>
                        </div>
                        : null}
                </EntityTable>
                <PanelModal fade={false} isOpen={!!currentOptions} toggle={this.onCloseOptions} className={'panel expression-modal'} key="import-modal">
                    <ModalHeader toggle={this.onCloseOptions}>Edit Options: {currentOptions ? currentOptions.DisplayName : ''}</ModalHeader>
                    <ModalBody>
                        <div>
                            <SimpleJsonObjectEditor
                                template={{
                                    color: { type: "color", display: 'Color' },
                                    order: { type: "string", display: 'Order' }
                                }}
                                defaultValue={currentOptionsObj}
                                onChange={(newObject) => {
                                    this.setState({ newOptionsStr: JSON.stringify(newObject) });
                                }}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={this.onCloseOptions}>{EM.t('util.cancel')}</Button>
                        <Button color="primary" onClick={this.onSaveOptions}>{EM.t('util.save')}</Button>
                    </ModalFooter>
                </PanelModal>
                <CreateTemplateDialog isOpen={this.state.exportDomainModalOpen} domains={data} key="exportDomainModalOpen"
                    onClose={() => {
                        this.setState({ exportDomainModalOpen: false });
                    }}
                />
                <CreateNewDomainDialog isOpen={this.state.importDomainModalOpen} key="importDomainModalOpen"
                    onClose={() => {
                        this.setState({ importDomainModalOpen: false });
                    }}
                />
            </div>
        );
    }
}
