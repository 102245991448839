import React from 'react';
import ColorManager from '../util/ColorManager';
import tinycolor from 'tinycolor2';

const userColors = new ColorManager();

const UserIcon = ({ user, className, altUser}) => {
    if (!user && !altUser)return null;

    let bits = altUser ? altUser.split(' ') : user.Name.split(' ');
    let initials = bits.reduce((accumulator, value) => {
        return accumulator + (value[0] || '');
    }, '').replace(/\W/g, '').toUpperCase();

    let color = altUser ? '#dddddd' : userColors.at(user.UserId);
    let colorObj = tinycolor(color);
    if (colorObj.getBrightness() > 175){
        color = colorObj.darken(10).toString();
    }

    return (
        <div className={"user-icon " + (className?className:"")} style={{ backgroundColor: color }} alt="Avatar" title={altUser || user.Email}>
            <span>{initials}</span>
        </div>
    );
};

export default UserIcon;