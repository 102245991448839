import * as types from '../actionTypes';
import * as errorActions from './error';
import ColorManager from '../../util/ColorManager';
import tinycolor from 'tinycolor2';

export function loadAccountSuccess(accountData) {
    return { type: types.LOAD_ACCOUNT, data: accountData };
}

export function loadAccount(loginMode) {
    return (dispatch, getState, api) => {
        return api.loadAccount(loginMode).then(data => {
            let colors = new ColorManager();
            let newData = data;
            newData.Domains.sort((a, b) => {
                return a.DomainId - b.DomainId;
            }).forEach((domain, index) => {
                if (domain.Options){
                    let jsonObj; 
                    try{
                        jsonObj = JSON.parse(domain.Options);
                    }catch(e){}
                    if (jsonObj){
                        if (jsonObj.color){                        
                            domain.color = jsonObj.color;
                        }
                        if (jsonObj.order){                        
                            domain.order = jsonObj.order;
                        }
                    }
                }

                if (!domain.color){
                    let color = colors.at(index + 1);
                    let colorObj = tinycolor(color);
                    if (colorObj.getBrightness() > 175){
                        color = colorObj.darken(5).toString()
                    }
                    domain.color = color;
                }

                if (!domain.order){
                    domain.order = null;
                }
            });
            return dispatch(loadAccountSuccess(data));
        }).catch(error => {
            dispatch(errorActions.globalError(error));
            throw error;
        });
    };
}