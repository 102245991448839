/* eslint eqeqeq: "off" */
/* global EM */
import React, { Component } from 'react';
import Dates from '../../util/Dates';
import _ from 'underscore';
import StaffingWorkItemActivityManageTasksDialog from './StaffingWorkItemActivityManageTasksDialog';
import StaffingUtil from '../../util/StaffingUtil';

export default class StaffingWorkItemActivityDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            detailsDialogVisible: false,
            roleEmployeesVisible: [],
            roles: [],
            employeeGroups: [],
            manageEmployeeModalTitle: '',
            projectionGroupsKey: ''
        };

        let textEndSetting = EM.getSetting('FavorTextEndInLabels') || 'false';
        this.showRtlLabels = (textEndSetting === 'true' ? true : false);
    }

    getRoles(projections, activity) {
        let filterIndexes = this.props.filterIndexes;
        let filteredProjections = _.filter(projections, function (projection) {
            return projection[filterIndexes.activity] == activity;
        });
        return _.sortBy(_.uniq(_.map(filteredProjections, (projection) => {
            return { name: projection[filterIndexes.role], roleId: projection[filterIndexes.roleId], activity: projection[filterIndexes.activity] };
        }), "roleId"), 'name');
    }

    getAssignmentModalTitle(projection, projectionGroupsKey, filterIndexes) {
        return projection[filterIndexes.workitem] + ': ' + projectionGroupsKey + ' (' + Dates.toMonthYearStrShort(projection[2]) + ' - ' + Dates.toMonthYearStrShort(projection[3]) + ')';
    }

    render() {
        let self = this;
        let { projections, range, filterIndexes, assignments } = this.props;
        let filteredProjections = projections.filter(projection => {
            return Dates.doMonthRangesOverlap(projection[filterIndexes.range], range);
        });

        let projectionGroups = _.groupBy(filteredProjections, (projection) => {
            return EM.activities.lookupValue(projection[filterIndexes.activity]);
        });
        let projectionGroupsKeys = _.sortBy(Object.keys(projectionGroups), key => {
            let activity = EM.activities.findByKey(key);
            if (activity) {
                return activity.Order ? parseInt(activity.Order) : 1000;
            } else {
                return 1000;
            }
        });

        let pHeight = this.props.rowHeight;
        assignments = assignments ? assignments : [];
        let leftOverAssignments = [...assignments];

        return (
            <div className={"item-detail"} onClick={(event) => {
                event.stopPropagation();
            }}>
                {this.props.milestones && this.props.milestones.length > 0 ?
                    <div className="item-milestones">
                        {this.props.milestones}
                    </div>
                : null}
                {projectionGroupsKeys.map((projectionGroupsKey, pgkI) => {
                    try {
                        let projectionsInGroups = projectionGroups[projectionGroupsKey];
                        let projectionsDeduped = projectionsInGroups.groupBy(proj => {
                            let range = proj[filterIndexes.range];
                            return proj[filterIndexes.activity] + '-' + range.beginIndex + '-' + range.endIndex;
                        });

                        let earliest = _.min(projectionsInGroups, item => {
                            return item[filterIndexes.range].dates[0];
                        });
                        if (!earliest) return null;
                        let beginDt = earliest[filterIndexes.range].dates[0];
                        let beginStr = beginDt ? Dates.toMonthYearStrShort(beginDt) : 'Invalid Date Range';

                        let latest = _.max(projectionsInGroups, item => {
                            let dts = item[filterIndexes.range].dates;
                            return dts[dts.length - 1];
                        });
                        if (!latest) return null;
                        let endDt = latest[filterIndexes.range].dates[latest[filterIndexes.range].dates.length - 1];
                        let endStr = endDt ? ' - ' + Dates.toMonthYearStrShort(endDt) : '';

                        let projectionsToDraw = Object.values(projectionsDeduped).sort((a, b) => {
                            return a[0][filterIndexes.range].beginIndex - b[0][filterIndexes.range].beginIndex;
                        });

                        let itemAssignments = _.filter(assignments, function (assignment) {
                            let proj = projectionsToDraw[0][0][filterIndexes.workitem];
                            let act = assignment.ActivityName;
                            if (!proj || !act) return;

                            return act.trim() === projectionGroupsKey.trim() && assignment.WorkItemName.trim() === proj.trim();
                        });

                        let assignmentDateGroups = {};
                        if (itemAssignments) {
                            itemAssignments.forEach((assignment) => {
                                assignment.dateKeys.forEach(dateKey => {
                                    if (!assignmentDateGroups[dateKey]) assignmentDateGroups[dateKey] = 0;
                                    assignmentDateGroups[dateKey] += assignment.Value;
                                });
                            });
                        }

                        let perfectMatchAssignments = [];
                        itemAssignments.forEach((assignment) => {
                            projectionsToDraw.forEach((projSet) => {
                                let projForMatch = projSet[0];
                                if (assignment.range.beginIndex === projForMatch[filterIndexes.range].beginIndex && assignment.range.endIndex === projForMatch[filterIndexes.range].endIndex) {
                                    perfectMatchAssignments.push(assignment);
                                }
                            });
                        });

                        let unmatchedAssignments = _.difference(itemAssignments, perfectMatchAssignments);

                        let focussed = true;
                        if (this.props.focusRoles && this.props.focusRoles.length > 0){
                            focussed = !!(projectionsInGroups.find(proj => {
                                return this.props.focusRoles.indexOf(proj[filterIndexes.roleId].toString()) > -1
                            }));
                        }

                        return (
                            <div key={projectionGroupsKey} className={"detail-row " + (focussed?'':'hidden')} style={{ height: pHeight * (unmatchedAssignments.length > 0 ? 2 : 1) }}>
                                <div className={"detail-header"} title={projectionGroupsKey}>
                                    <b className={this.showRtlLabels?'rtl':''}>{projectionGroupsKey}</b><br />
                                    <span>{beginStr + endStr}</span>
                                    {unmatchedAssignments.length > 0 ? 
                                        <span className="secondary">
                                            * {EM.t('staffing.unaligned')}
                                        </span>
                                    : null }
                                </div>
                                <div className="detail-grid">
                                    {projectionsToDraw.map((projSet, pid) => {
                                        let projection = projSet[0];

                                        let cellWidth = this.props.cellWidth;
                                        let canEdit = this.props.onAddAssignment;

                                        let left = (projection[filterIndexes.range].beginIndex - range.beginIndex) * cellWidth;
                                        let clip = -left + this.props.maxWidth;

                                        let marginBuffer = 0;
                                        let hasProjections = false;

                                        let projectionsValue = Math.fround(projSet.reduce(function (memo, projectionInGroup) {
                                            return memo + projectionInGroup[filterIndexes.value];
                                        }, 0));

                                        let outputRange = range.dates.map(function (month, dateKeyId) {
                                            let dataKey = range.beginIndex + dateKeyId;
                                            let assignmentValue = Math.fround(assignmentDateGroups[dataKey] ? assignmentDateGroups[dataKey] : 0);

                                            marginBuffer = _.indexOf(projection[filterIndexes.dateKeys], dataKey);
                                            let left = marginBuffer * cellWidth;

                                            if (projectionsValue > 0 && assignmentValue > 0 && left >= 0) {
                                                hasProjections = true;
                                                let output = (
                                                    <span
                                                        onClick={(event) => {
                                                            if (!canEdit) return null;
                                                            let title = self.getAssignmentModalTitle(projection, projectionGroupsKey, filterIndexes);
                                                            let roles = self.getRoles(filteredProjections, projection[filterIndexes.activity]);
                                                            let employeeGroups = _.groupBy(EM.employees.getActive(), "RoleId");
                                                            self.setState({ roles: roles, employeeGroups: employeeGroups, detailsDialogVisible: true, projectionDetailSet: projSet, manageEmployeeModalTitle: title, projectionGroupsKey: projectionGroupsKey });
                                                        }}
                                                        className={StaffingUtil.getColorClass(assignmentValue, projectionsValue, true)}
                                                        key={dataKey}
                                                        style={{
                                                            left: marginBuffer * cellWidth
                                                        }} title={StaffingUtil.getTooltip(assignmentValue, projectionsValue)}>
                                                        {StaffingUtil.getPercentage(assignmentValue, projectionsValue)}%
                                                    </span>
                                                );
                                                return output;
                                            } else {
                                                return null;
                                            }
                                        });
                                        leftOverAssignments = _.difference(leftOverAssignments, itemAssignments);
                                        return (
                                            <div
                                                onClick={(event) => {
                                                    if (!canEdit) return null;
                                                    let title = self.getAssignmentModalTitle(projection, projectionGroupsKey, filterIndexes);
                                                    let roles = self.getRoles(filteredProjections, projection[filterIndexes.activity]);
                                                    let employeeGroups = _.groupBy(EM.employees.getActive(), "RoleId");
                                                    self.setState({ roles: roles, employeeGroups: employeeGroups, detailsDialogVisible: true, projectionDetailSet: projSet, manageEmployeeModalTitle: title, projectionGroupsKey: projectionGroupsKey });
                                                }}
                                                key={pid}
                                                className={"detail-bar detail-bar-tall"}
                                                title={projectionGroupsKey}
                                                style={{
                                                    width: projection[filterIndexes.range].dates.length * cellWidth,
                                                    left: left,
                                                    clip: "rect(-5px, " + clip + "px, 40px, -5px)"
                                                }}
                                            >{hasProjections ? outputRange : <span className="under na">No assignments found.</span>}</div>
                                        )
                                    })}
                                </div>
                                {unmatchedAssignments.length > 0 ?
                                    <div className="detail-gutter">
                                        {[1].map((p, pid) => {
                                            let assignmentDateGroups = {};
                                            if (unmatchedAssignments) {
                                                unmatchedAssignments.forEach((assignment) => {
                                                    assignment.dateKeys.forEach(dateKey => {
                                                        if (!assignmentDateGroups[dateKey]) assignmentDateGroups[dateKey] = { ids: [], value: 0 };
                                                        assignmentDateGroups[dateKey].value += assignment.Value;
                                                        assignmentDateGroups[dateKey].ids.push(assignment.AssignmentId);
                                                    })
                                                });
                                            }

                                            let cellWidth = this.props.cellWidth;
                                            let outputRange = range.dates.map(function (month, dateKeyId) {
                                                let dataKey = range.beginIndex + dateKeyId;
                                                let assignmentValue = Math.fround(assignmentDateGroups[dataKey] ? assignmentDateGroups[dataKey].value : 0);
                                                let idList = assignmentDateGroups[dataKey] ? assignmentDateGroups[dataKey].ids.join(', ') : '';
                                                let left = dateKeyId * cellWidth;
                                                if (assignmentValue > 0) {
                                                    return (
                                                        <div
                                                            key={'other' + dataKey}
                                                            style={{ left: left }}
                                                            className={"detail-bar detail-bar-tall detail-bar-other"}
                                                        >
                                                            <span title={'Assignment IDs: ' + idList}>{assignmentValue.toFixed(2)}</span>
                                                        </div>
                                                    )
                                                } else {
                                                    return null;
                                                }
                                            });
                                            return outputRange;
                                        })}
                                    </div>
                                    : null}
                            </div>
                        );
                    } catch (e) {
                        console.warn(e);
                        return null;
                    }
                })}

                <div key="Other" className={"detail-row"} style={{ height: pHeight }}>
                    <div className={"detail-header"}>
                        <span className="no-title">* {EM.t('staffing.contributingToTotals')}</span>
                    </div>
                    <div className="detail-grid">
                        {[1].map((p, pid) => {
                            let assignmentDateGroups = {};
                            if (leftOverAssignments) {
                                leftOverAssignments.forEach((assignment) => {
                                    assignment.dateKeys.forEach(dateKey => {
                                        if (!assignmentDateGroups[dateKey]) assignmentDateGroups[dateKey] = { ids: [], value: 0 };
                                        assignmentDateGroups[dateKey].value += assignment.Value;
                                        assignmentDateGroups[dateKey].ids.push(assignment.AssignmentId);
                                    })
                                });
                            }

                            let cellWidth = this.props.cellWidth;
                            let outputRange = range.dates.map(function (month, dateKeyId) {
                                let dataKey = range.beginIndex + dateKeyId;
                                let assignmentValue = Math.fround(assignmentDateGroups[dataKey] ? assignmentDateGroups[dataKey].value : 0);
                                let idList = assignmentDateGroups[dataKey] ? assignmentDateGroups[dataKey].ids.join(', ') : '';
                                let left = dateKeyId * cellWidth;
                                if (assignmentValue > 0) {
                                    return (
                                        <div
                                            key={'other' + dataKey}
                                            style={{ left: left }}
                                            className={"detail-bar detail-bar-tall detail-bar-other"}
                                        >
                                            <span title={'Assignment IDs: ' + idList}>{assignmentValue.toFixed(2)}</span>
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            });
                            return outputRange;
                        })}
                    </div>
                </div>
                {this.props.isActive ?
                    <StaffingWorkItemActivityManageTasksDialog
                        roles={this.state.roles}
                        visible={this.state.detailsDialogVisible}
                        onClose={(event) => { this.setState({ detailsDialogVisible: false, roleEmployeesVisible: [] }); }}
                        employeeGroups={this.state.employeeGroups}
                        title={this.state.manageEmployeeModalTitle}
                        projectionGroupsKey={this.state.projectionGroupsKey}
                        projectionGroups={{ [this.state.projectionGroupsKey]: this.state.projectionDetailSet }}
                        filterIndexes={this.props.filterIndexes}
                        onAfterDeleteAssignment={this.props.onAfterDeleteAssignment}
                        onAfterUpdateAssignment={this.props.onAfterUpdateAssignment}
                        assignments={this.props.assignments}
                        onAddAssignment={this.props.onAddAssignment}
                    />
                    : null}
            </div>
        );
    }
}