/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';
import logo from '../images/logo.svg';
import MetaInfo from './MetaInfo';

export default function LoggingInNotice(props){  
    return (
        <div className="landing-page login-selector">
            <div>
                <div className="header">
                    <img src={logo} alt="logo" />
                </div>
                <div className="content clearfix">
                    <h5 className="mb-2">
                        Logging you in...                        
                    </h5>
                    <p>
                        If you're seeing this screen for more than a few seconds, please verify that you have "Allowed Popups" for this website in your browser's settings, 
                        and check for any windows that may have moved behind your main browser window.
                    </p>
                </div>                                
            </div>  
            <div className="footer">
                <MetaInfo />
            </div>         
        </div>
    );
}