/* global EM */
import React, { Component } from 'react';
import { withRouter } from "react-router";
import PageTitle from '../components/PageTitle';
import StaffingWorkItemPreferences from '../entities/preferences/StaffingWorkItemPreferences';
import StaffingWorkItemToolsPanel from '../components/Staffing/StaffingWorkItemToolsPanel';
import StaffingWorkItemRow from '../components/Staffing/StaffingWorkItemRow';
import StaffingTimeline from '../components/Staffing/StaffingTimeline';
import Dates from '../util/Dates';
import _ from 'underscore';
import ProjectionsManager from '../entities/ProjectionsManager';
import ReconcileDatesModal from '../components/Staffing/ReconcileDatesModal';
import IdentifyOrphansModal from '../components/Staffing/IdentifyOrphansModal';
import StaffingReportOptionsDialog from '../components/Staffing/StaffingReportOptionsDialog';
import Routes from '../app/Routes';
import WorkItemDetailsPanel from '../components/Staffing/WorkItemDetailsPanel';
import StaffingUtil from '../util/StaffingUtil';
import ResourceRequestsOptionDialog from '../components/ResourceRequests/ResourceRequestsOptionDialog';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap';

class StaffingWorkItem extends Component {
    constructor(props) {
        super(props);

        this._isMounted = false;
        this.defaultPrefs = new StaffingWorkItemPreferences('default');
        this.areToolsOpenPref = this.defaultPrefs.preferences.asFlag('tools-panel', 'open');
        this.defaultRangeInYears = 2;

        let cssVar = getComputedStyle(document.documentElement).getPropertyValue('--cell-width');
        this.cellWidth = parseInt(cssVar.trim());
        if (isNaN(this.cellWidth)) this.cellWidth = 40;

        let cssVar2 = getComputedStyle(document.documentElement).getPropertyValue('--row-height');
        this.rowHeight = parseInt(cssVar2.trim());
        if (isNaN(this.rowHeight)) this.rowHeight = 30;

        this.PM = new ProjectionsManager();
        this.projectionsFile = null;

        this.state = {
            preferences: this.defaultPrefs,
            areToolsOpen: this.areToolsOpenPref(),
            selectedItem: null,
            range: {},
            filterValues: null,
            addAssignmentsDialogOpen: false,
            showReconcileDates: false,
            showIdentifyOrphans: false,
            reportModalOpen: false,
            currentWorkItem: false,
            tasks: [],
            referenceTables: []
        }

        this.onFilterToggle = this.onFilterToggle.bind(this);
        this.onPrefSetChanged = this.onPrefSetChanged.bind(this);
        this.onAddAssignment = this.onAddAssignment.bind(this);
        this.onAfterDeleteAssignment = this.onAfterDeleteAssignment.bind(this);
        this.onAfterUpdateAssignment = this.onAfterUpdateAssignment.bind(this);
        this.onCloseReconcileDialog = this.onCloseReconcileDialog.bind(this);
        this.onCloseIdentifyOrphans = this.onCloseIdentifyOrphans.bind(this);
        this.reloadAssignments = this.reloadAssignments.bind(this);
        this.onAfterBulkUpdateAssignment = this.onAfterBulkUpdateAssignment.bind(this);
        this.onReportModalClose = this.onReportModalClose.bind(this);
        this.onGenerateReport = this.onGenerateReport.bind(this);
        this.onWorkItemOpen = this.onWorkItemOpen.bind(this);
        this.onWorkItemClose = this.onWorkItemClose.bind(this);
        this.onRequestModalClose = this.onRequestModalClose.bind(this);

        this.hasAlertedAboutTruncation = false;

    }

    componentDidMount() {
        if (!EM.hasFeature('staffing')) return EM.denyFeature();
        this._isMounted = true;
        let self = this;
        EM.loadEntities([EM.resourceRequests, EM.settings, EM.activities, EM.roles, EM.organizations, EM.departments, EM.employees, EM.assignments, EM.actuals, EM.attributes, EM.referenceTables]).then(() => {
            self.rowLimit = (EM.getTenantOption('staffingVisualizationRowLimit') || 1000);
            EM.schedules.loadDefaultItem().then((defaultSchedule) => {
                if (defaultSchedule) {
                    EM.schedules.loadFile(defaultSchedule.ScheduleId).then(() => {
                        let fileContents = EM.schedules.getFile(defaultSchedule.ScheduleId);
                        if (fileContents) {
                            let filterValues = fileContents.getFilterValues(true).filter(filter => filter.name !== 'activity');
                            self.setState({ defaultScheduleContent: fileContents, filterValues });
                        }
                    });
                    this.PM.loadDefaultProjections().then(projectionsFile => {
                        self.projectionsFile = projectionsFile;
                        self.onLoadInitial();
                    });
                } else {
                    let range = Dates.getMonthRangeFromMonthYearStrs(null, null, this.defaultRangeInYears);
                    this.setState({ range: Dates.getArrayOfMonths(range[0], range[1], true) });
                }
            });
        });

        EM.schedules.loadDefaultItem().then((defaultSchedule) => {
            if (!defaultSchedule) return;
            EM.schedules.loadFile(defaultSchedule.ScheduleId).then(() => {
                let fileContents = EM.schedules.getFile(defaultSchedule.ScheduleId);
                if (fileContents) {
                    let fileContentsObjects = fileContents.toObjectArray();
                    let wiGroups = fileContentsObjects.groupBy('Name');
                    let wiKeys = Object.keys(wiGroups).sort();
                    let addMeta = EM.getSetting('AdditionalMetadataFields');
                    if (addMeta) {
                        addMeta = addMeta.split(',');
                    }
                    let tasks = wiKeys.map(key => {
                        let group = wiGroups[key];
                        let lbl = key;

                        if (addMeta && Array.isArray(addMeta)) {
                            let meta = {};
                            group.forEach(item => {
                                addMeta.forEach(attr => {
                                    let value = item['_' + attr];
                                    if (value) {
                                        if (!meta[attr]) meta[attr] = {};
                                        meta[attr][value] = true;
                                    }
                                });
                            });
                            let metaStr = [];
                            Object.keys(meta).forEach(key => {
                                metaStr.push(key + ': ' + Object.keys(meta[key]).join(', '));
                            });
                            if (metaStr.length > 0) {
                                lbl = lbl + " " + metaStr.join(' | ');
                            }
                        }
                        return { label: lbl, value: key };
                    });

                    let addTaskList = EM.getSetting('AdditionalTasks');
                    if (addTaskList) {
                        addTaskList.split(',').map((task) => {
                            return tasks.push({ value: task.trim(), label: task.trim() });
                        });
                    }

                    this.setState({ tasks });
                }
            });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onPrefSetChanged(nextPreferences) {
        this.groupAndFilterData(nextPreferences);
    }

    onFilterToggle() {
        this.areToolsOpenPref(!this.state.areToolsOpen);
        this.setState({ areToolsOpen: !this.state.areToolsOpen });
        EM.triggerWindowResize();
    }

    onLoadInitial() {
        let self = this;
        let isWorkItemFilterEnabled = EM.getSetting("ResourceManager:WorkItems:IsFilterEnabled");
        if (isWorkItemFilterEnabled === 'true' && (EM.isStrictlyDomainSubscriber() || EM.isStrictlySuperAdmin())) {
            let state = EM.store.getState();
            let userID = state?.account?.User?.UserId;
            let sortedArray = [];
            let filteredArray = [];
            state?.referenceTables?.forEach((element) => {
                if (element.Name === 'TeamLead Workitems') {
                    sortedArray = JSON.parse(element.Value);
                }
            })
            sortedArray?.forEach((element) => {
                element?.forEach((data, index) => {
                    if (data === userID) {
                        filteredArray.push(element[0])
                    }
                })
            })
            this.setState({
                referenceTables: filteredArray.length > 0 ? filteredArray : [],
            })
        }

        if (self.projectionsFile) {
            self.projectionsFile.processDateRanges();
            self.projectionFilterIndexes = self.projectionsFile.filterIndexes;
        }

        let assignments = StaffingUtil.processAssignments(EM.assignments.get());
        self.setAssignmentState(assignments);

        this.groupAndFilterData(this.defaultPrefs);

        if (this.props.location && (this.props.location.hash || this.props.location.search)) {
            let hash, selectedWi;
            if (this.props.location.hash) {
                hash = decodeURIComponent(this.props.location.hash.slice(1));
            } else {
                let qs = Routes.parseQuery();
                selectedWi = decodeURIComponent(qs.wi);
                hash = selectedWi.toIdToken();
            }

            if (hash || selectedWi) {
                window.setTimeout(() => {
                    self.setState({ currentWorkItem: selectedWi });

                    try {
                        let element = document.querySelector('#' + CSS.escape(hash));
                        let container = document.querySelector('.staffing-container');
                        if (!element) return;
                        element.scrollIntoView();
                        container.scrollTop = container.scrollTop - 50;
                    } catch (e) {
                        console.log('Elem not found', hash);
                    }
                }, 0);
            }
        }
    }

    groupAndFilterData(nextPreferences) {
        let newState = {
            preferences: nextPreferences,
            //selectedItem: '' //Commented out so that pref changes wouldn't close details. Just trying it. -J
        };

        //let grouping = nextPreferences.grouping || '';
        if (this.projectionsFile) {
            newState.groups = this.projectionsFile.groupAndFilterProjections(nextPreferences);
        }

        let range = Dates.getMonthRangeFromMonthYearStrs(nextPreferences.begin, nextPreferences.end, this.defaultRangeInYears);
        newState.range = Dates.getArrayOfMonths(range[0], range[1], true);

        this.setState(newState);
    }

    setAssignmentState(assignments) {
        let assignmentGroups = _.groupBy(Object.values(assignments), 'WorkItemName');
        this.setState({ assignments, assignmentGroups });
    }

    onAddAssignment(assignment) {
        let self = this;
        if (assignment.WorkItemName.length > 100) {
            window.alert(EM.t('staffing.truncationWarning'));
            return;
        }

        EM.assignments.create(assignment).then((createdAssignment) => {
            let newAssignment = StaffingUtil.processSingleAssignment(createdAssignment.data);
            let assignments = Object.assign(self.state.assignments, { [newAssignment.AssignmentId]: newAssignment });
            self.setAssignmentState(assignments);
        });
    }

    onAfterDeleteAssignment(ids) {
        let self = this;
        let assignments = Object.assign(this.state.assignments);
        _.each(ids, function (deletedAssignmentId) {
            assignments = Object.assign(self.state.assignments, { [deletedAssignmentId]: null });
        });
        this.setAssignmentState(assignments);
    }

    onAfterUpdateAssignment(updatedAssignment) {
        let assignment = StaffingUtil.processSingleAssignment(updatedAssignment);
        let assignments = Object.assign(this.state.assignments, { [assignment.AssignmentId]: assignment });
        this.setAssignmentState(assignments);
    }

    onAfterBulkUpdateAssignment(updatedAssignments) {
        let assignments = Object.assign({}, this.state.assignments);
        updatedAssignments.forEach(updatedAssignment => {
            let assignment = StaffingUtil.processSingleAssignment(updatedAssignment);
            assignments[assignment.AssignmentId] = assignment;
        });
        this.setAssignmentState(assignments);
    }

    onCloseReconcileDialog(reloadAssignments) {
        let self = this;
        self.setState({ showReconcileDates: false });

        if (reloadAssignments === true) {
            self.reloadAssignments();
        }
    }

    onCloseIdentifyOrphans(reloadAssignments) {
        let self = this;
        self.setState({ showIdentifyOrphans: false });

        if (reloadAssignments === true) {
            self.reloadAssignments();
        }
    }

    reloadAssignments() {
        let self = this;
        return EM.loadEntities([EM.assignments, EM.employees], true).then(() => {
            let assignments = StaffingUtil.processAssignments(EM.assignments.get());
            self.setAssignmentState(assignments);
        });
    }

    onReportModalClose() {
        this.setState({ reportModalOpen: false });
    }
    onRequestModalClose() {
        this.setState({ requestModalOpen: false });
    }
    onWorkItemOpen(name) {
        this.setState({ currentWorkItem: name });
    }

    onWorkItemClose() {
        try {
            window.location.hash = '';
            this.props.history.push('workitem');
        } catch (e) { }

        this.setState({ currentWorkItem: null });
    }

    onGenerateReport(options) {
        let reportType = 'workitem';
        let currentPreferencesName = this.state.preferences.name;
        let reportAddress = Routes.compose(Routes.client.staffingReport, { DomainName: this.props.domain.Name }, { custom: currentPreferencesName, type: reportType });
        EM.crossPage = Object.assign({}, options, { reportType });
        this.props.history.push(reportAddress);
    }

    getWorkItemNameFromUrl() {
        const hash = window.location.hash;
        if (hash) {
            return hash.substring(1);
        }
        return null;
    }

    render() {
        const workItemFromUrl = this.getWorkItemNameFromUrl();
        let self = this;
        let showResourceRequests = EM.getSetting("ResourceRequests:Enabled")?.toLowerCase() === "true" ? true : false;
        let domain = EM.getActiveDomain();
        if (!this.state.range) return null;
        if (!this.state.range.dates) return null;
        let width = (this.cellWidth * this.state.range.dates.length) + StaffingUtil.getHeaderWidth();
        let groupKeys = this.state.groups ? Object.keys(this.state.groups).sort() : [];
        let rowCount = 0;

        let title = EM.t('header.staffing') + ': ';
        let badge = null;
        let detailMode = 'activity';
        if (this.props.match && this.props.match.params && this.props.match.params.DetailMode === 'role') {
            title += EM.t('header.staffingWorkItemRole');
            detailMode = 'role';
        } else {
            title += EM.t('header.staffingWorkItemActivity');
        }
        const account = EM.store?.getState();
        let resourceRequest = EM.isStrictlyDomainSubscriber() || EM.isStrictlySuperAdmin() ? EM.resourceRequests?.get()?.filter(x => x.RequesterAcknowledged === null && x.RequesterId == account?.account?.User?.UserId) : EM.resourceRequests?.get()?.filter(x => x.RecipientId == null || !x.RecipientId)
        return (
            <div key="contents" className={"page page-visualization container-fluid staffing " + (this.state.areToolsOpen ? 'tools-mode' : '')}>
                <PageTitle title={<span>{title}{badge}</span>} icon={detailMode === 'role' ? 'fa-duotone fa-users' : 'fa-duotone fa-chart-gantt'} bar={true}>
                    {showResourceRequests && (EM.isStrictlyDomainSubscriber() || EM.isStrictlySuperAdmin()) ?
                        <div className="btn-group">
                            {EM.isStrictlySuperAdmin() ?
                                <div className='icon-container'>
                                    <button className="btn btn-sm btn-primary"
                                        onClick={() => { this.props.history.push(Routes.compose(Routes.client.requestQueue, { DomainName: domain.Name })) }}>
                                        {EM.t('staffing.resourceRequests.view')}
                                    </button>
                                    {resourceRequest && resourceRequest.length ? <p className='notification-badge1'><span>{resourceRequest.length}</span>  </p> : null}
                                </div>
                                : null}
                            <UncontrolledButtonDropdown
                                group
                            >
                                <button className="btn btn-sm btn-primary"
                                    style={{ borderRight: "2px solid white" }}
                                    onClick={() => { this.setState({ requestModalOpen: true }); }}>
                                    {EM.t('staffing.resourceRequests.submit')}
                                </button>
                                <DropdownToggle
                                    size="sm"
                                    style={{
                                        borderBottomRightRadius: "10%",
                                        borderTopRightRadius: "10%"
                                    }}
                                    color='primary'>
                                    <div className='icon-container'>
                                        <i className="fas fa-caret-down" id='fasCaret'></i>
                                        {resourceRequest.length ? <p className='notification-badge2'><span>{resourceRequest ? resourceRequest.length : ''}</span> </p> : null}
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem className="text-muted"
                                        onClick={() => this.props.history.push(Routes.compose(Routes.client.requestQueue, { DomainName: domain.Name }))}
                                    >
                                        {EM.t('staffing.resourceRequests.view')}
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>

                        </div> : showResourceRequests && EM.isStrictlyDomainAdmin() ?
                            <div className='icon-container'>
                                <button className="btn btn-sm btn-primary"
                                    onClick={() => { this.props.history.push(Routes.compose(Routes.client.requestQueue, { DomainName: domain.Name })) }}>
                                    {EM.t('staffing.resourceRequests.view')}
                                </button>
                                {resourceRequest && resourceRequest.length ? <p className='notification-badge1'><span>{resourceRequest.length}</span>  </p> : null}
                            </div> : null}
                    <div className="btn-group">
                        <button className="btn btn-sm btn-success" onClick={() => { this.setState({ reportModalOpen: true }); }}>
                            {EM.t('staffing.generateReport')}
                        </button>
                    </div>
                    {EM.isDomainAdmin() && !EM.getSetting('HideReconcileSchedule') ?
                        <div className="btn-group">
                            <button className="btn btn-sm btn-secondary"
                                title="Match assignment dates to default schedule dates"
                                onClick={() => { window.setTimeout(() => { this.setState({ showReconcileDates: true }) }, 0); }}
                            >
                                {EM.t('staffing.reconcile')}
                            </button>
                            <button className="btn btn-sm btn-secondary beta"
                                title="Free up staff assigned to work that no longer exists in the default schedule"
                                onClick={() => { window.setTimeout(() => { this.setState({ showIdentifyOrphans: true }) }, 0); }}
                            >
                                {EM.t('staffing.orphans')}
                            </button>
                        </div>
                        : null}
                    <button className="btn btn-default btn-tools" onClick={this.onFilterToggle}>
                        <i className="fas fa-bars"></i>
                    </button>
                </PageTitle>
                <div className="staffing-container">
                    <div className="staffing-container-content" style={{ width: width }}>
                        <StaffingTimeline range={self.state.range} />
                        {groupKeys.length === 0 ?
                            <div className="group error">
                                <div className="group-header">
                                    <span>{EM.t('util.nodata')}</span>
                                </div>
                            </div>
                            : null}
                        {groupKeys.map((groupKey, groupIndex) => {
                            let group = self.state.groups[groupKey.trim()];
                            if (!group) {
                                return null;
                            }
                            let subKeys = Object.keys(group).sort();
                            if (EM.isStrictlyDomainSubscriber() && this.state.referenceTables.length > 0) {
                                subKeys = subKeys.filter(item => this.state.referenceTables.includes(item));
                            }
                            if (rowCount > this.rowLimit) return null;
                            return (
                                <div className="group" key={groupKey}>
                                    {groupKey === 'undefined' ?
                                        null
                                        :
                                        <div className="group-header">
                                            <span>{groupKey || '(Blank)'}</span>
                                        </div>
                                    }
                                    {subKeys.map((subKey, subKeyIndex) => {
                                        rowCount++;
                                        if (rowCount > this.rowLimit) return null;
                                        return (
                                            <StaffingWorkItemRow
                                                workItemFromUrl={workItemFromUrl}
                                                rowHeight={this.rowHeight}
                                                cellWidth={this.cellWidth}
                                                key={subKey}
                                                detailMode={detailMode}
                                                name={subKey}
                                                tasks={this.state.tasks.filter(x => x.value == subKey)[0]}
                                                range={self.state.range}
                                                projections={group[subKey]}
                                                assignments={self.state.assignmentGroups[subKey]}
                                                isActive={self.state.selectedItem === subKey}
                                                maxWidth={width - StaffingUtil.getHeaderWidth()}
                                                filterIndexes={self.projectionFilterIndexes}
                                                onlyActive={this.state.preferences.onlyActive}
                                                onAddAssignment={self.onAddAssignment}
                                                onAfterDeleteAssignment={self.onAfterDeleteAssignment}
                                                onAfterUpdateAssignment={self.onAfterUpdateAssignment}
                                                onWorkItemOpen={this.onWorkItemOpen}
                                                focusRoles={this.state.preferences.focusRoles}
                                                onClick={() => {
                                                    let newSelection = self.state.selectedItem === subKey ? null : subKey;
                                                    self.setState({ selectedItem: newSelection });
                                                }}
                                            />
                                        );
                                    })}
                                </div>
                            )
                        })}
                        {rowCount > this.rowLimit ?
                            <div>
                                <div className="m-3 alert alert-secondary">{EM.t('staffing.rowLimitExceeded')}</div>
                            </div>
                            : null}
                    </div>
                    <div className={"staffing-container-overlay " + (this.state.selectedItem ? 'active' : '')}
                        onClick={() => {
                            this.setState({ selectedItem: null });
                        }}
                    />
                </div>
                <StaffingWorkItemToolsPanel
                    onFilterClose={this.onFilterToggle}
                    onChange={this.onPrefSetChanged}
                    filterValues={this.state.filterValues}
                />

                {this.state.showReconcileDates ?
                    <ReconcileDatesModal
                        defaultScheduleContent={this.state.defaultScheduleContent}
                        assignments={this.state.assignments}
                        schedule={this.state.defaultSchedule}
                        employees={EM.employees.get()}
                        domain={EM.getActiveDomain()}
                        close={this.onCloseReconcileDialog}
                        isShowing={this.state.showReconcileDates}
                        reloadAssignments={this.reloadAssignments}
                    />
                    : null}
                {this.state.showIdentifyOrphans ?
                    <IdentifyOrphansModal
                        defaultScheduleContent={this.state.defaultScheduleContent}
                        assignments={this.state.assignments}
                        schedule={this.state.defaultSchedule}
                        employees={EM.employees.get()}
                        domain={EM.getActiveDomain()}
                        close={this.onCloseIdentifyOrphans}
                        isShowing={this.state.showIdentifyOrphans}
                        reloadAssignments={this.reloadAssignments}
                    />
                    : null}
                <StaffingReportOptionsDialog
                    onClose={this.onReportModalClose}
                    isOpen={this.state.reportModalOpen}
                    onGenerateReport={this.onGenerateReport}
                />
                {EM.isStrictlyDomainSubscriber() || EM.isStrictlySuperAdmin() ? 
                    <ResourceRequestsOptionDialog
                        onClose={this.onRequestModalClose}
                        isOpen={this.state.requestModalOpen}
                        workItems={this.state.tasks}
                /> : null}
                <WorkItemDetailsPanel
                    rowHeight={this.rowHeight}
                    cellWidth={this.cellWidth}
                    onClose={this.onWorkItemClose}
                    isOpen={!!this.state.currentWorkItem}
                    workItem={this.state.currentWorkItem}
                    filterIndexes={self.projectionFilterIndexes}
                    projections={this.state.groups}
                    assignments={this.state.assignmentGroups}
                    onSingleUpdate={this.onAfterUpdateAssignment}
                    onBulkUpdate={this.onAfterBulkUpdateAssignment}
                />

            </div>
        );
    }
}

export default withRouter(StaffingWorkItem);
