/* global EM */
import Entity from './Entity';
import _ from 'underscore';

export default class ConfigurationsEntity extends Entity{
    getPipelines(){
        return this.getByType(EM.configurationTypeLookup.lookupId('PipelineConfig'));
    }

    getSuperPipelines(){
        return this.getByType(EM.configurationTypeLookup.lookupId('SuperPipelineConfig'));
    }

    getPipelinesAll(){
        return this.get().filter((config) => {
            return config.ConfigurationTypeLookupId === EM.configurationTypeLookup.lookupId('PipelineConfig') || config.ConfigurationTypeLookupId === EM.configurationTypeLookup.lookupId('SuperPipelineConfig');
        }).reverse();
    }

    getPipelinesIndexed(){
        return _.indexBy(this.getByType(EM.configurationTypeLookup.lookupId('PipelineConfig')), 'Name');
    }

    getByType(typeId){
        return this.get().filter((config) => {
            return config.ConfigurationTypeLookupId === typeId;
        }).reverse();
    }
}