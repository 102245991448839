import Entity from './Entity';
import ColorManager from '../util/ColorManager';

export default class ActivitiesEntity extends Entity{
    getColor(activityName, defaultColor){
        if (!this.colorIndex){
            this.colorManager = new ColorManager();
            this.colorIndex = {};
            this.get().forEach(activity => {
                this.colorIndex[activity.Name] = this.colorManager.next();
            });
        }

        let color = this.colorIndex[activityName];
        return color || defaultColor;
    }
}