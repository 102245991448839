/* global EM */
import FileTypeBase from './FileTypeBase';
import Dates from '../../util/Dates';
import _ from 'underscore';
import xml2js from 'xml2js';

export default class ScheduleFile extends FileTypeBase {
    constructor(data, entityName, id) {
        super(data, entityName, id);

        this.indexes = ScheduleFile.getIndexes(data);

        this.cellFormatter = this.cellFormatter.bind(this);
    }

    static get allowsXml() { return true; }

    static async validate(csvContents) {
        await EM.activities.load();

        let indexes = ScheduleFile.getIndexes(csvContents);

        let errors = [];

        let limitSetting = parseInt(EM.getTenantOption('scheduleLimit'));
        let importLimit = isNaN(limitSetting) ? 35000 : limitSetting;
        if (csvContents.length > (importLimit + 1)){
            errors.push([(0 + ':' + 0), 'File contains too many rows. Maximum rows is ' + importLimit + '.']);
            return errors;            
        }        

        let header = csvContents[0];
        if (header.length > 256){
            errors.push([(0 + ':' + 0), 'File contains too many columns. Maximum columns is 256.']);
            return errors;
        }

        let badCols = ['Role', 'Value', 'MID', 'CID', 'MM'];
        let badCol = header.find(col => {
            return badCols.find(bc => bc === col);
        });
        if (badCol){
            errors.push([(0 + ':' + 0), 'File appears to be a projections file.']);
            return errors;
        }

        csvContents.forEach((row, rowIndex) => {
            if (rowIndex === 0) return;

            if (!row[indexes.workitem]) {
                errors.push([(rowIndex + ':' + indexes.workitem), 'Work Item is required.']);
            }

            //Check that activity is in the list
            let actFound = EM.activities.lookupId(row[indexes.activity], 'Name');
            if (!actFound) {
                errors.push([(rowIndex + ':' + indexes.activity), 'Activity not found.']);
            }

            //Check begin/end for valid format
            if (!row[indexes.begin] || !Dates.isValidDateStr(row[indexes.begin])) {
                errors.push([(rowIndex + ':' + indexes.begin), 'Invalid date format. Must be in format m/d/yyyy.']);
            }
            if (!row[indexes.end] || !Dates.isValidDateStr(row[indexes.end])) {
                errors.push([(rowIndex + ':' + indexes.end), 'Invalid date format. Must be in format m/d/yyyy.']);
            }
        });

        return errors;
    }

    static preSaveTransform(csvContents) {
        let indexes = ScheduleFile.getIndexes(csvContents);
        csvContents.forEach((row, rowIndex) => {
            if (rowIndex === 0) return;
            row[indexes.begin] = Dates.fromStr(row[indexes.begin]).toISO();
            row[indexes.end] = Dates.fromStr(row[indexes.end]).toISO();
        });

        return csvContents;
    }

    static preExportTransform(csvContents) {
        let indexes = ScheduleFile.getIndexes(csvContents);
        let output = csvContents.map((row, rowIndex) => {
            let copy = row.slice(0);
            if (rowIndex === 0) return copy
            copy[indexes.begin] = Dates.fromISO(row[indexes.begin]).toLocaleString();
            copy[indexes.end] = Dates.fromISO(row[indexes.end]).toLocaleString();
            return copy;
        });

        return output;
    }

    toObjectArray(processingCallback) {
        let results = [];
        this.data.forEach((row, index) => {
            if (index === 0) return;
            var act = EM.activities.findByKey(row[this.indexes.activity]);
            if (!act) return;

            let tmp = {
                Name: row[this.indexes.workitem],
                Activity: act.Name,
                ActivityId: act.ActivityId,
                Begin: row[this.indexes.begin],
                End: row[this.indexes.end]
            };

            Object.keys(this.indexes.attributes).forEach(attrKey => {
                tmp['_' + attrKey] = row[this.indexes.attributes[attrKey]];
            });

            if (processingCallback) {
                tmp = processingCallback(tmp, index);
            }

            if (tmp) results.push(tmp);
        });

        return results;
    }

    static getIndexes(csvContents) {    
        let indexes = {
            workitem: -1,
            activity: -1,
            begin: -1,
            end: -1            
        };

        let attributes = [];

        //Try to infer indexes from header titles first
        let header = csvContents[0];
        header.forEach((columnTitle, ci) => {
            if (!columnTitle)return;
            if (!columnTitle) return;
            let titleClean = columnTitle.toLowerCase().replace(/\s/g, '');
            if (titleClean === 'workitem') {
                indexes.workitem = ci;
            } else if (titleClean === 'activity') {
                indexes.activity = ci;
            } else if (titleClean === 'begin') {
                indexes.begin = ci;
            } else if (titleClean === 'end') {
                indexes.end = ci;
            } else {
                attributes.push([columnTitle, ci]);
            }
        });

        let defaultIndexes = {
            workitem: 0,
            activity: 1,
            begin: 2,
            end: 3
        }

        Object.keys(indexes).forEach((key) => {
            if (key === 'attributes') return;
            if (indexes[key] === -1) indexes[key] = defaultIndexes[key];
        });

        //Get default from 
        let settings = EM.settings.asKeyed();
        indexes.workitem = parseInt(settings.WorkItemIndex ? settings.WorkItemIndex.Value : indexes.workitem);
        indexes.activity = parseInt(settings.ActivityIndex ? settings.ActivityIndex.Value : indexes.activity);
        indexes.begin = parseInt(settings.BeginIndex ? settings.BeginIndex.Value : indexes.begin);
        indexes.end = parseInt(settings.EndIndex ? settings.EndIndex.Value : indexes.end);

        //Finally, make sure that any attribute values read in are not also "known" columns
        let knownCols = Object.values(indexes);
        attributes = attributes.filter(attr => {
            return knownCols.indexOf(attr[1]) === -1;
        });

        indexes.attributes = _.object(attributes);
        return indexes;
    }

    getFilterValues(trimForDisplay) {
        let filters = [];
        let indexes = this.indexes;
        let hiddenColumns = [];
        if (trimForDisplay) {
            let hiddenColumnSetting = EM.getSetting('HiddenColumns');
            if (hiddenColumnSetting) {
                hiddenColumnSetting.split(',').map((col) => {
                    return hiddenColumns.push(col.trim());
                })
            }
        }

        let transposed = _.unzip(this.data);
        transposed.forEach((columnSet, columnIndex) => {
            if (!columnSet[0])return;
            if (columnIndex === indexes.begin || columnIndex === indexes.end) return;

            if (trimForDisplay) {
                if (hiddenColumns.indexOf(columnSet[0]) > -1) return;
            }

            let filterMeta = {
                name: columnSet[0],
                isCore: false,
                label: columnSet[0],
                values: _.uniq(columnSet.slice(1).sort(), true)
            };

            if (columnIndex === indexes.workitem) {
                filterMeta.name = 'workitem';
                filterMeta.isCore = true;
            } else if (columnIndex === indexes.activity) {
                filterMeta.name = 'activity';
                filterMeta.isCore = true;
            }

            filters.push(filterMeta);
        });

        filters = _.sortBy(filters, (filter) => {
            return (filter.isCore ? 'a' : 'z') + (filter.name === 'workitem' ? 'a' : 'z') + filter.name;
        });
        
        return filters;
    }

    cellFormatter(row, col, value) {
        if (row > 0 && (col === this.indexes.begin || col === this.indexes.end)) {
            return Dates.fromISO(value).toLocaleString();
        }

        return value;
    }

    static async readXml(xml) {
        var parser = new xml2js.Parser({explicitArray : false, mergeAttrs : true});
        let results = await parser.parseStringPromise(xml);
        if (!results || !results.Project || !results.Project.Tasks)return null;

        let tasks = [];
        Object.keys(results.Project.Tasks).forEach(taskKey => {
            Array.prototype.push.apply(tasks, results.Project.Tasks[taskKey]);
        });

        let attributes = [];      
        Object.keys(results.Project.ExtendedAttributes).forEach(exAttr => {
            Array.prototype.push.apply(attributes, results.Project.ExtendedAttributes[exAttr]);
        });

        let header = ['Work Item', 'Activity', 'Begin', 'End'];
        let additionalColumns = [];
        let knownColumns = {};
        attributes.forEach(attr => {
            if (!attr.Alias)return;
            if (attr.Alias === 'Work Item Name' || attr.Alias === 'Activity Name'){
                knownColumns[attr.Alias] = attr.FieldID;
                return;
            }
            header.push(attr.Alias);
            additionalColumns.push(attr);
        });

        let wiNameFldId = knownColumns['Work Item Name'];
        let actNameFldId = knownColumns['Activity Name']

        let wis = {};
        let output = [ header ];
        tasks.forEach(task => {
            if (task.OutlineLevel === 0)return;
            if (task.OutlineLevel === '1'){
                wis[task.OutlineNumber] = task;
                return;
            }
            let outline = task.OutlineNumber.split('.');
            if (outline.length < 2)return;

            let wi = wis[outline[0]];
            if (!wi)return;

            let extAttrsGroups = task.ExtendedAttribute;
            if (extAttrsGroups && !_.isArray(extAttrsGroups))extAttrsGroups = [ extAttrsGroups ];
            let taskExtendedAttrs = extAttrsGroups ? _.indexBy(extAttrsGroups, 'FieldID') : {};
                        
            let wiName = (wiNameFldId && taskExtendedAttrs[wiNameFldId] ? taskExtendedAttrs[wiNameFldId].Value : wi.Name) || wi.Name;
            let actName = (actNameFldId && taskExtendedAttrs[actNameFldId] ? taskExtendedAttrs[actNameFldId].Value : task.Name) || task.Name;

            let start = task.Start || task.ManualStart;
            let startObj = Dates.fromISO(start);

            let end = task.Finish || task.ManualFinish;
            let endObj = Dates.fromISO(end);

            if (startObj.isInvalid || endObj.isInvalid)return;
        
            let row = [
                wiName,
                actName,
                startObj.toLocaleString(),
                endObj.toLocaleString()
            ];
            
            additionalColumns.forEach(ext => {
                let id = ext.FieldID;
                if (taskExtendedAttrs[id]){
                    row.push(taskExtendedAttrs[id].Value);
                }else{
                    row.push(null);
                }
            });

            output.push(row);
        });
    
        return output;
    }
}