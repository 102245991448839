export default {
    demand: {
        demandVsHeadcount: {
            config: {
                "hidden": true,
                "icon": "far fa-address-card",
                base: {
                    "focusMode": true,
                    "primary": { "dataset": { id: "default", group: "schedules", label: "Demand" }, chartType: "Area" },
                    "secondary": { "dataset": { id: "default", group: "headcounts", label: "Headcount" }, chartType: "Line" },
                },
                options: {
                    tableType: "primarydifference"
                }
            },
            monthly: {
                "dateGrouping": "Month",
            },
            quarterly: {
                "dateGrouping": "Quarter",
            },
            yearly: {
                "dateGrouping": "Year",
            }
        },
        /*demandVsActuals: {
            config: {
                "hidden": true,
                "icon": "fas fa-id-badge",
                base: {
                    "focusMode": true,
                    "primary": { "dataset": { id: "default", group: "schedules", label: "Demand" }, chartType: "Area" },
                    "secondary": { "dataset": { id: "default", group: "actuals", label: "Actual" }, chartType: "Line" },
                },
                options: {
                    tableType: "primarydifference"
                }
            },
            monthly: {
                "dateGrouping": "Month",
            },
            quarterly: {
                "dateGrouping": "Quarter",
            },
            yearly: {
                "dateGrouping": "Year",
            }
        },*/
        headcount: {
            config: {
                "hidden": true,
                "icon": "fas fa-users",
                base: {
                    "focusMode": true,
                    "dateGrouping": "Year",
                    "primary": { "dataset": { id: "default", group: "headcounts", label: "Headcount" }, chartType: "Area" },
                    "secondary": { "dataset": null, chartType: "Line" }
                },
                options: {
                    tableType: "listing"
                }
            },
            monthly: {
                "dateGrouping": "Month",
            },
            quarterly: {
                "dateGrouping": "Quarter",
            },
            yearly: {
                "dateGrouping": "Year",
            }
        }
    },
    assignments: {

    }
}