/* global EM */
import React, { Component } from 'react';
import SettingsTabs from '../components/SettingsTabs';
import EntityTable from '../components/EntityTables/EntityTable';
import { Validators } from '../util/EntityValidators';

export default class Security extends Component {
    constructor(props) {
        super(props);

        EM.permissions.load();
        EM.permissionLookup.load();
        EM.users.load();
    }

    render() {
        let data = null;
        let columns = null;
        const entity = EM.permissions;

        if (EM.allLoaded(entity, EM.permissionLookup, EM.users)) {
            data = entity.get();
            columns = [{                
                dataField: 'UserId',
                text: entity.columns('user'),
                asUserName: true,
                excludeByFields: ["Active"],
                editable: false,
                validators: [ Validators.required ]
            },{
                dataField: 'PermissionLookupId',
                text: entity.columns('permissions'),
                asPermission: true,
                editable: false,
                validators: [ Validators.required ]
            }];
        }

        return (
            <div key="contents" className="page container-fluid">
                <SettingsTabs {...this.props} />
                <EntityTable 
                    entity={entity} 
                    data={data} 
                    columns={columns} 
                    hideImport={EM.isSuperAdmin()?false:true} 
                    hideClearAll={true} 
                    className={"table-overflow-container tabs"}
                />
            </div>
        );
    }
}