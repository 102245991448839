/* global EM */
import React, { Component } from 'react';
import EntityTable from '../components/EntityTables/EntityTable';
import { Validators } from '../util/EntityValidators';

class Allocations extends Component {
    constructor(props){
        super(props);

        EM.allocations.load();
        EM.activities.load();
        EM.roles.load();
        EM.organizations.load();
        EM.departments.load();
        EM.resourceRequests.load();
    } 

    render() {
        let data = null;
        let columns = null;
        const entity = EM.allocations;

        if (EM.allLoaded(entity, EM.activities, EM.roles, EM.organizations, EM.departments)){    
            let orgLookup = (cell, row) => {
                let deptId = EM.roles.lookupValue(row.RoleId, 'DepartmentId')
                let orgId = EM.departments.lookupValue(deptId, 'OrganizationId');                        
                return EM.organizations.lookupValue(orgId);     
            };
            let deptLookup = (cell, row) => {
                let deptId = EM.roles.lookupValue(row.RoleId, 'DepartmentId')
                return EM.departments.lookupValue(deptId);                          
            };

            data = entity.get();
            columns = [
                {
                    dataField: 'organization',
                    text: entity.columns('organization'),
                    isReference: true,
                    width: 150,
                    formatter: orgLookup,
                    sortValue: orgLookup,
                    filterValue: orgLookup,
                    filterOptions: {
                        dynamicEntity: EM.organizations
                    }
                },{
                    dataField: 'department',
                    text: entity.columns('department'),
                    isReference: true,
                    width: 150,
                    formatter: deptLookup,
                    sortValue: deptLookup,
                    filterValue: deptLookup,
                    filterOptions: {
                        dynamicEntity: EM.departments
                    }
                },{                
                    dataField: 'RoleId',
                    text: entity.columns('role'),
                    width: 150,
                    fromEntity: EM.roles,
                    validators: [ Validators.required ]
                },{
                    dataField: 'ActivityId',
                    text: entity.columns('activity'),
                    width: 150,
                    fromEntity: EM.activities,
                    validators: [ Validators.required ]
                },{
                    dataField: 'Low',
                    text: entity.columns('low'),  
                    width: 75,
                    validators: [ Validators.requiredNumber, Validators.numberOnly ],
                    filterOptions: {
                        number: true
                    }
                }, {
                    dataField: 'Medium',
                    text: entity.columns('medium'),
                    width: 75,
                    validators: [ Validators.requiredNumber, Validators.numberOnly ],
                    filterOptions: {
                        number: true
                    }
                }, {
                    dataField: 'High',
                    text: entity.columns('high'),
                    width: 75,
                    validators: [ Validators.requiredNumber, Validators.numberOnly ],
                    filterOptions: {
                        number: true
                    }
                }, {
                    dataField: 'Active',
                    text: entity.columns('active'),
                    asActive: true,
                    width: 75
                }
            ];
        }

        return (
            <div key="contents" className="page container-fluid">
                <EntityTable entity={entity} data={data} columns={columns} className="table-overflow-container"/>
            </div>
        );
    }
}

export default Allocations;