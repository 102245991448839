/* global EM */
import React, { Component } from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import PanelModal from '../PanelModal';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { CSVExport  } from 'react-bootstrap-table2-toolkit';
import Dates from '../../util/Dates';
import ColumnMapper from '../ColumnMapper';

const { ExportCSVButton } = CSVExport;

export default class DemandModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: {}
        };

        this.onClose = this.onClose.bind(this);

        this.reserve = null;
        this.groupedData = null;    

        this.columns = ColumnMapper([{
            dataField: 'workitem',
            text: EM.t('demand.modal.workitem'),
            sort: true,
            width: 200,
            formatter: (cell) => {
                return <span title={cell}>{cell}</span>
            }
        },{
            dataField: 'activity',
            text: EM.t('demand.modal.activity'),
            sort: true,
            width: 150,
            formatter: (cell) => {
                return <span title={cell}>{cell}</span>
            }
        },{
            dataField: 'complexity',
            text: EM.t('demand.modal.complexity'),
            sort: true,
            width: 85
        },{
            dataField: 'begin',
            text: EM.t('demand.modal.begin'),
            sort: true,
            width: 85
        },{
            dataField: 'end',
            text: EM.t('demand.modal.end'),
            sort: true,
            width: 85
        },{
            dataField: 'value',
            text: EM.t('demand.modal.value'),
            sort: true,
            width: 75,
            formatter: (cell) => {
                return cell.toFixed(2);
            }
        }]);

        if (EM.debugMode){
            Array.prototype.push.apply(this.columns, ColumnMapper([{
                dataField: 'cid',
                text: EM.t('demand.modal.cid'),
                headerClasses: 'column-reference',
                classes: 'column-reference',
                sort: true,
                width: 50
            },{
                dataField: 'mid',
                text: EM.t('demand.modal.mid'),
                headerClasses: 'column-reference',
                classes: 'column-reference',
                sort: true,
                width: 50
            },{
                dataField: 'mm',
                text: EM.t('demand.modal.error'),
                headerClasses: 'column-reference',
                classes: 'column-reference',
                sort: true,
                width: 50,
                formatter: (cell) => {
                    return cell ? <span className="badge badge-danger" title={cell}>Error</span> : ''
                }
            }]));
        }

        this.hcColumns = [{
            dataField: 'value',
            text: EM.t('demand.modal.value'),
            sort: true,
            formatter: (cell) => {
                return cell.toFixed(2);
            }
        }];
    }

    onClose(){
        this.props.onClose();
        this.setState({ open: {} });
    }

    toggle(key){
        let newOpt = this.state.open[key] === true ? false : true;
        let newOpenState = Object.assign({}, this.state);
        newOpenState.open[key] = newOpt;
        this.setState(newOpenState);
    }

    render() {
        let data = this.props.data;
        if (!data)return null;
        
        let stack = data.series.stack;
        let dataset = this.props[stack];        

        this.groupedData = dataset.getDetail(data.point.x, data.series.name, this.props.preferences, stack);

        let month = Dates.fromMs(data.point.x);
        let keys = this.groupedData ? Object.keys(this.groupedData).sort() : [];
        let dgMonthly = this.props.preferences.dateGrouping === 'Month' ? true : false;

        return (
            <PanelModal ref={el => (this.componentRef = el)} fade={false} isOpen={data?true:false} toggle={this.onClose} className={'demand-modal panel ' + this.props.className} key="demand-modal">
                <ModalHeader toggle={this.onClose}>
                    {data.series.name}: {Dates.toGroupedStr(month, this.props.preferences.dateGrouping)}
                </ModalHeader>
                <ModalBody>
                    {keys.map((key, keyIndex) => {
                        let roleData = this.groupedData[key];
                        let total = roleData.reduce((memo, item) => {
                            return memo + item.value;
                        }, 0);
                        return (
                            <div className="collapse-section" key={key}>
                                <h6 className="alert alert-secondary" onClick={() => this.toggle(key)}>
                                    {key}
                                    {data.point.y ? 
                                        <span>{!dgMonthly?'*':''}{EM.t('demand.modal.total')}: {total.toFixed(2)}</span>
                                    : null}
                                </h6>
                                <Collapse isOpen={this.state.open[key] || keys.length === 1}>
                                    <ToolkitProvider
                                        keyField={'id'}
                                        data={roleData}
                                        columns={dataset.entityName === 'headcounts' ? this.hcColumns : this.columns}
                                        exportCSV={{
                                            fileName: key + '.csv'
                                        }}
                                        search={{
                                            searchFormatted: true
                                        }}
                                    >{props => (
                                        <div>
                                            <div className="collapse-section-toolbar clearfix">
                                                <ExportCSVButton className="float-right btn btn-secondary btn-sm" title={EM.t('util.table.exportRecords')} {...props.csvProps}>
                                                    <i className="fas fa-download"></i>{EM.t('util.table.exportRecords')}
                                                </ExportCSVButton>
                                            </div>
                                            <BootstrapTable   
                                                {...props.baseProps}                                                                         
                                                classes="table table-bordered table-striped table-xs"         
                                            />
                                        </div>
                                    )}
                                    </ToolkitProvider>
                                </Collapse>
                            </div>
                        )
                    })}
                    {!dgMonthly?
                        <div className="alert alert-secondary font-sm mt-4">
                            <i className="fas fa-exclamation-circle">&nbsp;</i>{EM.t('demand.modal.avgWarning')}
                        </div>
                    : null }                    
                </ModalBody>
                <ModalFooter>                
                    <Button color="secondary" onClick={this.onClose}>{EM.t('util.closeButton')}</Button>
                </ModalFooter>
            </PanelModal>
        );
    }
}