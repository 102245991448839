/* global EM */
import React, { Component } from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PanelModal from '../PanelModal';
import BootstrapTable from 'react-bootstrap-table-next';
import ColumnMapper from '../ColumnMapper';
import _ from 'underscore';
import NoTableData from './NoTableData';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { CSVExport  } from 'react-bootstrap-table2-toolkit';
import Dates from '../../util/Dates';

const { ExportCSVButton } = CSVExport;

export default class EntityAuditTrail extends Component {
    constructor(props) {        
        super(props);

        this.state = {
            data: null,
            keys: null
        };

        this.onClose = this.onClose.bind(this);
        this.entityTitle = this.props.entity.t('title');
        this.entityId = this.props.entityId;
        this.key = this.entityTitle + this.entityId;   
        
        if (EM.isDomainAdmin())EM.users.load();
    }

    onClose() {
        this.props.onClose();
    }

    componentDidUpdate(prevProps) {
        let self = this;
        if (this.props.id !== prevProps.id) {
            if (this.props.id === null){
                self.setState({ data: null, keys: null });
                return;
            }
            this.props.entity.audit(this.props.id).then(data => {
                let keys = [];
                if (!data){
                    self.setState({ data: [], keys });
                    return; 
                };
                let dataParsed = data.map(item => {    
                    let parsedValue = (JSON.parse(item.Value || '{}') || {});
                    let parsedOriginalValue = (JSON.parse(item.OriginalValue || '{}') || {});

                    let action = (function(cell){ switch(cell){
                        case 'PUT': return 'Updated';
                        case 'IMPORT-PUT': return 'Updated';
                        case 'POST': return 'Created';
                        case 'DELETE': return 'Deleted';
                        case 'BULK CREATE': return 'Bulk-Create';
                        default: return cell;
                    }})(item.ActionType);

                    let newObject = {};
                    if (action === 'Deleted'){
                        newObject = Object.assign({}, { _RowKey: item.RowKey, _Timestamp: Dates.fromISO(item.Timestamp), _UserId: item.UserId, _ActionType: action, _Values: parsedValue, _originalValues: parsedOriginalValue });
                    }else if (action === 'Bulk-Create'){
                        newObject = Object.assign({}, { _RowKey: item.RowKey, _Timestamp: Dates.fromISO(item.Timestamp), _UserId: item.UserId, _ActionType: action, _Values: parsedValue, _originalValues: parsedOriginalValue });
                    }else{
                        newObject = Object.assign({}, parsedValue, { _RowKey: item.RowKey, _Timestamp: Dates.fromISO(item.Timestamp), _UserId: item.UserId, _ActionType: action, _originalValues: parsedOriginalValue });
                    }
                    return newObject;
                }).sort((a, b) => {
                    return b._Timestamp.toMillis() - a._Timestamp.toMillis();
                });

                keys = _.difference(_.uniq(keys), [this.props.entity.idField, 'DomainId', 'CreatedBy', 'CreatedOn', 'ModifiedBy', 'ModifiedOn']).sort();
                self.setState({ data: dataParsed, keys });
            });
        }
    }

    render() {
        let auditDays = parseInt(EM.getSetting('AuditDays') || 30);
        if (auditDays !== 30 && auditDays !== 60 && auditDays !== 90){
            auditDays = 30;
        }

        let columns = ColumnMapper([
            {
                dataField: '_Timestamp',
                text: EM.t('util.table.auditTime'),
                isDateReference: true,
                width: 175,
                sort: false,
                csvExport: true
            },
            {
                dataField: '_UserId',
                text: EM.t('util.table.auditUser'),
                asUserIcon: true,
                width: 40,
                sort: false
            },
            {
                dataField: '_ActionType',
                text: EM.t('util.table.auditAction'),
                width: 75,
                sort: false,
                classes: 'action-type'
            }
        ]);
        
        this.props.entityColumns.forEach((col) => {
            if (col.asFunctions || col.isDateReference || col.isReference || col.isMeta)return;
            if (this.props.id !== '*' && col.text === EM.t('util.table.idColumn'))return;
            
            let newCol = Object.assign({}, col, { formatter: null, sort: false, filter: null });
            newCol.csvExport = true;

            if (col.formatter){
                newCol.subFormatter = col.formatter;
            }            

            newCol.formatter = (cell, row, index, formatExtraData) => {
                let value = cell;
                let formattedValue = value;

                let cls = 'change-gram ';
                if (typeof value === 'undefined' || value === null || value === '')value = '<blank>';

                let previousValue = row._originalValues[newCol.dataField];
                let previousFormattedValue = previousValue;
                if (typeof previousValue === 'undefined' || previousValue === null || previousValue === '')previousValue = '<blank>';                            

                if (newCol.subFormatter && row._ActionType !== 'Deleted'){
                    try{
                        formattedValue = newCol.subFormatter(cell, row, index);
                    }catch(e){}
                    try{
                        previousFormattedValue = newCol.subFormatter(previousValue||'', row._originalValues, index);                    
                    }catch(e){}
                }            
                if (typeof previousFormattedValue === 'undefined' || previousFormattedValue === null || previousFormattedValue === '' || previousFormattedValue === 'undefined')previousFormattedValue = '<blank>';                            
                let tip = 'Unchanged';

                if (row._ActionType === 'Created'){
                    cls += 'Created';
                    tip = 'New';
                }

                if (row._ActionType === 'Bulk-Create'){
                    cls += 'Bulk-Create';
                    tip = 'New';
                    if (newCol.text === EM.t('util.table.idColumn') && row._Values){
                        try{
                            formattedValue = <span>{row._Values.map(item => item[this.props.entity.idField]).join(', ')}</span>
                        }catch(e){}
                    }
                }                

                if (row._ActionType === 'Deleted'){
                    cls += 'Deleted';
                    tip = 'Deleted';
                    if (newCol.text === EM.t('util.table.idColumn'))formattedValue = row._Values.join(', ');
                }                

                if (row._ActionType === 'Updated' && value !== previousValue){
                    cls += 'Updated';
                    tip = 'Previously: '+ previousFormattedValue;
                }      
                
                if (newCol.auditAsEncoded){
                    formattedValue = '{Encoded}';
                }

                return <span title={tip} className={cls}>{formattedValue}</span>
            }

            if (col.text === EM.t('util.table.idColumn')){
                newCol.csvFormatter = (cell, row, index, formatExtraData) => {
                    if (row._ActionType === 'Deleted'){
                        return row._Values.join(', ');
                    }else if (row._ActionType === 'Bulk-Create'){
                        return row._Values.map(item => item[this.props.entity.idField]).join(', ');
                    }else{
                        return row[this.props.entity.idField];
                    }   
                }
            }

            columns.push(newCol);                
        });

        let rowClasses = (row, rowIndex) => {
            let classes = [];
            if (row.hasOwnProperty('_ActionType')) {
                classes.push(row._ActionType);
            }
            return classes.join(' ');
        };

        let title = EM.t('util.table.auditTitle', false, [this.entityTitle, this.props.id]);
        let csvTitle = 'Audit-' + this.entityTitle + '-' + this.props.id + '.csv';
        if (this.props.id === '*'){
            title = EM.t('util.table.auditTitleAll', false, [this.entityTitle]);
            csvTitle = 'Audit-' + this.entityTitle + '.csv';
        }
        return (
            <PanelModal fade={false} isOpen={!!this.props.id} toggle={this.onClose} className={'panel-full'} key="audit-modal">
                <ModalHeader toggle={this.onClose}>{title}</ModalHeader>
                <ModalBody>
                    <ToolkitProvider
                        keyField="_RowKey"
                        columns={columns}
                        data={this.state.data || []}
                        exportCSV={{
                            fileName: csvTitle
                        }}
                    >{props => (
                        <div>
                            <div className="clearfix mb-1">
                                <ExportCSVButton className="float-right btn btn-secondary btn-sm" title={EM.t('util.table.exportRecords')} {...props.csvProps}>
                                    <i className="fas fa-download"></i>{EM.t('util.table.exportRecords')}
                                </ExportCSVButton>
                            </div>
                            <BootstrapTable                                                              
                                classes="table table-bordered table-striped table-xs table-audit"       
                                rowClasses={rowClasses}
                                noDataIndication={() => <NoTableData data={this.state.data} />} 
                                {...props.baseProps}
                            />
                        </div>
                    )}
                    </ToolkitProvider>
                    <div className="alert alert-secondary font-sm">
                        <i className="fas fa-exclamation-circle">&nbsp;</i>{EM.t('util.table.auditWarning', null, [auditDays])}
                        <div className="mt-2">
                            <i className="far fa-clock">&nbsp;</i>
                            {EM.t('util.table.auditWarning2')}
                        </div>
                    </div>                    
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.onClose}>{EM.t('util.closeButton')}</Button>
                </ModalFooter>
            </PanelModal>
        );
    }
}
