/* global EM */
import React, { Component } from "react";
import Select from "react-select";
import "../../style/_resource-requests-option-dialog.scss";
import { v4 as uuid } from "uuid";

import {
    Button,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "reactstrap";
const requestTypeLists = [
    { label: "Add Resource", value: "1" },
    { label: "Remove Resource", value: "2" },
];

export default class ResourceRequestsOptionDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workItem: "",
            activity: "",
            role: "",
            requestType: "",
            message: "",
            errorWorkItem: "",
            errorActivity: "",
            errorRole: "",
            errorRequestType: "",
            errorMessage: "",
            visibleAlert: false,
            workItemsList: [],
            activitiesList: [],
            roleList: [],
        };

        this.onClose = this.onClose.bind(this);
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
    }

    csvToJson(csvArray) {
        const headers = csvArray[0];
        const data = csvArray.slice(1);

        return data.map(row => {
            let jsonObject = {};
            row.forEach((value, index) => {
                jsonObject[headers[index].trim()] = value.trim(); // Trimming for cleanliness
            });
            return jsonObject;
        });
    };

    findWorkItemsByTeamLead(jsonResult, userId) {
        return jsonResult
            .filter(item => item['Team Lead'] === userId) // Filter based on Team Lead
            .map(item => item['Work Item']); // Extract Work Item values
    }

    componentDidMount() {
        let self = this;
        let state = EM.store.getState();
        let loggedInUserId = state?.account?.User?.UserId;
        let isWorkItemFilterEnabled = EM.getSetting("ResourceManager:WorkItems:IsFilterEnabled");

        let filteredWorkItems = [];
        if (isWorkItemFilterEnabled === 'true') {
            let refTableWithFilter = state?.referenceTables?.filter((element) => element.Name === "TeamLead Workitems")[0]; //single match
            if (refTableWithFilter) {
                let csvRefTable = JSON.parse(refTableWithFilter.Value);
                const jsonRefTable = self.csvToJson(csvRefTable);
                filteredWorkItems = self.findWorkItemsByTeamLead(jsonRefTable, loggedInUserId);
            }
        }
        let workItems = [];
        if (filteredWorkItems.length > 0) {
            this.props.workItems.forEach((wi) => {
                if (filteredWorkItems.includes(wi.value)) {
                    workItems.push(wi);
                }
            });
        } else {
            workItems = this.props.workItems;
        }
        this.setState({ workItemsList: workItems });
        let activitiesDisplay = state?.activities ? state?.activities.map((element) => { return { label: element.Name, value: element.ActivityId } }) : [];
        this.setState({ activitiesList: activitiesDisplay });
        let rolesDisplay = state?.roles ? state?.roles.map((element) => { return { label: element.Name, value: element.RoleId } }) : [];
        this.setState({ roleList: rolesDisplay });
    }
    onClose() {
        this.setState({
            workItem: "",
            activity: "",
            role: "",
            requestType: "",
            message: "",
        });
        this.props.onClose();
    }
    onSubmitHandler(e) {
        e.preventDefault();
        let self = this;
        if (
            !this.state.workItem ||
            !this.state.activity ||
            !this.state.role ||
            !this.state.requestType ||
            !this.state.message
        ) {
            if (!this.state.workItem) {
                this.setState({ errorWorkItem: "WorkItem is required..." });
            }
            if (!this.state.activity) {
                this.setState({ errorActivity: "Activity is required..." });
            }
            if (!this.state.role) {
                this.setState({ errorRole: "Role is required..." });
            }
            if (!this.state.requestType) {
                this.setState({ errorRequestType: "Request type is required..." });
            }
            if (!this.state.message) {
                this.setState({ errorMessage: "Message is required..." });
            }
            return;
        } else {
            const ticketNum = uuid().slice(0, 8);
            const workItem = this.state.workItem.value;
            const activity = this.state.activity.value;
            const role = this.state.role.value;
            const requestType = this.state.requestType.value;
            const messages = this.state.message;
            let state = EM.store.getState();
            let userID = state?.account?.User?.UserId;
            let date = new Date();
            const postData = {
                TicketNumber: ticketNum,
                WorkItem: workItem,
                ActivityId: activity,
                RoleId: role,
                RequestTypeId: requestType,
                RequesterId: userID,
                RecipientId: null,
                RequesterNote: messages,
                RecipientNote: null,
                StatusId: "1",
                RequesterAcknowledged: date.toISOString(),
            };

            const domainId = state?.domain?.DomainId;
            EM.resourceRequests.create(postData, domainId).then((item) => {
                if (self.props.onUpdate) self.props.onUpdate(item);

                this.setState({ visibleAlert: true });
                setTimeout(() => {
                    this.setState({ visibleAlert: false });
                }, 1000);
                this.onClose();
            }).catch((error) => {
                console.error("Error posting data:", error);
                this.setState({ error: error.message });
                this.onClose();
            });
            this.setState({
                workItem: "",
                activity: "",
                role: "",
                requestType: "",
                message: "",
            });
        }
    }
    render() {
        return (
            <Modal
                ref={(el) => (this.componentRef = el)}
                fade={false}
                isOpen={this.props.isOpen}
                toggle={this.onClose}
                className={"demand-report-options " + this.props.className}
                key="demand-report-options"
            >
                <ModalHeader toggle={this.onClose}>
                    {EM.t("demand.resourceRequestsModal.resourceRequestForm")}
                </ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <label>WorkItem:</label>
                        <Select
                            className="multi-select"
                            isSearchable={true}
                            isMulti={false}
                            value={this.state.workItem}
                            options={this.state.workItemsList}
                            onChange={(selection) => {
                                this.setState({ errorWorkItem: "", workItem: "" });
                                if (selection) {
                                    window.setTimeout(() => {
                                        this.setState({ workItem: selection });
                                    }, 0);
                                }
                            }}
                            placeholder="Select WorkItem"
                        />
                        {this.state.errorWorkItem ? (
                            <p className="text-color-muted">{this.state.errorWorkItem}</p>
                        ) : null}
                    </FormGroup>
                    <FormGroup>
                        <label>Activity:</label>
                        <Select
                            className="multi-select"
                            isSearchable={true}
                            isMulti={false}
                            value={this.state.activity}
                            options={this.state.activitiesList}
                            onChange={(selection) => {
                                this.setState({ errorActivity: "", activity: "" });
                                if (selection) {
                                    window.setTimeout(() => {
                                        this.setState({ activity: selection });
                                    }, 0);
                                }
                            }}
                            placeholder="Select Activity"
                        />
                        {this.state.errorActivity ? (
                            <p className="text-color-muted">{this.state.errorActivity}</p>
                        ) : null}
                    </FormGroup>
                    <FormGroup>
                        <label>Role:</label>
                        <Select
                            className="multi-select"
                            isSearchable={true}
                            isMulti={false}
                            value={this.state.role}
                            options={this.state.roleList}
                            onChange={(selection) => {
                                this.setState({ errorRole: "", role: "" });
                                if (selection) {
                                    window.setTimeout(() => {
                                        this.setState({ role: selection });
                                    }, 0);
                                }
                            }}
                            placeholder="Select Role"
                        />
                        {this.state.errorRole ? (
                            <p className="text-color-muted">{this.state.errorRole}</p>
                        ) : null}
                    </FormGroup>
                    <FormGroup>
                        <label>Request Types:</label>
                        <Select
                            className="multi-select"
                            isSearchable={true}
                            isMulti={false}
                            value={this.state.requestType}
                            onChange={(selection) => {
                                this.setState({ errorRequestType: "", requestType: "" });
                                if (selection) {
                                    window.setTimeout(() => {
                                        this.setState({ requestType: selection });
                                    }, 0);
                                }
                            }}
                            options={requestTypeLists}
                            placeholder="Select Request Types"
                        />
                        {this.state.errorRequestType ? (
                            <p className="text-color-muted">{this.state.errorRequestType}</p>
                        ) : null}
                    </FormGroup>
                    <FormGroup>
                        <label>Message:</label>
                        <Input
                            type="textarea"
                            maxLength={250}
                            value={this.state.message}
                            onChange={(e) => {
                                this.setState({ errorMessage: "", message: "" });
                                this.setState({ message: e.target.value });
                            }}
                        />
                        {this.state.errorMessage ? (
                            <p className="text-color-muted">{this.state.errorMessage}</p>
                        ) : null}
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.onClose}>
                        {EM.t("util.closeButton")}
                    </Button>
                    <Button color="success" onClick={this.onSubmitHandler}>
                        {EM.t("demand.resourceRequestsModal.submit")}
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}
