/* global EM */
import React, { Component } from 'react';
import Dates from '../../util/Dates';
import Highcharts from './HighchartsWrapper';
import HighchartsTheme from './HighcartsTheme';
import ColorManager from '../../util/ColorManager';
import _ from 'underscore';
import DateGroupValueSet from '../../entities/files/DateGroupValueSet';
import MilestonesUtil from '../../util/MilestonesUtil';

export default class DemandGraph extends Component {
    constructor(props){
        super(props);

        this.hidden = {};

        this.colors = new ColorManager();
        this.onChartItemClicked = this.onChartItemClicked.bind(this);
    }

    componentDidMount() {
        let self = this;

        Highcharts.theme = HighchartsTheme;
        Highcharts.setOptions(HighchartsTheme);
        Highcharts.addEvent(window, 'beforeprint', function () {            
            self.prePrintSize = [ self.chart.chartWidth, self.chart.chartHeight ];
            if (self.props.printSize){
                self.chart.setSize(self.props.printSize[0], self.props.printSize[1], false);
            }else{
                self.chart.setSize(1000, 740, false);
            }            
        });
        Highcharts.addEvent(window, 'afterprint', function () {
            self.chart.setSize(self.prePrintSize[0], self.prePrintSize[1], true);
        });

        this.chart = Highcharts.chart('DemandGraph', {
            chart: {
                zoomType: 'x',
                margin: this.props.margin || [30, 30, 50, 65],
                ignoreHiddenSeries: true,
                backgroundColor: 'transparent'
            },
            lang: {
                noData: EM.t('util.nodata-filters')
            },
            noData: {
                useHTML: true
            },
            loading: {
                labelStyle: {
                    top: '2%'
                }
            },
            boost: {
                useGPUTranslations: true
            },            
            credits: { enabled: false },
            title: {
                text: ''
            },
            xAxis: {
                lineWidth: 1,
                className: 'x',
                type: 'datetime',
                minRange: 2592000000 * 3, //3 months in MS
                dateTimeLabelFormats: {
                    millisecond: '%H:%M:%S.%L',
                    second: '%H:%M:%S',
                    minute: '%H:%M',
                    hour: '%H:%M',
                    day: '%b %e, %Y',
                    week: '%b %e, %Y',
                    month: '%b %Y',
                    year: '%Y'
                }
            },
            yAxis: {
                tickLength: 0,
                title: {
                    text: ''
                },
                stackLabels: {
                    enabled: false
                },
                labels:{
                    format: '{value}'
                }
            },
            tooltip: {
                useHTML: true,                
                formatter: this.formatTooltip
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    shadow: false,
                    borderWidth: 0,
                    groupPadding: .075,
                    pointPadding: .1,
                    crisp: false, //False performs better, and cols don't touch.
                    maxPointWidth: 20, 
                    events: {
                        click: this.onChartItemClicked
                    }
                },
                area: {
                    stacking: 'normal',
                    fillOpacity: 0.25,                    
                    marker: {
                        enabled: false
                    },
                    events: {
                        click: this.onChartItemClicked
                    }
                },
                line: {
                    shadow: true,
                    marker: {
                        enabled: false
                    },
                    events: {
                        click: this.onChartItemClicked
                    }
                },
                series: {
                    states: {
                        hover: {
                            enabled: false
                        },
                        inactive: {
                            opacity: .5
                        }
                    }
                }
            },         
            legend: { 
                enabled: false,
                useHTML: true,
            },
            exporting: {
                buttons: {
                    contextButton: {
                        enabled: false
                    }
                },
                chartOptions: {
                    chart:{
                        width: 1200,
                        height: 800,
                        margin: [null, null, null, null],
                    },
                    legend: { 
                        enabled: true
                    }
                }
            },
            series: this.getAllSeries()
        });        

        this.seriesIndex = _.indexBy(this.chart.series, (series) => {
            return series.userOptions.stack + ':' + series.name;
        });

        let range = Dates.getMonthRangeFromMonthYearStrs(this.props.preferences.begin, this.props.preferences.end);
        this.chart.xAxis[0].setExtremes(range[0].toJSDate().getTime(), range[1].toJSDate().getTime(), true); 

        if (this.props.scenarioMode)this.setData(this.props);

        this.chart.hideNoData(); //hide no data message initially, since no data will be injected yet
    }

    getAllSeries(){
        let roles = this.getGroupingSeries(EM.roles, 'Role');
        let depts = this.getGroupingSeries(EM.departments, 'Department');
        let orgs = this.getGroupingSeries(EM.organizations, 'Organization'); 
        let totals = [];
        if (this.props.scenarioMode){
            totals = [{ 
                type: 'line', 
                name: 'Total',
                stack: 'primary', 
                color: '#888',
                seriesGroup: 'total',
                dateGrouping: '',
                datasetName: null,
                data: [],
                visible: true
            },{ 
                type: 'line', 
                name: 'Total',
                stack: 'secondary', 
                color: '#6484a5',            
                seriesGroup: 'total',
                linkedTo: ':previous', 
                zIndex: 100,
                dateGrouping: '',
                datasetName: null,
                data: [],
                visible: true        
            },{ 
                type: 'line', 
                name: 'Total',
                stack: 'tertiary', 
                color: '#aaa',
                seriesGroup: 'total',
                linkedTo: ':previous', 
                zIndex: -1,
                dateGrouping: '',
                datasetName: null,
                data: [],
                visible: true            
            }];  
        }
        return [...roles, ...depts, ...orgs, ...totals ];    
    }

    getGroupingSeries(dataset, grouping){
        let self = this;
        let series = [];
        let names = _.pluck(dataset.get(), ("Name")).sort();
        names.forEach((name, index) => {
            series.push({ 
                type: 'column', 
                name: name,
                stack: 'primary', 
                color: this.props.scenarioMode ? '#888' : self.colors.next(),
                seriesGroup: grouping,
                dateGrouping: '',
                datasetName: null,
                data: [],
                visible: this.props.scenarioMode ? false : true    
            });
            series.push({ 
                type: 'column', 
                name: name,
                stack: 'secondary', 
                color: this.props.scenarioMode ? self.colors.next() : self.colors.current(true),
                seriesGroup: grouping,
                linkedTo: ':previous', 
                zIndex: this.props.scenarioMode ? 100 : (index + 1) * 2,
                dateGrouping: '',
                datasetName: null,
                data: [],
                visible: this.props.scenarioMode ? false : true       
            });  
            series.push({ 
                type: 'column', 
                name: name,
                stack: 'tertiary', 
                color: this.props.scenarioMode ? '#aaa' : self.colors.current(true, true),
                seriesGroup: grouping,
                linkedTo: ':previous', 
                zIndex: this.props.scenarioMode ? - 1 : (index + 1) * 2,
                dateGrouping: '',
                datasetName: null,
                data: [],
                visible: this.props.scenarioMode ? false : true                
            });                        
        });
        return series;
    }

    formatTooltip(){
        try{
            let dso = this.series.userOptions;
            let date = `<b>${Dates.toGroupedStr(Dates.fromMs(this.x), dso.dateGrouping)}</b>`;
            let total = this.total?`Total: ${this.total.toFixed(2)}`:'';
            let dataset = dso.datasetName ? `${dso.stack.capitalize()} Dataset: ${dso.datasetType} (${dso.datasetName})<br/>` : '';        
            let point = `${this.series.name}: <b>${this.y?this.y.toFixed(2):''}</b>`;
            return `${date}<br/>${point}<br/>${dataset}${total}`;
        }catch(e){
            console.log(e);
            return 'Error: ' + this.series.name;
        }
    }

    formatLegendLabel(){
        return this.name;
    }

    onChartItemClicked(event){    
        let point = event.point;
        let series = point.series;
        this.props.onPointSelected(series.userOptions, point.options);
    }

    resize(){
        this.chart.reflow();
    }

    onLegendItemClicked(seriesClicked, isFocusMode){
        let newValue = seriesClicked.visible;
        this.chart.series.forEach((series) => {
            if (isFocusMode){
                series.update({
                    visible: series.name === seriesClicked.name ? true : false,
                    showInLegend: series.userOptions.stack === 'primary' ? (series.name === seriesClicked.name) : false
                }, false);
            }else{
                if (series.name === seriesClicked.name){
                    series.update({
                        visible: newValue,
                        showInLegend: series.userOptions.stack === 'primary' ? newValue : false
                    }, false);
                }
            }
        });
        this.chart.redraw();

        if (this.props.onSeriesSelected)this.props.onSeriesSelected(seriesClicked, isFocusMode);
    }

    onLegendItemsToggled(visible){
        this.chart.series.forEach((series, si) => {            
            series.update({
                visible: visible,
                showInLegend: series.userOptions.stack === 'primary' ? visible : false
            }, false);
        });
        this.chart.redraw();
    }

    onLegendItemVisibilitySet(item){        
        this.chart.series.forEach((series) => {
            let isVisible = !item ? false : series.name === item;
            series.update({
                visible: !!isVisible,
                showInLegend: !!isVisible
            }, false);
        });
        this.chart.redraw();
    }

    onExport(type){
        let exOpts = {
            chart: {
                backgroundColor: 'white'
            }
        };
        if (type === 'png'){
            this.chart.exportChartLocal({ type: 'image/png' }, exOpts);
        }
        if (type === 'svg'){
            this.chart.exportChartLocal({ type: 'image/svg+xml' }, exOpts);
        }        
        if (type === 'print'){
            this.chart.print(null, exOpts);
        }
    }

    showLoading(){
        this.chart.showLoading();
    }

    hideLoading(){
        this.chart.hideLoading();
    }

    componentDidUpdate(prevProps) {        
        if (    
            prevProps.primary !== this.props.primary ||
            prevProps.secondary !== this.props.secondary ||
            prevProps.tertiary !== this.props.tertiary ||
            prevProps.preferences !== this.props.preferences ||
            prevProps.plotLines !== this.props.plotLines
        ){
            this.showLoading();
            let shouldZoomOut = prevProps.preferences.begin !== this.props.preferences.begin || prevProps.preferences.end !== this.props.preferences.end;        
            window.setTimeout(() => {
                this.setData(this.props, shouldZoomOut);
                this.hideLoading();
            }, 0);
        }
    }

    async setData(props, shouldZoomOut) {
        let preferences = props.preferences;        

        //Clear if it comes in with no primary
        if (!props.primary) {
            this.chart.series.forEach((series) => {
                series.setData([], false);
            });
            this.chart.redraw();
            return;                
        }       

        EM.time('Total Graph Time');
        //EM.time('Processing time');
        let primaryData = await props.primary.getSummary(preferences, 'primary');     
        let secondaryData = null;   
        let tertiaryData = null;         
        if (props.secondary){
            secondaryData = await props.secondary.getSummary(preferences, 'secondary', this.props.scenarioMode);        
        }       
        if (props.tertiary){
            tertiaryData = await props.tertiary.getSummary(preferences, 'tertiary', this.props.scenarioMode);        
        }       
        //EM.timeEnd('Processing time');

        //EM.time('Post-Processing time');
        let seriesData = this.transformDatasetsForGraphing(primaryData, secondaryData, tertiaryData, preferences);                        
        if (Object.keys(seriesData).length === 0){
            this.setState({ nodata: true });
        }
        //EM.timeEnd('Post-Processing time');

        //EM.time('Series Processing time');
        let chartTypes = {
            primary: preferences.primary.chartType.toLowerCase(),
            secondary: preferences.secondary.chartType.toLowerCase(),
            tertiary: preferences.tertiary.chartType.toLowerCase()
        };

        for (var j = 0, rowLen = this.chart.series.length; j < rowLen; j++) {
            let series = this.chart.series[j];
            let stack = series.userOptions.stack;
            let group = series.userOptions.seriesGroup;
            let seriesKey = stack + ':' + group + ':' + series.name;
            let stackObj = props[stack];
            if (group === 'total')seriesKey = stack + ':total';            
                        
            series.update({
                type: chartTypes[stack],
                dateGrouping: preferences.dateGrouping,
                datasetType: stackObj ? stackObj.getTypeName() : null,
                datasetName: stackObj? stackObj.name : null,
                data: seriesData[seriesKey] || []
            }, false);
            
        }
        //EM.timeEnd('Series Processing time');

        if (preferences.series)this.onLegendItemVisibilitySet(preferences.series);
        this.setPlotLines(preferences);

        //EM.time('Series Redraw');
        this.chart.redraw(false);
        if (shouldZoomOut || this.props.scenarioMode){
            this.chart.zoomOut();
        }
        //EM.timeEnd('Series Redraw');      
        EM.timeEnd('Total Graph Time');  
    }    

    transformDatasetsForGraphing(primaryData, secondaryData, tertiaryData, preferences){
        let series = {};        
        let monthKeyMasterSet;
        if (this.props.scenarioMode){
            let allKeys = [];
            Array.prototype.push.apply(allKeys, Object.keys(primaryData));
            if (secondaryData){
                Array.prototype.push.apply(allKeys, Object.keys(secondaryData));
            }
            monthKeyMasterSet = _.uniq(allKeys).sort((a, b) => a - b);
        }else{
            monthKeyMasterSet = Object.keys(primaryData).sort((a, b) => a - b);
        }

        monthKeyMasterSet.forEach((monthKey, mkIndex) => {
            let monthGroup = primaryData[monthKey];
            if (!monthGroup)monthGroup = secondaryData[monthKey];
            
            Object.keys(monthGroup).forEach((roleKey) => {
                if (roleKey === 'date')return;                

                let data = monthGroup[roleKey];                 
                this.addSingleSeries(monthGroup, series, 'primary:', roleKey, data);

                if (secondaryData){
                    let secMonthGroup = secondaryData[monthKey]; 
                    if (secMonthGroup){  
                        this.addSingleSeries(secMonthGroup, series, 'secondary:', roleKey, data);
                    }
                }

                if (tertiaryData){
                    let terMonthGroup = tertiaryData[monthKey]; 
                    if (terMonthGroup){  
                        this.addSingleSeries(terMonthGroup, series, 'tertiary:', roleKey, data);
                    }
                }                
            });  
        });

        return series;
    } 
    
    addSingleSeries(monthGroup, series, prefix, roleKey, fallbackData){
        let key = prefix + roleKey; 
        if (!series[key])series[key] = [];

        let data = monthGroup[roleKey];     
        let dt = monthGroup.date.toMillis ? monthGroup.date.toMillis() : monthGroup.date.ts;      

        series[key].push([dt, data?(data.set?DateGroupValueSet.from(data).get():fallbackData):null]);
    }
    
    async setPlotLines(preferences){
        let wi = null;
        let wis = preferences.get('workitem');
        if (wis && wis.length === 1)wi = wis[0];

        window.setTimeout(async () => {
            this.chart.xAxis[0].update({
                plotLines: preferences.plotLines ? await MilestonesUtil.getMilestonesForGraphing(wi) : []
            }, true);
        }, 0);
    }

    render() {
        return (
            <div className="demand-graph" id="DemandGraph" />
        );
    }
}