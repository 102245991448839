/* global EM */
import Dates from './Dates';

export default {
    getMilestones(workItem){
        let milestones = [];
        let msTag = 'Milestone:';
        let wic = workItem.trim();
        EM.attributes.get().forEach(attr => {
            if (attr.Scope && attr.Scope.trim() === wic && attr.AttributeTypeLookupId === 3){
                let label = attr.Name.replace(msTag, '');
                let date = Dates.fromStr(attr.Value);
                if (date.isInvalid)return;
                milestones.push({ label, date, id: attr.AttributeId });
            }
        });

        return milestones;
    },

    async getMilestonesForGraphing(workItem){
        let mss = [];    
        if (workItem){            
            await EM.attributes.load();
            mss = this.getMilestones(workItem).map(ms => {
                return {
                    value: ms.date.toMillis(), 
                    className: 'milestone', 
                    label: { text: ms.label, useHTML: true },
                }
            });
        }

        let today = {
            value: new Date().getTime(), 
            className: 'today', 
            label: { text: EM.t('util.today'), useHTML: true },
        };

        return [ today, ...mss ];
    }
}