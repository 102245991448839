import React from 'react';
import { Label, FormGroup, Input, FormText  } from 'reactstrap';

export default function TextField(props){        
    return (
        <FormGroup>      
            <Label title={props.description}>{props.display || props.name}:</Label>
            <Input 
                value={props.value} 
                onChange={event => props.onChange(props.name, event.target.value)} 
            />
            {props.description ? 
                <FormText>{props.description}</FormText>
            : null }
        </FormGroup>
    );
}