/* global EM */
import React, { Component } from 'react';
import FileViewerDialog from '../components/FileViewerDialog';
import { Validators } from '../util/EntityValidators';
import Dates from '../util/Dates';
import CSV from '../util/CSV';
import _ from 'underscore';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import PageTitle from '../components/PageTitle';
import InputLabel from '../components/InputLabel';
import MetaStack from '../components/MetaStack';
import EntityCreator from '../components/EntityTables/EntityCreator';
import EntityAuditTrail from '../components/EntityTables/EntityAuditTrail';

class Headcounts extends Component {
    constructor(props){
        super(props);

        this.state = {
            fileId: null,
            fileTitle: null,
            fileSubtitle: null
        };

        EM.headcounts.load();
        EM.resourceRequests.load();

        this.onCloseFile = this.onCloseFile.bind(this);
    }

    loadFile(row){
        const entity = EM.headcounts;      
        entity.loadFile(row.HeadcountId);
        this.setState({ 
            fileId: entity.makeFileName(row.HeadcountId), 
            fileTitle: row.Name,
            fileSubtitle: Dates.isoToDateTime(row.CreatedOn)
        });
    }

    onCloseFile(){
        this.setState({ fileId: null, fileTitle: null, fileSubtitle: null });
    }

    onDelete(row) {
        if (!row) return;
        if (window.confirm(EM.t('util.table.deleteConfirmation'))) {
            let ids = [ row['HeadcountId'] ];
            EM.headcounts.delete(ids);   
        }
    }  
    
    onUpdate(row, prop, value) {
        if (!row || !prop) return;        
        if (row[prop] !== value){
            EM.headcounts.update(row, Object.assign({}, row, { [prop]: value }));
        }
    }      

    isSearchMatch(row){
        if (!this.state.searchTerm)return false;
        if (row.Name){
            if (row.Name.toLowerCase().indexOf(this.state.searchTerm) > -1)return true;
        }
        return false;
    }        

    render() {
        let data = null;
        let columns = null;
        const entity = EM.headcounts;     

        let hideFunctions = EM.getActiveDomain().ParentDomainId && !entity.isOverridden(); 

        if (EM.allLoaded(entity)){    
            data = _.sortBy(entity.get(), item => {
                let d = item.IsDefault ? 3152536197530 : Dates.fromISO(item.CreatedOn).toMillis();
                return d;
            }).reverse();
            columns = [{
                dataField: 'Name',
                text: entity.columns('name'),
                validators: [ Validators.required ],
                width: 200  
            }, {
                dataField: 'IsDefault',
                text: entity.columns('isDefault'),
                width: 100,  
                asActive: true        
            }];
        }
        
        return (
            <div key="contents" className="page container-fluid">
                <div>
                    <PageTitle title={entity.t('title')} bar={true}>
                        <div className="table-tools">
                            {EM.isDomainEditor() && !hideFunctions ?
                                <div className="btn-group">
                                    <button className="btn btn-success btn-sm" title={EM.t('util.table.addRecord')} onClick={() => {
                                        this.setState({ createModalOpen: true });    
                                    }}>
                                        <i className="fas fa-plus"></i><span>{EM.t('util.table.addRecord')}</span>
                                    </button>                                    
                                </div> 
                            : null }
                            <div className="btn-group">
                                <button className="btn btn-secondary btn-sm" title={EM.t('util.table.getTemplate')} onClick={async (event) => {
                                    await EM.roles.load();
                                    let dates = Dates.currentMonthPlusYears(1);
                                    let output = [['Role', Dates.toDateShort(dates[0]), Dates.toDateShort(dates[1]) ]];
                                    EM.roles.get().forEach(role => {
                                        output.push([role.Name, '0', '0']);
                                    })
                                    CSV.save('headcount-template', output);
                                }}>
                                    <i className="fas fa-table"></i><span>{EM.t('util.table.getTemplate')}</span>
                                </button>
                            </div>                            
                            <label className="search-label">
                                <span className="sr-only">Search this table</span>
                                <input type="text" 
                                    className="form-control form-control-sm" 
                                    placeholder="Search" 
                                    value={this.state.searchTerm}
                                    autoComplete="off" 
                                    autoCorrect="off" 
                                    autoCapitalize="off" 
                                    spellCheck="false"
                                    onChange={(event) => {
                                        let value = event.target.value;
                                        this.setState({ searchTerm: value ? value.toLowerCase() : '' });
                                    }}
                                />                            
                            </label>
                            <button className="btn btn-default" onClick={() => {
                                this.setState({ searchTerm: ''});
                            }}>×</button>
                        </div>
                    </PageTitle>

                    { data ? 
                        <div className={"card-rows " + (this.state.searchTerm ? 'search-mode ' : '')}>
                            {data.map(row => {                        
                                let isSearchMatch = this.isSearchMatch(row);
                                return (
                                    <div className={["card", (row.IsDefault ? 'card-default' : ''), (isSearchMatch?'search-match':'')].join(' ')} key={row.HeadcountId}>
                                        <div className="card-body">
                                            <h5 className="card-title mb-1 clearfix">
                                                {EM.debugMode ?
                                                    <span className="meta-reference">{ row.HeadcountId }<br/></span>
                                                : null }     
                                                <InputLabel characterLimit={100} className="title" text={row.Name} altText="[BLANK]" disabled={!EM.isDomainAdmin() || hideFunctions} onEdited={event => {
                                                    if (!event.target.value || !event.target.value.trim()){
                                                        alert(EM.t('util.noBlankWarning'));
                                                        return;
                                                    }
                                                    this.onUpdate(row, 'Name', event.target.value.trim());
                                                }} />
                                                <div className="btn-group">
                                                    <button className="btn btn-sm btn-domain" onClick={() => {
                                                        this.loadFile(row);
                                                    }}>
                                                        <i className="far fa-eye"></i> {EM.t('util.table.view')}
                                                    </button>
                                                    <UncontrolledButtonDropdown size="sm" className="menu-button">
                                                        <DropdownToggle className="btn-domain">
                                                            <i className="fas fa-caret-down"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            { EM.isDomainEditor() ? 
                                                                <DropdownItem disabled={row.IsDefault} onClick={() => {
                                                                    this.onUpdate(row, 'IsDefault', !row.IsDefault);
                                                                }}>Make Default</DropdownItem>
                                                            : null }
                                                            <DropdownItem divider />
                                                            { EM.isDomainAdmin() ?
                                                                <DropdownItem onClick={event => this.setState({ auditId: row.HeadcountId })}>{EM.t('util.table.audit')}</DropdownItem>
                                                            : null }                                                            
                                                            { EM.isDomainAdmin() ?
                                                                <DropdownItem disabled={row.IsDefault} className={row.IsDefault?"text-muted":"text-danger"} onClick={event => this.onDelete(row)}>{EM.t('util.table.deleteRecords')}</DropdownItem>
                                                            : null }
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </div>
                                            </h5>   
                                            <div className="card-text text-muted">
                                                <InputLabel characterLimit={200} type="textarea" className="description flush" text={row.Description} altText="No description." disabled={hideFunctions} onEdited={event => {
                                                    this.onUpdate(row, 'Description', event.target.value.trim());
                                                }} />  
                                            </div>                                    
                                        </div>
                                        <div className="card-footer">
                                            <MetaStack item={row} />
                                        </div>                                       
                                    </div>
                                );
                            })}                       
                        </div>
                    : null }              

                    {columns ? 
                        <EntityCreator columns={columns} entity={entity} isOpen={this.state.createModalOpen} key="createModal" 
                            onClose={() => {
                                this.setState({ createModalOpen: false });                        
                            }}
                        />         
                    : null }

                    {columns ? 
                        <EntityAuditTrail entityColumns={columns} entity={entity} id={this.state.auditId} onClose={() => {
                            this.setState({ auditId: null });
                        }} />   
                    : null } 
                </div>                        
                <FileViewerDialog 
                    files={this.props.files}
                    onClose={this.onCloseFile} 
                    id={this.state.fileId} 
                    title={this.state.fileTitle}
                    subtitle={this.state.fileSubtitle}
                    entity={entity}
                />
            </div>
        );
    }
}

export default Headcounts;
