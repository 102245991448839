/* global EM */
import React, { Component } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { Workbook } from '../../util/Workbook';

export default class DemandReportTable extends Component {
    onExportXlsx(title, columns, data){
        new Workbook((wb) => {
            wb.addWorksheetFromColumnsAndData('report', columns, data);
            wb.save('Report');
        });
    }

    render() {
        return (            
            <ToolkitProvider
                keyField="id"
                exportCSV={{
                    fileName: 'Report.csv'
                }}
                data={this.state.tableData || []}
                columns={this.columns || []}                             
            >
                {props => (
                    <div className="demand-table">
                        <div className="clearfix mb-1">
                            <h5 className="float-left">{this.props.selectedSeries ? this.props.selectedSeries.name : EM.t('reports.differenceTable.beginText')}</h5>
                            { this.props.selectedSeries ? 
                                <UncontrolledButtonDropdown size="sm" className="no-print float-right">
                                    <DropdownToggle caret>
                                        <i className="fas fa-download"></i>{EM.t('util.table.exportRecords')}
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={() => props.csvProps.onExport()}>CSV</DropdownItem>
                                        <DropdownItem onClick={() => this.onExportXlsx(this.props.selectedSeries.name, this.columns, this.state.tableData)}>XLSX</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>                     
                            : null }
                        </div>      
                        {this.state.tableData ?                                  
                            <BootstrapTable                                         
                                keyField="id"
                                classes="table table-bordered table-striped table-xs table-big-headers"       
                                {...props.baseProps}
                            />
                        : null }
                    </div>
                )}
            </ToolkitProvider>
        );
    }
}