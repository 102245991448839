/* global EM */
import React, { Component } from 'react';
import Creatable from 'react-select/creatable';

export default class WorkItemSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            currentValue: this.props.value || ''
        }
    }

    componentDidMount(){
        EM.schedules.loadDefaultItem().then((defaultSchedule) => {
            if (!defaultSchedule)return;
            EM.schedules.loadFile(defaultSchedule.ScheduleId).then(() => {
                let fileContents = EM.schedules.getFile(defaultSchedule.ScheduleId);
                if (fileContents){  
                    let fileContentsObjects = fileContents.toObjectArray();
                    let wiGroups = fileContentsObjects.groupBy('Name');
                    let wiKeys = Object.keys(wiGroups).sort();
                    let addMeta = EM.getSetting('AdditionalMetadataFields');
                    if (addMeta){
                        addMeta = addMeta.split(',');
                    }
                    let tasks = wiKeys.map(key => {                        
                        let group = wiGroups[key];
                        let lbl = key;
                        
                        if (addMeta && Array.isArray(addMeta)){
                            let meta = {};
                            group.forEach(item => {
                                addMeta.forEach(attr => {
                                    let value = item['_' + attr];
                                    if (value){
                                        if (!meta[attr])meta[attr] = {};
                                        meta[attr][value] = true;
                                    }
                                });
                            });
                            let metaStr = [];
                            Object.keys(meta).forEach(key => {
                                metaStr.push(key + ': ' + Object.keys(meta[key]).join(', '));
                            });
                            if (metaStr.length > 0){
                                lbl = lbl + ' <span class="text-muted">(' + metaStr.join(' | ') + ')</span>';
                            }
                        }

                        return { label: lbl, value: key };
                    });

                    let addTaskList = EM.getSetting('AdditionalTasks');  
                    if (addTaskList){
                        addTaskList.split(',').map((task) => {
                            return tasks.push({ value: task.trim(), label: task.trim() });
                        });
                    }

                    this.setState({ tasks });
                }
            });
        });
    }

    render() {
        let cv = this.state.currentValue;
        let value = cv ? { value: cv, label: cv } : this.props.value;
        return (
            <Creatable
                className={"single-select " + (this.props.className)}
                classNamePrefix={"single-select"}
                isSearchable={true}
                isClearable={true}
                isMulti={false}
                options={this.state.tasks}
                placeholder={EM.t('util.filtering.placeholderDefault')}
                formatCreateLabel={(input) => { return EM.t('util.select-create', null, [input]) }}
                value={value} 
                ref={this.props.innerRef}        
                onChange={(selected) => {
                    this.setState({ currentValue: selected ? selected.value.trim() : null });
                    if (this.props.onChange)this.props.onChange(selected);
                    if (this.props.onUpdate)this.props.onUpdate(selected ? selected.value.trim() : null);
                }}  
                formatOptionLabel={function(data) {
                    return (
                        <span dangerouslySetInnerHTML={{ __html: data.label }} />
                    );
                }}
            />
        );
    }
};