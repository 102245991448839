/* eslint no-extend-native: "off" */
import _ from 'underscore';

export default {
    registerGlobal: function () {
        Promise.prototype.finally = Promise.prototype.finally || {
            finally (fn) {
                const onFinally = value => Promise.resolve(fn()).then(() => value);
                return this.then(
                result => onFinally(result),
                reason => onFinally(Promise.reject(reason))
                );
            }
        }.finally;
        
        String.prototype.capitalize = function () {
            return this[0].toUpperCase() + this.slice(1);
        };

        String.prototype.decapitalize = function () {
            return this[0].toLowerCase() + this.slice(1);
        };

        String.prototype.toIdToken = function () {
            return this.replace(/\W/g, '_');
        };

        Array.prototype.sortAlphaNumeric = function(){
            var reA = /[^a-zA-Z]/g;
            var reN = /[^0-9]/g;

            function sortAlphaNum(a, b) {
                var aA = a.replace(reA, "");
                var bA = b.replace(reA, "");
                if (aA === bA) {
                    var aN = parseInt(a.replace(reN, ""), 10);
                    var bN = parseInt(b.replace(reN, ""), 10);
                    return aN === bN ? 0 : aN > bN ? 1 : -1;
                } else {
                    return aA > bA ? 1 : -1;
                }
            }

            return this.sort(sortAlphaNum);
        };

        Array.prototype.groupBy = Array.prototype.groupBy || function (criteria) {
            return this.reduce(function (obj, item) {        
                var key = typeof criteria === 'function' ? criteria(item) : item[criteria];    
                if (!obj.hasOwnProperty(key))obj[key] = [];
                obj[key].push(item);
                return obj;        
            }, {});
        };

        Array.prototype.mapFiltered = function(transform){
            return this.reduce(function(memo, item, itemIndex) {
                let result = transform(item, itemIndex);
                if (result)memo.push(result);
                return memo;
            }, []);
        };

        _.mixin({
            nest: (list, ...iteratees) => {
                return _.filteredNest(list, null, ...iteratees);
            },
            filteredNest: (list, filterFunction, ...iteratees) => {
                let groups = {};
                list.forEach((item, itemIndex) => {
                    if (filterFunction) {
                        let included = filterFunction(item);
                        if (!included) return;
                    }

                    let cGroup = groups;
                    iteratees.forEach((iteratee, ii) => {
                        let result = (typeof iteratee === 'string' ? item[iteratee] : iteratee(item, itemIndex));
                        if (!cGroup[result]) cGroup[result] = ii === iteratees.length - 1 ? [] : {};
                        cGroup = cGroup[result];
                    });
                    cGroup.push(item);
                });

                return groups;
            }
        });
    },

    loadScript: function(uri) {
        return new Promise((resolve, reject) => {
            var tag = document.createElement('script');
            tag.src = uri;
            tag.async = true;
            tag.onload = () => {
                resolve();
            };
            var firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        });
    },

    sleep: function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    } 
}