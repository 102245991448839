/* global EM */
import React, { Component } from 'react';
import HelpContent from '../components/Help/HelpContent';
import PageTitle from  '../components/PageTitle';
import MetaInfo from  '../components/MetaInfo';
import Routes from '../app/Routes';

export default class HelpManual extends Component {    
    render() {
        return (
            <div key="contents" className="page container-fluid help-page">
                <div className="row no-gutters p-0">
                    <div className="col-6">
                        <PageTitle title={EM.t('help.title')} />
                    </div>
                    <div className="col-6 no-print">
                        <div className="btn-group float-right">
                            <button className="btn btn-secondary btn-sm" onClick={() => {
                                this.props.history.push(Routes.compose(Routes.client.help, {}, {path: '/'}));
                            }}>
                                <i className="fas fa-arrow-left"></i> {EM.t('help.back')}
                            </button>                             
                            <button className="btn btn-warning btn-sm" onClick={() => {
                                window.print();
                            }}>
                                <i className="fas fa-print"></i> {EM.t('help.print')}
                            </button> 
                        </div>
                    </div>
                </div>
                <div className="row no-gutters p-0">
                    <HelpContent path={'*'} readOnly={true} onHelpClick={() => {}} />
                    <MetaInfo />
                </div>            
            </div>
        );
    }
}