export default class LocalStorageManager{
    constructor(store){
        this.localNamespace = 'rm.'; 
    }

    set(key, value){
        let toSave = value;
        if (typeof value !== 'string'){
            toSave = JSON.stringify(value);
        }
        localStorage.setItem(this.localNamespace + key, toSave);
    }

    get(key){
        return localStorage.getItem(this.localNamespace + key);
    }

    remove(key){
        localStorage.removeItem(this.localNamespace + key);
    }

    keys(){
        return Object.keys(localStorage).filter(key => { 
            return key.indexOf(this.localNamespace) === 0;
        }).map(key => {
            return key.replace(this.localNamespace, '');
        });
    }
}