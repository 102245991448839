/* global EM */
import React, { Component } from 'react';
import PanelModal from '../PanelModal';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dates from '../../util/Dates';
import StaffingUtil from '../../util/StaffingUtil';
import StaffingWorkItemEmployeeListing from './StaffingWorkItemEmployeeListing';
import _ from 'underscore';

export default class StaffingWorkItemManageTasksDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeProjection: null
        };

        this.onClose = this.onClose.bind(this);
        this.filteredEmployees = null;

        EM.employees.load();
    }

    onClose(){
        this.filteredEmployees = null;
        this.props.onClose();        
    }

    getGroupKey(assignment){
        return assignment.ActivityName + ': ' + assignment.BeginDateStr + ' - ' + assignment.EndDateStr;
    }

    render() {
        let { range, workItem, projectionsGroupKey, projectionsInGroup, filterIndexes, assignments } = this.props;        
        if (!range)return null;         
        let role = EM.roles.findByKey(projectionsGroupKey);
        let roleName = role.Name;        
        let sortedProjections = _.sortBy(projectionsInGroup, proj => {
            let activity = EM.activities.byId(proj[filterIndexes.activity]);
            if (activity) {
                return activity.Order ? parseInt(activity.Order) : 1000;
            } else {
                return 1000;
            }
        });

        if (!this.filteredEmployees)this.filteredEmployees = EM.employees.get().filter(emp => {
            return emp.RoleId === role.RoleId && emp.Active
        });
        let roleAssignments = assignments.filter(assignment => {
            let emp = EM.employees.byId(assignment.EmployeeId);
            return emp.RoleId === role.RoleId;
        });
        let groupedAssignments = _.groupBy(roleAssignments, assignment => this.getGroupKey(assignment));

        return (
            <PanelModal 
                isOpen={this.props.visible} 
                key="assignments-role-dialog-modal" 
                className={'panel workitem-assignments-dialog'} 
                toggle={(event) => { this.props.close(); }}
            >
                <ModalHeader toggle={this.onClose}>
                    {workItem} &mdash; {roleName}: {Dates.toMonthYearStrShort(range.dates[0])} to {Dates.toMonthYearStrShort(range.dates[range.dates.length - 1])}
                </ModalHeader>
                <ModalBody>
                    <p>
                        Projections for Work Item <i className="text-info">{workItem}</i>&nbsp; 
                        and Role <i className="text-info">{roleName}</i>&nbsp; 
                        between {Dates.toMonthYearStrShort(range.dates[0])}&nbsp; 
                        and {Dates.toMonthYearStrShort(range.dates[range.dates.length - 1])}.
                    </p>
                    {sortedProjections.map((proj, projIndex) => {
                        let itemElementClass = projIndex === this.state.activeProjection ? "expanded" : "collapsed";
                        let assignmentGroupKey = EM.activities.lookupValue(proj[filterIndexes.activity]) + ': ' + Dates.toMonthYearStrShort(proj[filterIndexes.begin]) + ' - ' + Dates.toMonthYearStrShort(proj[filterIndexes.end]);
                        let assignmentsInGroup = groupedAssignments[assignmentGroupKey] || [];

                        let projectionsPercentage = Math.fround(proj[filterIndexes.value]);
                        let assignmentPercentage = Math.fround(assignmentsInGroup.reduce(function (memo, assignment) { 
                            return memo + assignment.Value; 
                        }, 0));
                        let colorClass = StaffingUtil.getColorClass(assignmentPercentage, projectionsPercentage, true);
                        if (assignmentsInGroup.length === 0)colorClass = 'other';

                        return (
                            <div key={"sbwir-detail-row-" + projIndex}>
                                <div                                     
                                    className={"clearfix section-header " + itemElementClass + ' ' + colorClass}
                                    onClick={() => {
                                        this.setState({ activeProjection: projIndex === this.state.activeProjection ? null : projIndex });
                                    }}
                                >
                                    <span className={"title roleName"}>
                                        {assignmentGroupKey}
                                    </span>
                                    <span className={"ratio float-right"} title={StaffingUtil.getTooltip(assignmentPercentage, projectionsPercentage)}>                                                        
                                        {StaffingUtil.getMarkupRatio(assignmentPercentage, projectionsPercentage)}
                                    </span>
                                </div>
                                {itemElementClass === 'expanded' ?
                                    <div className="section-content">
                                        <StaffingWorkItemEmployeeListing
                                            role={role}
                                            onAddAssignment={this.props.onAddAssignment}
                                            projection={proj}
                                            filterIndexes={filterIndexes}
                                            employees={this.filteredEmployees}
                                            assignments={assignments}
                                            relevantAssignments={assignmentsInGroup}
                                            onAfterDeleteAssignment={this.props.onAfterDeleteAssignment}
                                            onAfterUpdateAssignment={this.props.onAfterUpdateAssignment}
                                            show={itemElementClass === 'expanded'}
                                            allMatchingDateRange={proj[filterIndexes.range]}
                                        />
                                    </div>
                                : null }
                            </div>
                        )
                    })}
                </ModalBody>
                <ModalFooter>
                    <Button className="pull-left" onClick={this.onClose}>
                        {EM.t('util.closeButton')}
                    </Button>
                </ModalFooter>
            </PanelModal>
        );
    }
}