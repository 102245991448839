/* global EM */
import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import File from '../util/File';

function renderOptionValue(i18nKeyBase, key, value, isText){
    try{
        let output = null;
        let keyOut = key;

        if (key === 'focusMode')return null;

        if (Array.isArray(value)){
            output = value.map(item => item === null ? '<blank>' : item).join(', ');
        }

        if (typeof value == "boolean"){
            output = value.toString().capitalize();
        }
        
        if (typeof value === "string"){
            output = value;
        }

        if (key === 'primary' || key === 'secondary' || key === 'tertiary'){
            output = (value.dataset.label || 'Default') + (' (' + value.dataset.group.capitalize() + ')');
            keyOut = key + 'Dataset';
        }

        if (output === null)return null;

        let label = EM.t(i18nKeyBase + '.' + keyOut, true);
        if (!label){
            label = keyOut.replace(/([A-Z])/gm, ' $1').capitalize();
        }

        if (isText){
            return `${label.trim()}: ${output}`;
        }else{
            return <li key={key}><span>{label.trim()}:</span> {output}</li>;
        }
    }catch(e){
        return null;
    }
}

export default function ReportDetailsDialog(props){
    if (!props.isOpen)return null;

    let reportOptions = props.reportOptions;
    let configuration = props.configuration;

    if (!configuration)return null;
    if (!reportOptions)return null;
    
    return (
        <Modal fade={false} isOpen={props.isOpen} toggle={props.onClose} className={' ' + (EM.currentTheme === 'Dark'?'dark-mode ': ' ') + props.className} backdrop="static">
            <ModalHeader toggle={props.onClose}>
                Report Details
            </ModalHeader>
            <ModalBody>                
                <ul className="def-list">
                    <li><span>{EM.t('reports.publishing.name')}:</span> {configuration.Name || '<blank>'}</li>
                    <li><span>{EM.t('reports.publishing.description')}:</span> {configuration.Description || '<blank>'}</li>
                    <li><span>{EM.t('reports.publishing.isPublic')}:</span> {configuration.IsPublic ? 'Yes' : 'No' }</li>
                </ul>
                <hr/>
                <h6 className="font-weight-bold text-muted mb-3">Report Properties</h6>
                <ul className="def-list">
                    {Object.keys(reportOptions).sort((a,b) => {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    }).map((key) => {
                        return renderOptionValue(props.i18nKeyBase, key, reportOptions[key]);
                    })}
                </ul>
            </ModalBody>
            <ModalFooter>        
                <Button color="light" className="mr-auto" onClick={()=> {
                    let lines = [
                        EM.t('reports.publishing.name') + ': ' + (configuration.Name || '<blank>'),
                        EM.t('reports.publishing.description') + ': ' + (configuration.Description || '<blank>'),
                        EM.t('reports.publishing.isPublic') + ': ' + (configuration.IsPublic ? 'Yes' : 'No') 
                    ];
                    Object.keys(reportOptions).sort((a,b) => {
                        return a.toLowerCase().localeCompare(b.toLowerCase());
                    }).forEach((key) => {
                        let value = renderOptionValue(props.i18nKeyBase, key, reportOptions[key], true);
                        if (value)lines.push(value);
                    });
                    File.save(configuration.Name + '-definition.txt', lines.join('\n'));
                }}>{EM.t('reports.publishing.export')}</Button>
                <Button color="secondary" onClick={props.onClose}>{EM.t('util.closeButton')}</Button>
            </ModalFooter>
        </Modal>
    );
}