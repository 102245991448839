/* global EM */
import React, { useState } from 'react';
import { Button, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, FormText, Label } from 'reactstrap';
import Select from 'react-select';
import PanelModal from '../PanelModal';
import File from '../../util/File'

const CreateTemplateDialog = (props) => {

    let [canCreate, setCanCreate] = useState(false);

    let [selectedDomain, setSelectedDomain] = useState(null);
    let [fileName, setFileName] = useState(null);
    let [exportDefaultSchedule, setExportDefaultSchedule] = useState(true);

    let availableDomains = props.domains ? props.domains.map(domain => { return { value: domain.DomainId, label: domain.DisplayName } }) : null;

    return (
        <PanelModal fade={false} isOpen={props.isOpen} toggle={onClose} className={'panel panel-full ' + props.className} key="create-template-modal">
            <ModalHeader toggle={onClose}>{EM.t('domains.export.header')}</ModalHeader>
            <ModalBody>
                <div>
                    <FormGroup>
                        <Select
                            className="single-select"
                            classNamePrefix="single-select"
                            isSearchable={true}
                            isMulti={false}
                            options={availableDomains}
                            placeholder={EM.t('domains.export.selectDomain.placeholder')}
                            onChange={(selection) => {
                                setCanCreate(true);
                                setSelectedDomain(selection);
                            }}
                        />
                        <FormText color="muted">
                            {EM.t('domains.export.selectDomain.instructions')}
                        </FormText>
                    </FormGroup>
                    <FormGroup>
                        <Input name="fileName" placeholder={EM.t('domains.export.inputFileName.placeholder')} onChange={(e) => setFileName(e.target.value)} />
                        <FormText color="muted">
                            {EM.t('domains.export.inputFileName.instructions')}
                        </FormText>
                    </FormGroup>
                    <FormGroup check>
                        <Label check>
                            <Input type="checkbox" checked={exportDefaultSchedule} onChange={(event) => {
                                setExportDefaultSchedule(!exportDefaultSchedule);
                            }} />
                            {EM.t('domains.export.checkDefaultSchedule.label')}
                        </Label>
                    </FormGroup>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onClose}>{EM.t('util.cancel')}</Button>
                <Button color="primary" disabled={!canCreate} onClick={onCreate}> {EM.t('domains.export.buttonDownload.label')}</Button>
            </ModalFooter>
        </PanelModal>
    );

    async function onCreate() {
        let domainId = selectedDomain.value;
        try {
            let templatedDomain = await EM.api.getDomainTemplate(domainId, fileName, { schedule: exportDefaultSchedule });
            File.saveJson(`${templatedDomain.fileName}`, templatedDomain);
        }
        catch (e) {
            //TODO: fail a bit more gracefully
            alert(e.message);
        }
    }


    function onClose() {
        props.onClose();
    }

}

export default CreateTemplateDialog;