import { combineReducers } from 'redux';
import error from './error';
import account from './account';
import domain from './domain';
import domainState from './domainState';
import fetching from './fetching';
import translation from './translation';
import files from './files';
import projections from './projections';
import audit from './audit';
import terms from './terms';
import release from './release';
import notifications from './notifications';
import entityTypes from '../entityTypes';
import resourceReducer from './resourceRequest';

let reducerSet = {
    error, 
    account,
    translation,
    domain,
    domainState,
    files,
    fetching,
    projections,
    audit,
    terms,
    release,
    notifications,
    resourceReducer,
};

entityTypes.forEach(entityObject => {
    reducerSet[entityObject.name] = generateEntityReducer(entityObject);
});

export default combineReducers(reducerSet);

function generateEntityReducer(entityObject){
    const ACTNAME = entityObject.name.toUpperCase();
    return function (state = null, action) {
        switch (action.type) {
            case `CLEAR_${ACTNAME}`:
                return null;   
            case `CLEARALL_${ACTNAME}`:
                return [];                             
            case `LOAD_${ACTNAME}`:
                return action.data;
            case `RELOAD_${ACTNAME}`:
                return [ ...state ];                
            case `CREATE_${ACTNAME}`:                                     
                if (entityObject.isFileListingTable && action.data.IsDefault){
                    let tmpState = state.map((item) => {
                        return Object.assign({}, item, { IsDefault: false });
                    });                    
                    return [ ...tmpState, action.data ];
                }else{              
                    return [ ...(state ? state : []), action.data ];
                }           
            case `CREATE_BULK_${ACTNAME}`:   
                return [ ...(state ? state : []), ...action.data ];              
            case `UPDATE_${ACTNAME}`:                
                return state ? state.map((item) => {
                    if (item[entityObject.idField] === action.data[entityObject.idField]) {                        
                        return action.data;                        
                    }else{
                        if (entityObject.isFileListingTable && action.data.IsDefault){
                            return Object.assign({}, item, { IsDefault: false });
                        }else{
                            return Object.assign({}, item);
                        }
                    }
                }) : state;
            case `DELETE_${ACTNAME}`:
                return state.filter((item) => {
                    return action.data.indexOf(item[entityObject.idField]) === -1;
                });            
            default:
                return state;
        }
    }
}