import saver from 'file-saver';
import Excel from 'xlsx-populate';
import _ from 'underscore';

export class Workbook {
    constructor(initCallback) {
        let self = this;
        this._workbook = null;

        Excel.fromBlankAsync().then((workbook) => {
            self._workbook = workbook;
            let newCb = initCallback.bind(self);
            newCb(self);
        });

        if (!window.process){
            window.process = { browser:  true };
        }        
    }

    addWorksheetFromColumnsAndData(name, columns, data){
        if (!columns || !data)return;
        let colIndex = _.indexBy(columns, 'dataField');
        let header = columns.map(col => col.text);
        let keys = columns.map(col => col.dataField);
        let rows = data.map(row => {            
            return { data: keys.map(column => { 
                let colDef = colIndex[column];
                let method = colDef.csvFormatter || colDef.formatter || ((cell) => cell);
                return method(row[column], row);
            }), style: row.isGroupedByRow ? 'groupBy' : '' }
        });
        this.addWorksheet(name, [ { data: header, style: 'header' }, ...rows]);
    }    

    addWorksheetFromJsonObjectArray(name, dataset){
        if (!dataset[0])return;
        let header = Object.keys(dataset[0]);
        header = header.filter(key => !['DomainId', 'CreatedOn', 'CreatedBy', 'ModifiedOn', 'ModifiedBy', '_ts'].includes(key) )
        let rows = dataset.map(row => {
            return { data: header.map(column => {
                let value = row[column];
                if (_.isArray(value))value = 'N/A';
                if (_.isObject(value))value = 'N/A';
                return value;
            })}
        });
        this.addWorksheet(name, [ { data: header, style: 'header' }, ...rows]);
    }

    addWorksheetFromJsonFlatArray(name, dataset){
        let rows = dataset.map((row, rowIndex) => {
            return { data: row, style: (rowIndex === 0 ? 'header' : null) }
        });
        this.addWorksheet(name, rows);
    }

    addWorksheet(name, rows) {
        let sheet = this._workbook.addSheet(name);        
        rows.forEach(function (row, index) {
            let range = sheet.range(index + 1, 1, index + 1, row.data.length);
            range.value([row.data]);
            let rowStyle = WorksheetStyles[row.style];            
            if (rowStyle) {
                if (rowStyle.font) range.style(rowStyle.font);
                if (rowStyle.fill) range.style("fill", rowStyle.fill);
                if (rowStyle.bottomBorder) range.style("bottomBorder", true);
                if (rowStyle.topBorder) range.style("topBorder", true);
            }

            if (row.style && row.style.cellFormats && Array.isArray(row.style.cellFormats)){
                range.style("numberFormat", row.style.cellFormats);
            }
        });

        if (this._workbook.sheet("Sheet1")) {
            this._workbook.deleteSheet("Sheet1");
        }
    }

    save(filename) {
        this._workbook.outputAsync().then((blob) => {
            saver.saveAs(new Blob([blob], { type: 'application/octet-stream' }), filename + '.xlsx');
        });
    }
}

export const WorksheetStyles = {
    header: {
        font: {
            bold: true
        },
        bottomBorder: true
    },
    meta: {
        font: {
            bold: true
        },
        bottomBorder: true
    },
    total: {
        font: {
            bold: true
        },
        topBorder: true
    },
    bold: {
        font: {
            bold: true
        }
    },
    groupBy: {
        font: {
            bold: true
        },
        bottomBorder: true
    }
}
