import React, { Component } from 'react';
import Draggable from 'react-draggable';
import tinycolor from 'tinycolor2';

export default class ResizableTimelineRow extends Component {
    constructor(props){
        super(props);

        this.pixelWidth = props.pixelWidth || 25;
        this.pixelHeight = props.pixelHeight || 20;

        this.state = {
            width: this.pixelWidth * props.width,
            left:  this.pixelWidth * props.left,
            isResizing: false
        };

        this.onMove = this.onMove.bind(this);
        this.onMoved = this.onMoved.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onResized = this.onResized.bind(this);
    }

    onMove(event, deltaX){
        let left = this.state.left + deltaX; 
        if (left < 0)return;
        if (left + this.state.width > this.props.maxWidth)return;
        this.setState({ left: left });            
    }

    onMoved(e){
        if (this.props.onMoved){
            this.props.onMoved(e, { left: this.state.left / this.pixelWidth, rawLeft: this.state.left, width: this.state.width / this.pixelWidth, rawWidth: this.state.width } );
        }
    }

    onResize(event, deltaX){
        let width = this.state.width + deltaX;
        if (width > (this.props.maxWidth - this.state.left))return;
        this.setState({ width: width, isResizing: true });
    }

    onResized(e){
        if (this.props.onResized){
            this.props.onResized(e, { width: this.state.width / this.pixelWidth, rawWidth: this.state.width } ); 
        }
        this.setState({ isResizing: false });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.width !== this.props.width || prevProps.left !== this.props.left) {
            this.setState({
                width: this.props.width * this.pixelWidth,
                left: this.props.left * this.pixelWidth
            });
        }
    }

    render(){
        let clip  = -(this.state.left) + (this.props.maxWidth || 0);
        let bgColor = this.props.color;
        let cls = this.props.className;

        let newStyle = { 
            width: this.state.width + 'px', 
            left: this.state.left + 'px',
            backgroundColor: bgColor,
            clip: "rect(-5px, " + clip + "px, 40px, -5px)"
        };

        if (tinycolor(bgColor).getBrightness() < 100){
            cls += ' dark';
        }

        return (            
            <Draggable
                grid={[this.pixelWidth]}
                key="resizableHandle1"
                axis="x"
                handle=".left"
                position={{ x: this.state.left }}
                onDrag={(event, obj) => {
                    this.onMove(event, obj.deltaX);
                }}
                onStop={this.onMoved}
            >
                <div style={newStyle} className={cls + (this.state.isResizing ? ' resizing': '')} onClick={this.props.onClick} title={this.props.tooltip}>
                    {!this.props.readOnly ?
                        <span className="react-resizable-handle left" />                    
                    : null }
                    {this.props.children}
                    {!this.props.readOnly ?
                        <Draggable
                            grid={[this.pixelWidth, this.pixelWidth]}
                            key="resizableHandle2"
                            handle=".right"
                            onDrag={(event, obj) => {
                                this.onResize(event, obj.deltaX);
                            }}
                            onStop={this.onResized}
                        >
                            <span className="react-resizable-handle right" />
                        </Draggable>
                    : null }
                </div>
            </Draggable>
        );
    }
}