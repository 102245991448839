import React, { Component } from 'react';
import TextField from './TextField';
import ArrayField from './ArrayField';
import ColorField from './ColorField';
import BooleanField from './BooleanField';
import OptionField from './OptionField';
import PipelineField from './PipelineField';
import ReferenceTableField from './ReferenceTableField';

const ExpressionField = React.lazy(() => import('./ExpressionField'));

export default class SimpleJsonObjectEditor extends Component {
    constructor(props){
        super(props);

        this.state= {
            value: this.props.defaultValue || {}
        }
        this.onChange = this.onChange.bind(this);
    }

    onChange(prop, value){
        let newObject = Object.assign({}, this.state.value, { [prop]: value });
        if (value === null)delete newObject[prop];
        this.setState({ value: newObject });

        if (this.props.onChange)this.props.onChange(newObject);
    }

    render() {
        let keys = Object.keys(this.props.template);        
        return keys.map(key => {
            let prop = this.props.template[key];
            return (
                <div key={key}>                    
                    { prop.type === 'boolean' ? 
                        <BooleanField name={key} display={prop.display} description={prop.description} value={this.state.value[key] || false} onChange={this.onChange} />
                    : null }
                    { prop.type === 'color' ?
                        <ColorField name={key} display={prop.display} description={prop.description} value={this.state.value[key] || '#ffffff'} onChange={this.onChange} />
                    : null }                    
                    { prop.type === 'array' ? 
                        <ArrayField name={key} display={prop.display} description={prop.description} value={this.state.value[key]?(this.state.value[key].join?this.state.value[key].join(','):[]):''} onChange={this.onChange} />                    
                    : null }                    
                    { prop.type === 'expression' ? 
                        <ExpressionField name={key} display={prop.display} description={prop.description} value={this.state.value[key] || ''} onChange={this.onChange} />
                    : null }  
                    { prop.type === 'option'  ? 
                        <OptionField name={key} display={prop.display} description={prop.description} value={this.state.value[key] || ''} onChange={this.onChange} options={prop.options || []} />
                    : null } 
                    { prop.type === 'pipeline'  ? 
                        <PipelineField name={key} display={prop.display} description={prop.description} value={this.state.value[key] || ''} onChange={this.onChange} options={prop.options || []} />
                    : null }  
                    { prop.type === 'referenceTable'  ? 
                        <ReferenceTableField name={key} display={prop.display} description={prop.description} value={this.state.value[key] || ''} onChange={this.onChange} options={prop.options || []} />
                    : null }                                                              
                    { prop.type === 'string' || !prop.type ? 
                        <TextField name={key} display={prop.display} description={prop.description} value={this.state.value[key] || ''} onChange={this.onChange} />
                    : null }                      
                </div>
            );
        });
    }
};