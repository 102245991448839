/* global EM */
import React, { Component } from 'react';
import PanelModal from '../PanelModal';
import StaffingTimeline from './StaffingTimeline';
import StaffingWorkItemRow from './StaffingWorkItemRow';
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dates from '../../util/Dates';
import _ from 'underscore';
import AssignmentApprovalList from './AssignmentApprovalList';
import StaffingUtil from '../../util/StaffingUtil';

export default class WorkItemDetailsPanel extends Component {
    constructor(props){
        super(props);

        this.state = {
            milestones: []
        };
    }

    componentDidUpdate(prevProps){
        if (this.props.workItem === prevProps.workItem)return;

        EM.attributes.load().then(() => {
            let milestones = [];
            let msTag = 'Milestone:';
            EM.attributes.get().forEach(attr => {
                if (attr.Scope === this.props.workItem && attr.Name.indexOf(msTag) === 0){
                    let label = attr.Name.replace(msTag, '');
                    let date = Dates.fromStr(attr.Value);
                    if (date.isInvalid)return;
                    milestones.push({ label, date, id: attr.AttributeId });
                }
            });
            this.setState({ milestones });
        });
    }

    render(){
        let wi = this.props.workItem;
        if (!wi)return null;

        let assignments = this.props.assignments[wi];

        let projectionGroupKeys = Object.keys(this.props.projections);
        let grouping = projectionGroupKeys.find(gKey => {
            return !!this.props.projections[gKey][wi];
        });
        if (!this.props.projections[grouping])return null;
        
        let projections = this.props.projections[grouping][wi];
        if (!projections)return null;

        let min = _.min(projections, a => a[this.props.filterIndexes.begin]);
        let max = _.max(projections, a => a[this.props.filterIndexes.end]);
        let begin = min[this.props.filterIndexes.begin];
        let end = max[this.props.filterIndexes.end];

        let fullRange = null;
        if (begin < Dates.usableBegin || end < Dates.usableBegin || begin > Dates.usableEnd || end > Dates.usableEnd){            
            fullRange = Dates.getArrayOfMonths(Dates.usableBegin, Dates.usableEnd, true);
        }else{
            fullRange = Dates.getArrayOfMonths(begin, end, true);
        }
        let width = (this.props.cellWidth * fullRange.dates.length) + StaffingUtil.getHeaderWidth();

        return (
            <PanelModal isOpen={true} className={'panel panel-full workitem-details'} key="workitem-details" toggle={(event) => { this.props.close(); }}>
                <ModalHeader toggle={this.props.onClose}>{this.props.workItem}</ModalHeader>
                <ModalBody>
                    <div className="staffing staffing-boxed">
                        <div className="staffing-container">
                            <div className="staffing-container-content" style={{ width: width }}> 
                                <StaffingTimeline range={fullRange} milestones={this.state.milestones}/>   
                                <div className="group">
                                    <StaffingWorkItemRow 
                                        rowHeight={this.props.rowHeight}
                                        cellWidth={this.props.cellWidth}                                        
                                        key={'sk' + this.props.workItem}
                                        name={this.props.workItem}
                                        range={fullRange}
                                        projections={projections}
                                        assignments={assignments}
                                        isActive={true}
                                        maxWidth={width - 200}
                                        filterIndexes={this.props.filterIndexes}
                                        onlyActive={false}
                                        onAddAssignment={null}
                                        onAfterDeleteAssignment = {null}
                                        onAfterUpdateAssignment = {null}
                                        onWorkItemOpen={null}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>   
                    { assignments ? 
                        <AssignmentApprovalList
                            className="mt-2"
                            assignments={assignments}
                            onBulkUpdate={this.props.onBulkUpdate}
                            onSingleUpdate={this.props.onSingleUpdate}
                            unit={'Work Item: ' + this.props.workItem}
                            url={window.location.origin + window.location.pathname + '?wi=' + this.props.workItem}
                        />             
                    : 
                        <div className="alert alert-secondary mt-2">
                            No assignments exist for this Work Item.
                        </div>
                    }      
                </ModalBody>
                <ModalFooter>
                    <Button className="pull-left" onClick={this.props.onClose}>
                        {EM.t('util.closeButton')}
                    </Button>
                </ModalFooter>
            </PanelModal>
        );
    }
};