import * as types from '../actionTypes';
import * as errorActions from './error';
import HelpIndex from '../help/HelpIndex'


export function loadReleaseSuccess(releaseData) {
    return { type: types.LOAD_RELEASE, data: releaseData };
}

export function loadReleaseData() {
    return (dispatch, getState, api) => {

        const releaseContentCID = HelpIndex.content.release.cid;
        const releaseContentName = HelpIndex.content.release.key;
        const language = getState().translation.name ?? 'en';
        const doLoadMetaData = true;

        return api.loadHelp(releaseContentCID, releaseContentName, language, doLoadMetaData).then(data => {
            const release = {
                dateUpdated: data.content.version.when
            };

            return dispatch(loadReleaseSuccess(release));
        }).catch(error => {
            console.log(error);
            dispatch(errorActions.globalError({ message: 'Release meta data could not be fetched.' }));
        });
    };
}