/* eslint jsx-a11y/anchor-is-valid: 0 */
import React from 'react';

export default function PreLoginError(props){  
    return (
        <div className="error-block alert alert-danger m-4 p-4">
            <h4>Error</h4>
            <p>{props.error.message}</p>
            <div className="mt-4 clearfix">
                <button className="btn btn-default float-right" onClick={() => {
                    Object.keys(localStorage).forEach(key => {
                        if (key.indexOf('rm') !== 0)localStorage.removeItem(key);
                    });
                    sessionStorage.removeItem('rm.login_mode');
                    window.location = '/';
                }}>Clear Cached Login State &amp; Retry</button>
            </div>
        </div>
    );
}